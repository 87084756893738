export const menuItemsUser = [
  {
    name: 'Profile',
    icon: 'user',
    url: '/profile',
  },
  {
    name: 'My scores',
    icon: 'chartbar',
    url: '/scores',
  },
  {
    name: 'Education & Courses',
    icon: 'book',
    url: '/education',
  },
  {
    name: 'Working experience',
    icon: 'briefcase',
    url: '/experience',
  },
  {
    name: 'Personal offers',
    icon: 'gift',
    url: '/offers',
  },
  {
    name: 'Self-assessment',
    icon: 'Medal',
    url: '/self',
  },
  {
    name: 'Help',
    icon: 'DangerCircle',
    url: '/help',
  },
  {
    name: 'Log out',
    icon: 'logout',
    url: '/logout',
  },
];

export const menuItemsCompany = [
  {
    name: 'Сompany statistics',
    icon: 'Home',
    url: '/stats',
  },
  {
    name: 'Employee search',
    icon: 'Search',
    url: '/search',
  },
  // {
  //   name: 'Employee data',
  //   icon: 'Document',
  //   url: '/data',
  // },
  {
    name: 'Payments',
    icon: 'Wallet',
    url: '/payments',
  },
  {
    name: 'Logs',
    icon: 'User3',
    url: '/logs',
  },
  {
    name: 'Help',
    icon: 'DangerCircle',
    url: '/help',
  },
  {
    name: 'Log out',
    icon: 'logout',
    url: '/logout',
  },
];

export const newItemz = [
  {
    name: 'Verification review',
    icon: 'Document',
    url: '/self',
  },
];

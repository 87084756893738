import clsx from 'clsx';
import React from 'react';

export const Dots = ({ className, ...otherProps }) => (
  <div
    role="button"
    tabIndex={0}
    aria-label="More options"
    style={{ borderRadius: '50%', width: 25, height: 25, background: '#F4F7FE' }}
    className={clsx('flex items-center justify-center', className)}
    {...otherProps}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
      <path
        d="M4.10819 7.48633C3.36494 7.48633 2.75684 8.09444 2.75684 8.83768C2.75684 9.58092 3.36494 10.189 4.10819 10.189C4.85143 10.189 5.45954 9.58092 5.45954 8.83768C5.45954 8.09444 4.85143 7.48633 4.10819 7.48633ZM12.2163 7.48633C11.4731 7.48633 10.8649 8.09444 10.8649 8.83768C10.8649 9.58092 11.4731 10.189 12.2163 10.189C12.9595 10.189 13.5676 9.58092 13.5676 8.83768C13.5676 8.09444 12.9595 7.48633 12.2163 7.48633ZM8.16224 7.48633C7.419 7.48633 6.81089 8.09444 6.81089 8.83768C6.81089 9.58092 7.419 10.189 8.16224 10.189C8.90549 10.189 9.51359 9.58092 9.51359 8.83768C9.51359 8.09444 8.90549 7.48633 8.16224 7.48633Z"
        fill="#0446F1"
      />
    </svg>
  </div>
);

import React from 'react';

export const Label = ({ text, active }) => (
  <div
    className="font-thin text-xs transition-all overflow-hidden"
    style={{ color: '#989FAD', height: active ? 16 : 0 }}
  >
    {text}
  </div>
);

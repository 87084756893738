import clsx from 'clsx';
import React from 'react';

export const Rate = ({ percent }) => (
  <div
    className={clsx(
      'flex px-3 py-1 justify-center items-center rounded-[41px] min-w-[56px] font-semibold text-[15px]',
      percent < 0 ? 'bg-[#FFDFDF] text-[#ED2C2C]' : 'bg-[#EDFFE1] text-[#60C720]',
    )}
  >
    {percent}%
  </div>
);

export const helpData = {
  FAQ: [
    {
      q: 'What is a background check and why is it required during the job search process',
      a: 'A background check is a process where an employer verifies the information provided by a job seeker and investigates their criminal record, education, employment history, and other relevant details. It is required during the job search process to ensure the accuracy of the information provided and to assess the suitability of the candidate for the position',
    },
    {
      q: 'What data and information can be checked during a background check',
      a: 'During a background check, an employer may check a variety of data and information, including but not limited to: criminal history, education history, employment history, credit history, driving record, and social media presence. The specific information checked may vary depending on the employer and the position being applied for.',
    },
    {
      q: 'What is the procedure for conducting a background check and how long does it take',
      a: 'The procedure for conducting a background check varies depending on the employer and the type of check being conducted. Typically, the process involves obtaining consent from the job seeker, gathering relevant information, and verifying the accuracy of that information. The length of time it takes to conduct a background check can also vary depending on the complexity of the check and the availability of information.',
    },
    {
      q: 'What factors can affect the results of a background check',
      a: 'Several factors can affect the results of a background check, including inaccurate or incomplete information provided by the job seeker, errors or omissions in public records or databases, legal restrictions on what information can be reported, and human error during the verification process.',
    },
    {
      q: 'What should I do if I have doubts or disagree with the results of a background check',
      a: 'If you have doubts or disagree with the results of a background check, you should contact the employer or the company that conducted the check. They may be able to provide additional information or clarify any discrepancies.',
    },
    {
      q: 'What information is considered confidential and how is it processed during a background check',
      a: 'During a background check, certain information may be considered confidential and subject to special handling requirements. This may include sensitive personal information such as social security numbers or medical records. Employers are required to follow strict guidelines for handling confidential information during a background check, including obtaining consent from the job seeker and taking steps to protect the privacy and security of that information.',
    },
  ],
  API: [
    {
      q: 'What is an API?',
      a: 'API stands for Application Programming Interface. It is a set of rules and protocols that allows different software applications to communicate with each other. APIs enable developers to access certain functionalities or data from other applications or services without having to build everything from scratch.',
    },
    {
      q: 'How do I use an API?',
      a: 'To use an API, you typically need to make HTTP requests to specific endpoints provided by the API. These requests can be made using various programming languages or tools. The API documentation usually provides details on how to authenticate, construct requests, and interpret responses.',
    },
    {
      q: 'What are some common use cases for APIs?',
      a: `APIs have various use cases, including but not limited to:
        - Integrating third-party services into your application,
        - Fetching data from external sources,
        - Automating repetitive tasks,
        - Building mobile apps that interact with backend systems,
        - Creating custom integrations between different systems`,
    },
  ],
  Contacts: [
    {
      q: 'How can I contact customer support?',
      a: 'To contact customer support, you can reach out to us via email at support@example.com or call our toll-free number +1-800-123-4567. Our support team is available Monday to Friday from 9 AM to 5 PM.',
    },
    {
      q: 'Where can I find our office address?',
      a: 'Our office is located at 123 Main Street, City, State, ZIP Code. You can find detailed directions on our website under the "Contact" section.',
    },
  ],
};

import React from 'react';

export default function ArrowDown() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
      <path
        fill="#0446F1"
        fillRule="evenodd"
        d="m19.761 12.484-5.185 5.185a.815.815 0 0 1-1.152 0l-5.185-5.185A.815.815 0 0 1 9.39 11.33l4.132 4.132h.954l4.132-4.132a.815.815 0 1 1 1.152 1.153Z"
        clipRule="evenodd"
      />
      <rect width="27" height="27" x=".5" y="1" stroke="#E6E6E6" rx="7.5" />
    </svg>
  );
}

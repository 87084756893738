import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState } from 'react';

import { InputDiv } from '../../Select/Select';
import { inviteCustomer } from '../../../utils/httpServices/company';
import {toast} from "react-toastify";

const AddEmployee = observer(({ visible, onCancel }: any) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const handleOk = async () => {
    try {
      const result = await inviteCustomer({name, email});
      if (result.error) {
        toast(result.error.message, {type: 'error'});
      } else {
        toast('Invitation sent to ' + email, {type: 'success'});
      }
    } catch (e) {
      toast('Error', {type: 'error'});
    }
    onCancel();
  };

  return (
    <Modal
      open={visible}
      onCancel={onCancel}
      title={<h3 className="text-[22px] text-center font-medium mb-7">Add employee</h3>}
      footer={null}
    >
      <div className="flex flex-col w-full gap-6">
        <InputDiv
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
          className="w-full relative"
          placeholder="Full name"
          label={undefined}
          color={undefined}
          containerClassName={undefined}
          disabled={undefined}
          searchIcon={undefined}
          children={undefined}
        />

        <InputDiv
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          className="w-full relative"
          placeholder="Email"
          label={undefined}
          color={undefined}
          containerClassName={undefined}
          disabled={undefined}
          searchIcon={undefined}
          children={undefined}
        />

        <button
          className="bg-[#0446F1] text-white mx-auto font-medium py-[10px] px-4 w-[160px] rounded-[25px]"
          onClick={handleOk}
        >
          Save
        </button>
      </div>
    </Modal>
  );
});

export default AddEmployee;

import React, { useState } from 'react';

import PageTitle from '../../../components/PageTitle';
import {
  Star as Starr,
  Airplane,
  Check,
  Credit,
  Hotels,
  Car,
  Rental,
  StarYellow,
  Clock,
  DetailsIcon,
} from '../../../components/Icons/PersonalOffer';
import { offers } from './mockData';

import clsx from 'clsx';

const MenuItem = ({ children, selected, icon, onClick }) => (
  <div
    className={clsx(
      'flex items-center gap-2 px-4 py-3 cursor-pointer rounded-[20px] bg-[#EDF0F4] transition-all whitespace-nowrap',
      selected && 'bg-[#fff] select-none ',
    )}
    onClick={onClick}
  >
    {React.cloneElement(icon, { active: selected })}
    <div className={clsx(!selected && 'opacity-50')}>{children}</div>
  </div>
);

const Tag = ({ children }) => (
  <div className="absolute top-6 right-4 flex rounded-[20px] backdrop-blur-[18px] py-1 px-[6px] gap-1 bg-[#fff] items-center text-black">
    {children}
  </div>
);

const menuItems = [
  'Recommended for you',
  'Airlines',
  'Insurance company',
  'Credit products',
  'Hotels',
  'Car rental',
  'Rental Apartments',
];
const icons = [<Starr />, <Airplane />, <Check />, <Credit />, <Hotels />, <Car />, <Rental />];

export default function PersonalOffers() {
  const [selected, setSelected] = useState(0);

  return (
    <div className="flex flex-col">
      <PageTitle title="Personal offers" showUser />
      <div className="flex gap-6">
        <div className="flex flex-col gap-1">
          {menuItems.map((item, index) => (
            <MenuItem
              key={item}
              selected={selected === index}
              icon={icons[index]}
              onClick={() => setSelected(index)}
            >
              {item} ({offers[index].length})
            </MenuItem>
          ))}
        </div>
        <div className="grid grid-cols-2 gap-5 w-full">
          {offers[selected].map((item) => (
            <div
              className="grid flex-col aspect-[1.97] col-span-1 gap-2 rounded-3xl border p-6 font-medium relative bg-[lightgrey] text-white bg-cover bg-no-repeat"
              style={item.bg ? { backgroundImage: `url(/images/mocks/${item.bg})` } : {}}
              key={item.name}
            >
              <div className="flex gap-3 items-center h-fit">
                <div
                  className="w-10 h-10 rounded-lg bg-white bg-contain bg-no-repeat"
                  style={{ backgroundImage: `url(/images/mocks/${item.icon})` }}
                />
                <div className="text-[18px]">{item.name}</div>
              </div>
              <div className="flex flex-col gap-[10px] self-end">
                <div className="flex gap-1 items-center">
                  <Clock /> {item.time}
                </div>
                <div className="w-[37%] line-clamp-4">{item.text}</div>
              </div>
              {item.type === 'upgrade' && (
                <Tag>
                  <StarYellow /> Upgrade
                </Tag>
              )}

              {item.type === 'details' && (
                <Tag>
                  <DetailsIcon /> Details
                </Tag>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';

import CircleHalf from '../../../components/CircleHalf';
import { CircleProgress } from '../../../components/CircleProgress';
import Divider from '../../../components/Divider';
import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import PageTitle from '../../../components/PageTitle';

import { iconsData } from './icons';
import Selector from '../../../components/Selector/Selector';
import { Rate } from './Rate';
import { get6Month, getColor, getLastDay } from '../../../utils/helpers';
import { RateChange } from './RateChange';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import Chart from './Chart';
import PeriodTag, { periods } from './PeriodTag';
import { getMockDataTotal, mockData } from './mockData';

export const labels = [
  [1, 5, 10, 15, 20, 25, getLastDay()],
  get6Month(),
  ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
];

export default function MyScores() {
  const [selected, setSelected] = useState(0);
  const [period, setPeriod] = useState(0);

  const name = selected ? iconsData[selected - 1].name : '';
  let backgroundColor = name ? '#2A5CDD' : 'black';
  if (name?.includes('Credit')) backgroundColor = '#29B4EF';
  if (name?.includes('Drug')) backgroundColor = '#8E25E1';
  if (name?.includes('Med')) backgroundColor = '#17DA7C';
  if (name?.includes('Emp')) backgroundColor = '#FD8026';
  if (name?.includes('Peer')) backgroundColor = '#FD4A23';

  const currentPercent =
    selected > 0
      ? iconsData[selected - 1].percent
      : (iconsData.reduce((acc, item) => acc + item.percent, 0) / iconsData.length).toFixed();

  return (
    <div className="flex flex-col">
      <PageTitle title="Score" showUser />
      <div className="flex gap-4">
        <PageBlock className="min-w-[390px]">
          <CircleHalf
            percent={currentPercent}
            text={selected > 0 ? iconsData[selected - 1].name : 'Total score'}
          />
        </PageBlock>
        <div className="flex gap-1 w-full">
          {iconsData.map((item) => (
            <PageBlock small key={item.name} className="flex-1">
              <img src={`/images/score/${item.icon}.svg`} alt="" />
              <div className="text-[13px] py-1 flex flex-wrap w-1/2 text-center font-medium">
                {item.name}
              </div>
              <CircleProgress percent={item.percent} size={80} />
            </PageBlock>
          ))}
        </div>
      </div>
      <Selector className="w-full mt-6 mb-4 cursor-pointer" selected={selected}>
        <div style={{ color: selected === 0 ? '#fff' : '#8E8E8E' }} onClick={() => setSelected(0)}>
          Total
        </div>
        {iconsData.map((item, i) => (
          <div
            key={item.name}
            style={{ color: selected === i + 1 ? '#fff' : '#8E8E8E' }}
            onClick={() => setSelected(i + 1)}
          >
            {item.name}
          </div>
        ))}
      </Selector>

      <PageBlock className="mb-3">
        <div className="flex flex-col w-full">
          <div className="flex justify-between mb-4">
            <PageBlockTitle>Statistic</PageBlockTitle>
            <div className="flex gap-3 items-center">
              <div className="flex gap-1 font-medium text-[13px]">
                {periods.map((el, i) => (
                  <PeriodTag selected={period === i} onClick={() => setPeriod(i)} key={el}>
                    {el}
                  </PeriodTag>
                ))}
              </div>
              <CalendarIcon />
            </div>
          </div>
          <div className="gap-[50px] flex">
            <div>
              <div
                className="font-medium text-[40px] leading-none w-[5rem]"
                style={{ color: getColor(+currentPercent) }}
              >
                {currentPercent}
              </div>
              <RateChange percent={2.45} />
            </div>
            <div className="flex flex-1 w-full h-[130px]">
              <Chart
                data={!!selected ? null : getMockDataTotal(labels[period], period)}
                lineColor={backgroundColor}
                labels={labels[period]}
                tableData={mockData[period]}
              />
            </div>
          </div>
        </div>
      </PageBlock>

      <PageBlock noPadding>
        <div className="flex flex-col w-full">
          <PageBlockTitle className="px-6 pt-4 pb-3">History</PageBlockTitle>
          <Divider />
          <div className="flex flex-col">
            {(selected !== 0
              ? iconsData[selected - 1].data
              : iconsData.flatMap((el) => [
                  ...el.data.map((item) => ({ ...item, name: el.name, icon: el.icon })),
                ])
            ).map((item, i, arr) => {
              const name = selected ? iconsData[selected - 1].name : item.name;
              let backgroundColor = '#2A5CDD';
              if (name?.includes('Credit')) backgroundColor = '#29B4EF';
              if (name?.includes('Drug')) backgroundColor = '#8E25E1';
              if (name?.includes('Med')) backgroundColor = '#17DA7C';
              if (name?.includes('Emp')) backgroundColor = '#FD8026';
              if (name?.includes('Peer')) backgroundColor = '#FD4A23';

              return (
                <React.Fragment key={i}>
                  <div className="p-6 pt-5 font-medium">
                    <div className="flex w-full justify-between">
                      <div className="flex gap-2 items-center">
                        <div
                          className={`bg-no-repeat bg-contain h-8 w-8 flex items-center justify-center rounded-full`}
                          style={{ backgroundColor }}
                        >
                          <img
                            src={`/images/score/${
                              selected ? iconsData[selected - 1].icon : item.icon
                            }-white.svg`}
                            alt=""
                            style={{ stroke: 'white !important', fill: 'white !important' }}
                          />
                        </div>
                        <div>
                          <div className="text-base">
                            {selected ? iconsData[selected - 1].name : item.name}
                          </div>
                          <div className="text-xs text-[#ACACAC]">{item.date}</div>
                        </div>
                      </div>

                      <Rate percent={item.change} />
                    </div>
                    <div className="mt-3">{item.text}</div>
                  </div>
                  {i !== arr.length - 1 && <Divider />}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </PageBlock>
    </div>
  );
}

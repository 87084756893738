import React from 'react';

export default function Circles({ percent = 50, color = '#000', className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 413 338" fill="none" className={className}>
      <g strokeLinecap="round" strokeWidth="24.333" clipPath="url(#aaa)" filter="url(#bbbb)">
        <path
          stroke="#FCDA27"
          d="M168.37 291.994a122.761 122.761 0 0 1-108.853-66.01 122.76 122.76 0 0 1 8.215-127.039 122.755 122.755 0 0 1 215.893 28.038"
        />
        <path
          stroke="#74DC34"
          d="M168.37 322.215A152.979 152.979 0 0 1 37.872 89.412 152.976 152.976 0 1 1 258.55 292.807"
        />
        <path stroke="#FFA5A5" d="M233.47 104.137a92.066 92.066 0 1 0-65.101 157.168" />
        <path
          stroke="#ED2C2C"
          d="M229.748 169.237a61.37 61.37 0 0 0-10.344-34.099 61.37 61.37 0 0 0-63.008-26.099 61.378 61.378 0 0 0 11.974 121.577"
        />
        <path
          stroke="#000"
          d="M168.37 199.927a30.693 30.693 0 0 1-29.978-24.119 30.692 30.692 0 0 1 17.141-34.446"
        />
      </g>
      <defs>
        <clipPath id="aaa">
          <path fill="#fff" d="M0 .84h413v337H0z" />
        </clipPath>
        <filter
          id="bbbb"
          width="338.288"
          height="338.288"
          x="-.774"
          y="4.094"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_262_3429" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_262_3429" result="shape" />
        </filter>
      </defs>
      <text
        x="45%"
        y="51%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: 22, fontWeight: 600, fill: color }}
      >
        {percent}%
      </text>
    </svg>
  );
}

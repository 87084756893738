type Icon = 'Bank' | 'Employerscore' | 'FirstAid' | 'PoliceCar' | 'Syringe' | 'User';

interface IconData {
  active: boolean;
  icon: Icon;
  name: string;
  percent: number;
  data?: { date: string; text: string; change: number }[];
}

export const iconsData: IconData[] = [
  {
    active: false,
    icon: 'PoliceCar',
    name: 'Criminal score',
    percent: 50,
    data: [
      {
        date: '25.07.2022  09:23',
        text: 'Charge under case number 123 for theft of property valued at $1000.',
        change: -1,
      },
      {
        date: '01.07.2000  09:23',
        text: 'Charge with driving under the influence (DUI) under case number 565 after failing a breathalyzer test.',
        change: -2,
      },
      {
        date: '06.04.2005  09:23',
        text: `Arrest and charge with identity theft under case number 6767 for using someone else's personal information without consent.`,
        change: 5,
      },
    ],
  },
  {
    active: true,
    icon: 'Syringe',
    name: 'Drug score',
    percent: 100,
    data: [
      {
        date: '15.05.2020 15:30',
        text: 'Completed a successful drug rehabilitation program. Positive progress in overcoming substance abuse.',
        change: 8,
      },
      {
        date: '10.03.2019 13:45',
        text: 'Arrest on possession charges under case number 9876. Found with an illicit substance during a routine traffic stop.',
        change: -15,
      },
    ],
  },
  {
    active: true,
    icon: 'Bank',
    name: 'Credit score',
    percent: 28,
    data: [
      {
        date: '12.09.2021 10:40',
        text: 'Paid off outstanding car loan. This greatly improved overall debt-to-income ratio.',
        change: 10,
      },
      {
        date: '18.01.2020 16:20',
        text: 'Collection account placed on credit report due to unpaid medical bill.',
        change: -20,
      },
    ],
  },
  {
    active: true,
    icon: 'FirstAid',
    name: 'Medical score',
    percent: 14,
    data: [
      {
        date: '04.11.2020 11:55',
        text: 'Consistent yearly physical checkups show positive health maintenance',
        change: 5,
      },
      {
        date: '22.02.2021 14:00',
        text: 'Diagnosed with high blood pressure. Started medication and recommended diet change.',
        change: -10,
      },
    ],
  },
  {
    active: true,
    icon: 'Employerscore',
    name: 'Employer score',
    percent: 75,
    data: [
      {
        date: '24.07.2022 16:00',
        text: 'Promotion to senior position due to consistent performance and leadership qualities.',
        change: 10,
      },
      {
        date: '30.01.2021 09:00',
        text: 'Received a write-up for repeated tardiness.',
        change: -5,
      },
    ],
  },
  {
    active: false,
    icon: 'User',
    name: 'Peer score',
    percent: 86,
    data: [
      {
        date: '01.08.2020 15:20',
        text: 'Volunteered at local food bank and received positive feedback from community members.',
        change: 5,
      },
      {
        date: '15.10.2020 18:30',
        text: 'A public dispute with a neighbor negatively influenced peer perception.',
        change: -5,
      },
    ],
  },
];

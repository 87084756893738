import clsx from 'clsx';
import React from 'react';

export const RateChange = ({ percent }) => (
  <div
    className={clsx('flex text-sm font-bold gap-[2px] items-center')}
    style={{ color: percent < 0 ? '#ED2C2C' : '#05CD99', opacity: percent < 0 ? 0.5 : 1 }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="18"
      viewBox="0 0 8 18"
      fill="none"
      style={{
        transform: `rotate(${percent < 0 ? 180 : 0}deg)`,
      }}
    >
      <g clipPath="url(#clip0_1_5550)">
        <path
          d="M0.162598 10.4667L3.8501 6.72754L7.5376 10.4667H0.162598Z"
          fill={percent < 0 ? '#ED2C2C' : '#05CD99'}
          opacity={percent < 0 ? 0.7 : 1}
        />
      </g>
      <defs>
        <clipPath id="clip0_1_5550">
          <rect width="8" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
    {percent > 0 && '+'}
    {percent}%
  </div>
);

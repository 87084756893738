import React from 'react';

const Doc = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M8.167 1.167H3.5a1.167 1.167 0 00-1.167 1.166v9.334A1.166 1.166 0 003.5 12.833h7a1.167 1.167 0 001.167-1.166v-7l-3.5-3.5z"
      stroke="#0446F1"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.167 1.167v3.5h3.5M9.333 7.583H4.667M9.333 9.917H4.667M5.833 5.25H4.667"
      stroke="#0446F1"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Document = React.memo(Doc);
export default Document;

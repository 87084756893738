export const FAQ = [
  {
    q: 'What is a background check and why is it required during the job search process?',
    a: 'A background check is a process where an employer verifies the information provided by a job seeker and investigates their criminal record, education, employment history, and other relevant details. It is required during the job search process to ensure the accuracy of the information provided and to assess the suitability of the candidate for the position.',
  },
  {
    q: 'What data and information about me can be checked during a background check?',
    a: 'During a background check, various data and information about a job seeker can be checked. This may include criminal records, credit history, employment history, education verification, professional licenses, and certifications.',
  },
  {
    q: 'What is the procedure for conducting a background check and how long does it take?',
    a: 'The procedure for conducting a background check may vary depending on the employer and the type of check being performed. It typically involves obtaining consent from the job seeker, collecting necessary information, verifying the information through various sources, and analyzing the results. The duration of a background check can range from a few days to several weeks, depending on the complexity of the check.',
  },
  {
    q: 'What factors can affect the results of a background check?',
    a: 'Several factors can affect the results of a background check. These may include discrepancies in the information provided by the job seeker, incomplete or inaccurate records, delays in obtaining information from external sources, or issues with the verification process.',
  },
  {
    q: 'What should I do if I have doubts or disagree with the results of a background check?',
    a: 'If a job seeker has doubts or disagrees with the results of a background check, they should first communicate their concerns to the employer or the background check provider. It may be possible to provide additional documentation or clarify any misunderstandings. If necessary, legal advice may be sought to address any potential issues.',
  },
  {
    q: 'What information is considered confidential and how is it processed during a background check?',
    a: 'Confidential information, such as social security numbers, financial records, or medical history, is considered sensitive during a background check. Employers and background check providers are required to handle this information securely and in compliance with applicable privacy laws.',
  },
  {
    q: 'What laws and regulations govern the conduct of a background check?',
    a: `The conduct of a background check is governed by various laws and regulations, including the Fair Credit Reporting Act (FCRA) in the United States. These laws aim to protect job seekers' rights and ensure that background checks are conducted fairly and accurately.`,
  },
  {
    q: 'What are the consequences of a negative result in a background check for my employment?',
    a: 'The consequences of a negative result in a background check for employment can vary depending on the employer and the nature of the negative information. It may result in the job offer being rescinded or the candidate being disqualified from consideration for the position. It is important for job seekers to be honest and transparent during the application process to avoid potential issues.',
  },
  {
    q: 'Can I request a copy of the background check results?',
    a: 'In some cases, job seekers may be able to request a copy of the background check results from the employer or the background check provider. This can help them understand the information that was considered and address any inaccuracies or discrepancies.',
  },
  {
    q: 'What security guarantees does Backgrounds Check provide when processing my personal data?',
    a: 'Backgrounds Check, as a background check provider, takes the security of personal data seriously. They have measures in place to protect the confidentiality and integrity of the information provided by job seekers. These measures may include secure data storage, encryption, access controls, and compliance with relevant data protection laws.',
  },
];

import React from 'react';
import { getColor } from '../../utils/helpers';

interface IProps {
  percent: number;
  size: number;
  width: number;
}

export const CircleProgress = ({ percent = 100, size = 54, width = 7 }: IProps) => {
  const radius = size / 2 - width;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  const color = getColor(percent);

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke={color}
        opacity="0.2"
        strokeWidth={width}
      />
      <circle
        r={radius}
        cx={size / 2}
        cy={size / 2}
        fill="transparent"
        stroke={color}
        strokeWidth={width}
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(90, ${size / 2}, ${size / 2})`}
      />
      <text
        x="50%"
        y="52%"
        textAnchor="middle"
        dominantBaseline="middle"
        style={{ fontSize: `16px`, fontWeight: 500, fill: color }}
      >
        {percent}
      </text>
    </svg>
  );
};

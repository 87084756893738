import React from 'react';
import { getColor } from '../../utils/helpers';
import clsx from 'clsx';

interface IProps {
  percent: number;
  className?: string;
}

export default function Rating({ percent, className }: IProps) {
  const color = getColor(percent)?.toLowerCase();

  return (
    <div
      className={clsx(
        'flex w-full pl-[6px] items-center text-white h-4 rounded-xl bg-gray-200 relative',
        className,
      )}
    >
      <div
        className="h-4 rounded-xl absolute left-0 top-0 flex items-center pl-[6px]"
        style={{ background: color, width: `${percent}%` }}
      >
        <div className="">{percent}</div>
      </div>
    </div>
  );
}

import React from 'react';

export const Tick = ({ selected = true }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    fill="none"
    viewBox="0 0 18 17"
    style={{ minWidth: 18, minHeight: 17, opacity: selected ? 1 : 0.4 }}
  >
    <rect width="17" height="17" x=".051" fill="#5ECA1B" fillOpacity=".15" rx="8.5" />
    <path
      stroke="#5ECA1B"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m12.296 5.925-5.15 5.15-2.34-2.34"
    />
  </svg>
);

import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '../../components/Button';
import { Paragraph, Text, Title } from '../../components/Typography';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { menuItemsCompany, menuItemsUser } from '../../components/Menu/menuItems';

const Page404 = observer(() => {
  const navigate = useNavigate();
  const account = globalController.getAccount();

  const handleNav = () => {
    if (account === 'company') {
      navigate(menuItemsCompany[0].url);
    } else if (account === 'user') {
      navigate(menuItemsUser[0].url);
    } else {
      navigate('/');
    }
  };

  return (
    <div className="Page404-container">
      <Text className="Page404-sadSmile" role="img" aria-label="sad smile icon">
        ☹️
      </Text>
      <Title>Error 404</Title>
      <Paragraph>Ooops! Page not found.</Paragraph>
      <Paragraph className="Page404-text--small">
        The Page you are looking for doesn&apos;t exist.
      </Paragraph>
      <Button variant="bigBlue" onClick={handleNav} className="!text-xl">
        Go back
      </Button>
    </div>
  );
});

export default Page404;

export const skillsOptions = [
  'Accounting Software',
  'Accuracy',
  'Action planning',
  'Active Listening',
  'Acute care',
  'Ad design',
  'Adaptability',
  'Administrative',
  'Adobe Creative Suite: Illustrator, InDesign, Photoshop',
  'Adobe Photoshop, InDesign',
  'Advanced Cardiac Life Support (ACLS)',
  'Affiliate Marketing',
  'Agile Business Analysis',
  'Analytical skills',
  'Anti Money Laundering',
  'Application Programming Interface (API)',
  'Artificial Intelligence',
  'Attention to detail',
  'B2B Marketing',
  'Basic knowledge of user interface communication',
  'Being Detail Oriented',
  'Big Data tools',
  'Bookkeeping through Excel or TurboTax',
  'Brand management',
  'Business Development',
  'Business Intelligence',
  'Business Management',
  'Business Process Modeling',
  'Business Storytelling',
  'Buyer engagement',
  'Buyer-Responsive selling',
  'Channel Marketing',
  'Charisma',
  'Clear communication',
  'Cloud Computing',
  'Cloud networking and file sharing',
  'Cloud Security',
  'Cloud Service Platform Expertise',
  'CMS Tools',
  'Coaching',
  'Cognos Analytics (IBM)',
  'Cold-calling',
  'Collaboration',
  'Color sense & theory',
  'Commitment',
  'Communication',
  'Compassion',
  'Conflict Resolution',
  'Consulting',
  'Consumer Behavior Drivers',
  'Content Management Systems (CMS)',
  'Content Marketing',
  'Copywriting',
  'CPR',
  'Creativity',
  'Critical thinking',
  'CRO and A/B Testing',
  'Cryptography',
  'CSS preprocessors',
  'Curiosity',
  'Customer Relationship Management (CRM)',
  'Data Analysis',
  'Data entry',
  'Data Mapping',
  'Data Structures',
  'Data visualization & pattern-finding through critical thinking',
  'Database Management',
  'Dealing with work-related stress',
  'Decision-making skills',
  'Development and Operations',
  'Digital Marketing',
  'Digital printing',
  'Discussion',
  'Dreamweaver',
  'Educational platforms (software like Elearn)',
  'Effective communication and sociability',
  'Email marketing',
  'Emotional Intelligence',
  'Empathy',
  'Enterprise Resource Planning',
  'Enthusiasm',
  'Entity Relationship Diagrams',
  'Equipment installation and configuration',
  'Fast Typing',
  'Filing and paper management',
  'Gap Analysis',
  'General business knowledge',
  'Git/Version control (Github, GitLab)',
  'Goal setting',
  'Graphic User Interfaces (GUI)',
  'High-Performance Computing',
  'HTML & CSS',
  'Imagination',
  'Infection control',
  'Infographics',
  'Innovation',
  'Integration of visual communication in social media platforms',
  'Interactive media design',
  'Interpersonal Skills',
  'Javascript',
  'Know Your Customers (KYC)',
  'Lead generation',
  'Leadership',
  'Logic and Reasoning',
  'Logistics',
  'Logo creation',
  'Machine learning',
  'Market Research',
  'Mathematics',
  'Media Planning',
  'Microsoft Access',
  'Microsoft Excel',
  'Microsoft Office Pack',
  'Microsoft Visio',
  'Motivation',
  'Negotiation',
  'Network Management',
  'Networking',
  'Newsletter',
  'Nonverbal Communication',
  'Numerical Analysis',
  'Numerical competence',
  'Online Marketing',
  'Open-mindedness',
  'Oral and written communication',
  'Paid social media advertisements',
  'Paperwork/record-keeping abilities',
  'Patience',
  'People management',
  'People Skills',
  'Persuasion',
  'PESTEL',
  'Photo Editing',
  'Physical endurance',
  'Physical organization',
  'Planning',
  'Porter’s Five Forces',
  'Positivity',
  'Presentation',
  'Prioritization',
  'Problem Solving',
  'Process improvement',
  'Product knowledge',
  'Product Management',
  'Matlab',
  'Python',
  'R',
  'Scala',
  'Project management',
  'Proposal writing',
  'Public speaking',
  'Relationship-building',
  'Research',
  'Responsive design principles',
  'Revenue recognition',
  'Risk management',
  'Sales',
  'Scheduling',
  'Scientific Computing',
  'Scientific Modeling',
  'Search Engine and Keyword Optimization',
  'Search Engine Optimization (SEO)',
  'Six Sigma techniques',
  'Smart Contract Development',
  'Social media',
  'Solidity',
  'SQL',
  'Stakeholder analysis',
  'STATA, SPSS, SAS',
  'Statistical Analysis',
  'Storyboarding',
  'Storytelling',
  'Strategic Management',
  'Stress management',
  'Surgery preparation',
  'System Context Diagrams',
  'Targeting and marketing through visual communications',
  'Task delegation',
  'Teamwork',
  'Technical writing',
  'Technological & digital literacy',
  'Technological savviness',
  'Telemetry',
  'Testing/Debugging',
  'The 4 P-s of Marketing',
  'The McKinsey 7s Framework',
  'Time Management',
  'Troubleshooting',
  'Typography: spacing, line height, layout, choosing fonts',
  'Updated curriculum knowledge',
  'UX Design',
  'Video Production',
  'Visual Basic',
  'Web (Internet savviness, basic HTML, CMS)',
  'Web analytics',
  'Web scraping',
  'Wireframes',
  'WordPress',
  'Writing',
];

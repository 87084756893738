const graphData = [
  { time: 4.5, percent: 10, src: '/images/mocks/photos/DavidB.jpg' },
  { time: 4, percent: 15, src: '/images/mocks/photos/IlonAsk.jpg' },
  { time: 1, percent: 20, src: '/images/mocks/photos/IrenLi.jpg' },
  { time: 3, percent: 35, src: '/images/mocks/photos/MaryD.jpg' },
  { time: 2, percent: 55, src: '/images/mocks/photos/NataliH.jpg' },
  { time: 3.5, percent: 70, src: '/images/mocks/photos/StenL.jpg' },
  { time: 2.5, percent: 85, src: '/images/mocks/photos/DavidB.jpg' },
  { time: 2.9, percent: 92, src: '/images/mocks/photos/StenL.jpg' },
];

export default graphData;

import React, { useState } from 'react';

import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import PageTitle from '../../../components/PageTitle';

import { jobs } from './mockData';
import clsx from 'clsx';
import Rating from '../../../components/Rating';
import Star from '../../../components/Icons/Star';
import Selector from '../../../components/Selector/Selector';
import { CircleProgress } from '../../../components/CircleProgress';
import { iconsData } from '../MyScores/icons';
import PeriodTag, { periods, periodsY } from '../MyScores/PeriodTag';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import Chart from '../MyScores/Chart';
import { labels } from '../MyScores';
import { mockData } from '../MyScores/mockData';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import { Star5 } from '../../../components/Icons/Star5';

const thisYear = new Date().getFullYear();

const lineYears = [
  [thisYear],
  [thisYear - 2, thisYear - 1, thisYear],
  [thisYear - 4, thisYear - 3, thisYear - 2, thisYear - 1, thisYear],
];

export const TagExp = ({ active, data, onClick }) => (
  <div
    className={clsx(
      'flex flex-1 items-center p-4 bg-[#EAECEF] rounded-3xl cursor-pointer',
      !data && 'justify-center',
      active && '!bg-[#0446F1] text-white',
    )}
    onClick={onClick}
  >
    {data ? (
      <div className="flex gap-3">
        <div
          className="bg-no-repeat bg-contain w-[52px] h-[52px] rounded-xl"
          style={{ backgroundImage: `url(${data.src})` }}
        />
        <div className="font-medium">
          <div className="leading-[18px]">{data.position}</div>
          <div className="flex items-center gap-[6px] leading-[18px]">
            <Star rating={data.rating} color={active ? '#fff' : ''} />
            {data.company}
          </div>
          <div className={`text-[13px] text-[${active ? 'white' : '#ACACAC'}]`}>
            {data.duration ? `${data.years} · ${data.duration}` : data.years}
          </div>
        </div>
      </div>
    ) : (
      'Total'
    )}
  </div>
);

export default function WorkExpirience() {
  const [activeIdx, setActiveIdx] = useState(0);
  const [selected, setSelected] = useState(0);
  const [period, setPeriod] = useState(0);
  const [periodY, setPeriodY] = useState(0);
  const [reviews, setReviews] = useState(Array(jobs.length).fill(0));

  const item = iconsData[selected + 4];

  return (
    <div className="flex flex-col">
      <PageTitle title="Working experience" showUser />
      <div className="flex gap-3 mb-5">
        <TagExp active={activeIdx === 0} onClick={() => setActiveIdx(0)} />
        {jobs.map((job, i) => (
          <TagExp
            data={job}
            key={job.date + job.company}
            active={activeIdx === i + 1}
            onClick={() => setActiveIdx(i + 1)}
          />
        ))}
      </div>
      <div className="flex flex-col gap-4">
        <PageBlock className="!items-start">
          <div className="flex justify-between items-center w-full">
            <PageBlockTitle>Jobs timeline</PageBlockTitle>
            <div className="flex gap-1 items-center">
              {periodsY.map((el, i) => (
                <PeriodTag selected={periodY === i} onClick={() => setPeriodY(i)} key={el}>
                  {el}
                </PeriodTag>
              ))}
            </div>
          </div>
          <div className="flex gap-6 mt-6 w-full">
            <div className="flex gap-2">
              <PageBlock smallBlue className="!items-start">
                <div className="flex flex-col">
                  <div className="text-[32px] leading-none text-[#0446F1] font-medium">5</div>
                  <div>Jobs</div>
                </div>
              </PageBlock>
              <PageBlock smallBlue className="!items-start">
                <div>
                  <div className="flex gap-1 text-[#0446F1] items-end font-medium">
                    <div className="text-[32px] leading-none">72</div>
                    <div>month</div>
                  </div>
                  <div className="whitespace-nowrap">Total operating time</div>
                </div>
              </PageBlock>
              <PageBlock smallBlue className="!items-start">
                <div>
                  <div className="flex gap-1 text-[#0446F1] items-end font-medium">
                    <div className="text-[32px] leading-none">12</div>
                    <div>month</div>
                  </div>
                </div>
                <div className="whitespace-nowrap">Avr. contract duration</div>
              </PageBlock>
            </div>
            <div className="flex flex-col justify-between bg-[white] w-full">
              {/* <div
                className="flex w-full h-full bg-cover bg-no-repeat"
                style={{ backgroundImage: 'url(/images/mocks/timeline.svg)' }}
              ></div> */}
              <img src="/images/mocks/timeline.svg" alt="" />
              <div
                className={clsx('flex w-full mt-3', periodY ? 'justify-between' : 'justify-center')}
              >
                {lineYears[periodY].map((el, i) => (
                  <div key={el} className="text-[10px] font-medium" style={{ color: '#c2c2c2' }}>
                    {el}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </PageBlock>
        <div className="flex gap-3">
          <div className="flex flex-col flex-1 gap-3">
            <PageBlock className="!items-start">
              <PageBlockTitle>All real reviews</PageBlockTitle>
              <Selector
                className="w-full !bg-[#F3F3F3] h-[48px] mt-6 mb-4 cursor-pointer"
                classNameChildren={'!rounded-[72px]'}
                classNameSelected="!bg-[#fff] !rounded-[72px] h-[44px] !top-1"
                selected={selected}
                padding={4}
              >
                <div
                  style={{ color: selected === 0 ? '#000' : '#8E8E8E' }}
                  onClick={() => setSelected(0)}
                >
                  Employer reviews
                </div>
                <div
                  style={{ color: selected === 1 ? '#000' : '#8E8E8E' }}
                  onClick={() => setSelected(1)}
                >
                  Peer reviews
                </div>
              </Selector>
            </PageBlock>
            <PageBlock className="h-full">
              <div className="flex gap-6 w-full">
                <div className="rounded-3xl py-4 px-1 flex flex-col items-center !min-w-[113px]">
                  <img src={`/images/score/${item.icon}.svg`} alt="" />
                  <div className="text-[13px] py-1 flex flex-wrap w-1/2 text-center font-medium">
                    {item.name}
                  </div>
                  <CircleProgress percent={item.percent} size={80} />
                </div>
                <div className="w-full pt-3 font-medium text-sm">
                  <div className="text-sm mb-1">HR</div>
                  <Rating percent={88} className="mb-[20px]" />
                  <div className="mb-1">Security</div>
                  <Rating percent={63} className="mb-[20px]" />
                  <div className="mb-1">Immediate supervisor</div>
                  <Rating percent={75} className="mb-[20px]" />
                </div>
              </div>
            </PageBlock>
          </div>
          <div className="flex flex-col flex-[1.63] gap-3">
            <div className="flex gap-[5px]">
              <PageBlock className="w-full font-medium !items-start">
                <div className="text-[32px] text-[#0446F1]">12</div>
                <div className="text-[13px]">Received feedback</div>
              </PageBlock>
              <PageBlock className="w-full font-medium !items-start">
                <Star rating={4.8} big bigStar color="#0446F1" />
                <div className="text-[13px]">Average score</div>
              </PageBlock>
            </div>
            <PageBlock className="w-full h-full flex-col">
              <div className="flex w-full justify-between items-center mb-4">
                <PageBlockTitle>Statistic</PageBlockTitle>
                <div className="flex gap-3 items-center">
                  <div className="flex gap-1 font-medium text-[13px]">
                    {periods.map((el, i) => (
                      <PeriodTag selected={period === i} onClick={() => setPeriod(i)} key={el}>
                        {el}
                      </PeriodTag>
                    ))}
                  </div>
                  <CalendarIcon />
                </div>
              </div>
              <div className="flex flex-1 w-full h-full">
                <Chart
                  lineColor="#2A5CDD"
                  lineWidth={2}
                  labels={labels[period]}
                  tableData={mockData[period]}
                />
              </div>
            </PageBlock>
          </div>
        </div>
        <div className="flex gap-5">
          {jobs.slice(0, 3).map((job, i) => (
            <PageBlock className="flex-1 !items-start font-medium" key={job.position + job.company}>
              <div className="flex items-center gap-3">
                <div
                  className="bg-no-repeat bg-contain w-[32px] h-[32px] rounded-xl"
                  style={{ backgroundImage: `url(${job.src})` }}
                />
                <div className="text-lg font-semibold">{job.company}’s reviews</div>
              </div>

              {job.reviews.map((el, j) => (
                <React.Fragment key={el.name + el.position}>
                  <div className="flex justify-between items-center w-full mb-3 mt-4">
                    <div className="flex gap-2">
                      <div
                        className="bg-no-repeat bg-contain w-[42px] h-[42px] rounded-[50%]"
                        style={{ backgroundImage: `url(${el.src})` }}
                      />
                      <div className="!leading-none">
                        <div className="text-lg font-semibold leading-6">{el.name}</div>
                        <div className="text-base leading-4">{el.position}</div>
                      </div>
                    </div>
                    <div
                      className="bg-no-repeat bg-contain w-[28px] h-[28px] transition-all cursor-pointer"
                      onClick={() => setReviews(reviews.map((el, idx) => (idx === i ? j : el)))}
                      style={{
                        backgroundImage: 'url(/images/up.svg)',
                        transform: `rotate(${reviews[i] === j ? 0 : 180}deg)`,
                      }}
                    />
                  </div>
                  {reviews[i] === j && (
                    <>
                      <div className="font-medium w-full">
                        {Object.entries(el.data).map(([rev, rating]) => (
                          <React.Fragment key={rev + rating}>
                            <Divider />
                            <div className="py-[10px] flex w-full justify-between items-center">
                              <div className="text-[15px]">{rev}</div>
                              <Star5 rating={rating} />
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                      <Button variant="lightBlue" className="mt-3 mb-4">
                        View comments
                      </Button>
                    </>
                  )}
                  <Divider />
                </React.Fragment>
              ))}
            </PageBlock>
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageBlock from '../../../components/PageBlock';
import clsx from 'clsx';
import Selector from '../../../components/Selector/Selector';
import { Skills } from '../../Register/Company/RegisterUser';
import { skillsOptions } from '../../Register/Company/mockData';
import Divider from '../../../components/Divider';

const skills = skillsOptions.sort(() => Math.random() - 0.5).slice(0, 8);

export default function SettingsUser() {
  const [selectors, setSelectors] = useState([true, false, false]);

  const toggle = (index, newValue) => {
    const newSelectors = selectors.map(() => false);
    newSelectors[index] = newValue;
    if (index === 1 && newSelectors.every((el) => !el)) {
      newSelectors[0] = true;
    }
    if ((index === 2 || index === 0) && newSelectors.every((el) => !el)) {
      newSelectors[1] = true;
    }
    setSelectors(newSelectors);
  };

  return (
    <div className="flex flex-col">
      <PageTitle title="Settings" showUser settings />
      <div className="lg:w-[40%] md:w-1/2 w-full">
        <PageBlock className="!items-start !justify-start">
          <div className="w-full flex flex-col gap-y-8 font-bold text-sm mt-4">
            <div className="flex items-center justify-between w-full">
              Available to everyone{' '}
              <Selector
                classNameSelected="!bg-white"
                className={clsx(
                  '!w-[64px] !h-[32px] border cursor-pointer border-2',
                  selectors[0]
                    ? '!bg-[#0446F1] !border-[#0446F1]'
                    : '!bg-[#EAEAEA] !border-[#EAEAEA]',
                )}
                selected={selectors[0]}
                onClick={() => toggle(0, !selectors[0])}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              Hide for everyone
              <Selector
                classNameSelected="!bg-white"
                className={clsx(
                  '!w-[64px] !h-[32px] border cursor-pointer border-2',
                  selectors[1]
                    ? '!bg-[#0446F1] !border-[#0446F1]'
                    : '!bg-[#EAEAEA] !border-[#EAEAEA]',
                )}
                selected={selectors[1]}
                onClick={() => toggle(1, !selectors[1])}
              />
            </div>
            <div className="flex items-center justify-between w-full">
              Available to selected companies
              <Selector
                classNameSelected="!bg-white"
                className={clsx(
                  '!w-[64px] !h-[32px] border cursor-pointer border-2',
                  selectors[2]
                    ? '!bg-[#0446F1] !border-[#0446F1]'
                    : '!bg-[#EAEAEA] !border-[#EAEAEA]',
                )}
                selected={selectors[2]}
                onClick={() => toggle(2, !selectors[2])}
              />
            </div>
          </div>
          <Divider className="my-4" />

          <Skills data={skills} withDelBtn={false} />
        </PageBlock>
      </div>
    </div>
  );
}

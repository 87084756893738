export const mockData = [
  [40, 59, 65, 75, 85, 77, 66].reverse(),
  [40, 59, 65, 75, 85, 77],
  [40, 50, 60, 68, 76, 73, 71, 69, 67, 65, 63, 61],
];

export const getMockDataTotal = (labels, period, totalStyle) => ({
  labels,
  datasets: [
    {
      label: 'Criminal Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#2A5CDD',
      backgroundColor: '#2A5CDD',
    },
    {
      label: 'Credit Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#29B4EF',
      backgroundColor: '#29B4EF',
    },
    {
      label: 'Drug Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#8E25E1',
      backgroundColor: '#8E25E1',
    },
    {
      label: 'Medical Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#17DA7C',
      backgroundColor: '#17DA7C',
    },
    {
      label: 'Employer Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#FD8026',
      backgroundColor: '#FD8026',
    },
    {
      label: 'Peer Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#FD4A23',
      backgroundColor: '#FD4A23',
    },
    {
      label: 'Total Score',
      data: mockData[period].map(() => Math.floor(Math.random() * 101)),
      borderColor: '#000',
      backgroundColor: '#000',
      ...totalStyle,
    },
  ],
});

import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import PeriodTag, { periods } from '../../User/MyScores/PeriodTag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Advantages } from './Advantages';
import Button from '../../../components/Button';
import CalendarIcon from '../../../components/Icons/CalendarIcon';
import Chart from '../../User/MyScores/Chart';
import Divider from '../../../components/Divider';
import { Line } from './Line';
import PageTitle from '../../../components/PageTitle';
import { RateChange } from '../../User/MyScores/RateChange';
import SelfModal from '../../../components/Modals/Self';
import { TagExp } from '../../User/WorkExpirience';
import css from './index.module.css';
import globalController from '../../../mobx/GlobalController';
import { jobs } from '../../User/WorkExpirience/mockData';
import { labels } from '../../User/MyScores';
import { mockData } from '../../User/MyScores/mockData';
import { observer } from 'mobx-react-lite';
import { qas } from '../../../components/Modals/Self/qa';
import { userData } from '../../User/Profile/mockData';

const employees = ['John Smith', 'Will Smith', 'Leam McJoy', 'Jack Carther'];

const generalIdxs = [0, 1, 2, 3];
const securityIdxs = [4, 5];
const profIdxs = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
const companyIdxs = [18, 19, 20, 21, 22];

const Self = observer(() => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const companyID = query.get('companyID');
  const employeeID = query.get('employeeID');

  const qaSSCompany0 = sessionStorage.getItem('qaCompany');
  const qaSSCompany = useMemo(() => (qaSSCompany0 ? JSON.parse(qaSSCompany0) : []), [qaSSCompany0]);

  const account = globalController.getAccount();
  const isCompany = account === 'company';
  const isUser = account === 'user';

  const isNotLogged = !account && companyID;
  const isEmptyCompany = !account && companyID && employeeID;

  const [activeIdx, setActiveIdx] = useState(0);
  const [searchStr, setSearchStr] = useState('');
  const [searchOptionValue, setSearchOptionValue] = useState('');
  const [period, setPeriod] = useState(0);

  const qaSS = sessionStorage.getItem('qa');
  let qa = useMemo(() => (qaSS ? JSON.parse(qaSS) : []), [qaSS]);
  if (isEmptyCompany) {
    if (qa?.length && Array.isArray(qa)) {
      qa = qa.map((el) => (el === -1 ? Math.floor(Math.random() * 5) : el));
    } else {
      qa = Array(qas.length).fill(Math.floor(Math.random() * 5));
    }
    sessionStorage.setItem('qa', JSON.stringify(qa));
  }
  const commentsSS = sessionStorage.getItem('comments');
  const comments = useMemo(() => (commentsSS ? JSON.parse(commentsSS) : []), [commentsSS]);

  const isNotComplete = !qa?.length || (qa?.length && qa.some((el) => el === -1));

  const isModalOpen = globalController.getModals().self;

  const Row = useCallback(
    ({ idx }) => (
      <div className="w-full flex items-center justify-between text-[15px] font-medium gap-1">
        <div className="flex gap-2 items-center my-2">
          <div>
            <div className="inline-flex gap-2">
              {qas[idx]?.name}
              <div
                className="text-[13px] text-[#0446F1] h-5 w-5 bg-[#E3EBFF] flex justify-center items-center select-none"
                style={{ borderRadius: '50%', cursor: 'pointer' }}
                title={qas[idx]?.desc}
              >
                ?
              </div>
            </div>
            {comments[idx] ? <div className="text-[#838383]">Comment: {comments[idx]}</div> : null}
          </div>
        </div>
        {qa[idx] >= 0 ? (
          <div>
            <div className="flex items-center gap-1 justify-end text-right whitespace-nowrap">
              <div>{qas[idx].est[qa[idx]].icon}</div>
              <div>{qas[idx].est[qa[idx]].name}</div>
            </div>
            {qaSSCompany && isCompany ? (
              qaSSCompany[idx] === 1 || qaSSCompany[idx] === 0 ? (
                <div className="text-end text-[#0d0]">Verified</div>
              ) : (
                <div className="text-end text-[#bbb]">Not Verified</div>
              )
            ) : null}
          </div>
        ) : (
          <div>-</div>
        )}
      </div>
    ),
    [comments, isCompany, qa, qaSSCompany],
  );

  useEffect(() => {
    if (!account && (!companyID || !employeeID)) {
      navigate('/');
    }
  }, [account, companyID, employeeID, navigate]);

  return (
    <div className="flex flex-col">
      <PageTitle
        title={isNotLogged ? '' : 'Self-estimation'}
        showUser={!isNotLogged}
        search={false}
        searchValue={searchStr}
        searchOnChange={setSearchStr}
        searchOptionChange={setSearchOptionValue}
        searchOptions={(employees || []).filter((e) =>
          e.toLowerCase().includes(searchStr.toLowerCase()),
        )}
        selectedOption={searchOptionValue}
      />
      {!isNotLogged ? (
        <div className="flex gap-3 mb-6 w-full">
          {jobs.map((job, i) => (
            <div key={i + job.date + job.company} className="relative w-full">
              <TagExp data={job} active={activeIdx === i} onClick={() => setActiveIdx(i)} />
              {!job.rating && isNotComplete && (
                <div
                  onClick={() => isUser && globalController.setModal({ name: 'self', value: true })}
                  className={css.attention}
                  style={{ backgroundImage: 'url(/images/attention.svg)' }}
                >
                  <div>Self-assessment not done</div>
                </div>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="flex items-center justify-between gap-x-8 mb-8 font-medium">
          <PageBlock shadow className="w-full h-full">
            <div className="flex gap-4 pb-6 items-center">
              <img src="/images/mocks/ActiveCampaign.jpg" alt="" />
              <div className="text-[22px] text-center">ActiveCampaign</div>
            </div>
            <div className="text-center text-base leading-none">
              Your employee, John Smith, has indicated that he worked at your company and completed
              a self-assessment. We kindly request verification of his self-asssessment, seeking
              your agreement or disagreement with it.
            </div>
          </PageBlock>
          <PageBlock shadow className="w-full h-full">
            <div className="flex w-full gap-4 p-6">
              <div
                className="min-w-[78px] min-h-[78px] bg-no-repeat bg-contain rounded"
                style={{ backgroundImage: `url(${userData.photo})` }}
              />
              <div className="font-medium flex w-full">
                <div>
                  <div className="flex justify-between w-full">
                    <div className="text-2xl leading-none mb-1">{userData.name}</div>
                    <div
                      className="rounded-xl px-2 py-1 h-fit text-sm leading-none"
                      style={{ border: '1px dashed #0446F1' }}
                    >
                      ID&nbsp;{userData.SSN}
                    </div>
                  </div>
                  <div className="text-base leading-none mb-[6px] w-2/3">{userData.position}</div>
                </div>
              </div>
            </div>
          </PageBlock>
        </div>
      )}
      <PageBlock
        shadow
        className="mb-6 font-medium cursor-pointer"
        onClick={() => {
          globalController.setModal({ name: 'self', value: true });
        }}
      >
        <div className="text-[28px]">
          {isCompany || isNotLogged ? (
            <div className="text-center">
              Unlock your Employees Potential: Complete Verification
              <br />
              Click here to fill form
            </div>
          ) : (
            'Unlock Your Potential: Complete Your Self-Assessment!'
          )}
        </div>
      </PageBlock>
      {isNotLogged ? null : isNotComplete || isCompany ? (
        <PageBlock
          shadow
          bg="linear-gradient(123deg, rgba(255, 255, 255, 0.31) 54.08%, rgba(4, 70, 241, 0.84) 129.04%)"
        >
          <Advantages
            isCompany={isCompany || isNotLogged}
            isComplete={!isNotComplete}
            isUser={isUser}
          />
        </PageBlock>
      ) : (
        <Advantages
          isCompany={isCompany || isNotLogged}
          isComplete={!isNotComplete}
          isUser={isUser}
        />
      )}
      <PageBlock shadow className="font-medium mt-6 !items-start !justify-start">
        <PageBlockTitle>Progress bar</PageBlockTitle>
        <div className="flex justify-between w-full text-[15px] mt-4 mb-6">
          {isNotComplete ? (
            <>
              <div>Attendance 0%</div>
              <div>Attendance 0-50%</div>
              <div>Attendance 50-100%</div>
              <div>Attendance 100%</div>
            </>
          ) : (
            <>
              <div className="text-center">
                Need self-assessment
                <br />
                0%
              </div>
              <div className="text-center">
                Self-assessment
                <br />
                25%
              </div>
              <div className="text-center">
                Need company verification
                <br />
                50%
              </div>
              <div className="text-center">
                Company verification
                <br />
                50%
              </div>
              <div className="text-center">
                Assessment completed
                <br />
                100%
              </div>
            </>
          )}
        </div>
        <div className={isNotComplete ? 'w-full' : 'w-[95%] mx-auto'}>
          {/* <Line percent={isCompany ?(qaSSCompany?.filter((el) => el !== -1).length / qaSSCompany.length) * 100 : 0) : (qa?.filter((el) => el !== -1).length / qa.length) * 100 : 0} /> */}
          <Line
            percent={
              isCompany
                ? qaSSCompany
                  ? (qaSSCompany?.filter((el) => el !== -1).length / qaSSCompany.length) * 100
                  : 0
                : qa
                ? (qa?.filter((el) => el !== -1).length / qa.length) * 100
                : 0
            }
          />
        </div>
      </PageBlock>
      {isNotComplete ? null : (
        <div className="mt-6 flex flex-col gap-6">
          <div className="flex gap-3">
            <PageBlock className="!items-start !justify-start w-full" shadow>
              <div className="flex gap-2 mb-1">
                <PageBlockTitle>General assessment</PageBlockTitle>
                <Button
                  variant="smallBlue"
                  onClick={() => globalController.setModal({ name: 'self', value: true })}
                >
                  Edit
                </Button>
              </div>
              {generalIdxs.map((idx, i, arr) => (
                <React.Fragment key={`${idx}-${i}`}>
                  <Row idx={idx} />
                  {i !== arr.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </PageBlock>
            <PageBlock className="!items-start !justify-start w-full" shadow>
              <div className="flex gap-2 mb-1">
                <PageBlockTitle>Security issues</PageBlockTitle>
                <Button
                  variant="smallBlue"
                  onClick={() => globalController.setModal({ name: 'self', value: true })}
                >
                  Edit
                </Button>
              </div>
              {securityIdxs.map((idx, i, arr) => (
                <React.Fragment key={`${idx}-${i}`}>
                  <Row idx={idx} />
                  {i !== arr.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </PageBlock>
          </div>
          <PageBlock className="!items-start !justify-start w-full" shadow>
            <div className="flex gap-2 mb-1">
              <PageBlockTitle>Professional assessment</PageBlockTitle>
              <Button
                variant="smallBlue"
                onClick={() => globalController.setModal({ name: 'self', value: true })}
              >
                Edit
              </Button>
            </div>
            <div className="grid grid-rows-6 grid-cols-2 grid-flow-col w-full gap-x-8">
              {profIdxs.map((idx, i, arr) => (
                <div className="w-full" key={`${idx}-${i}`}>
                  <Row idx={idx} />
                  {i !== arr.length - 1 && i !== Math.floor(arr.length / 2) - 1 && <Divider />}
                </div>
              ))}
            </div>
          </PageBlock>
          <PageBlock className="!items-start !justify-start w-full" shadow>
            <div className="flex gap-2 mb-1">
              <PageBlockTitle>Company assessment</PageBlockTitle>
              <Button
                variant="smallBlue"
                onClick={() => globalController.setModal({ name: 'self', value: true })}
              >
                Edit
              </Button>
            </div>
            {companyIdxs.map((idx, i, arr) => (
              <React.Fragment key={`${idx}-${i}`}>
                <Row idx={idx} />
                {i !== arr.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </PageBlock>

          {!isNotLogged && (
            <PageBlock className="w-full h-full flex-col" shadow>
              <div className="flex w-full justify-between items-center mb-4">
                <div className="flex gap-3">
                  <PageBlockTitle>Statistic</PageBlockTitle>
                  <RateChange percent={2.45} />
                </div>
                <div className="flex gap-3 items-center">
                  <div className="flex gap-1 font-medium text-[13px]">
                    {periods.map((el, i) => (
                      <PeriodTag selected={period === i} onClick={() => setPeriod(i)} key={i + el}>
                        {el}
                      </PeriodTag>
                    ))}
                  </div>
                  <CalendarIcon />
                </div>
              </div>
              <div className="flex flex-1 w-full h-full">
                <Chart
                  lineColor="#2A5CDD"
                  lineWidth={2}
                  labels={labels[period]}
                  tableData={mockData[period]}
                  xGrid
                  yGrid
                />
              </div>
            </PageBlock>
          )}
        </div>
      )}

      {isModalOpen && (
        <SelfModal
          visible={isModalOpen}
          onCancel={() => {
            globalController.setModal({ name: 'self', value: false });
          }}
          isCompany={isCompany || isNotLogged}
        />
      )}
    </div>
  );
});

export default Self;

import React from 'react';

export default function CirclesThin({ className }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 470 470" className={className}>
      <g filter="url(#a123)">
        <path
          fill="#74DC34"
          d="M382.804 147.804c48.157 81.63 21.022 186.843-60.608 235-81.63 48.157-186.843 21.022-235-60.608-48.157-81.63-21.022-186.843 60.608-235 81.63-48.157 186.843-21.022 235 60.608ZM100.809 314.165c43.722 74.111 139.244 98.747 213.356 55.026 74.111-43.722 98.747-139.244 55.026-213.355-43.722-74.112-139.244-98.748-213.356-55.027-74.111 43.722-98.747 139.244-55.026 213.356Z"
        />
      </g>
      <g filter="url(#b123)">
        <path
          fill="#F4F4F4"
          d="M377.029 316.406c3.787 2.17 8.634.868 10.628-3.015a171.608 171.608 0 0 0-145.69-249.857c-4.361-.177-7.882 3.4-7.858 7.764.023 4.364 3.583 7.862 7.943 8.06a155.8 155.8 0 0 1 131.875 226.164c-1.975 3.892-.684 8.713 3.102 10.884Z"
        />
      </g>
      <g filter="url(#c123)">
        <path
          fill="#000"
          d="M348.297 312.328c-42.708 62.753-128.2 79.004-190.953 36.296-62.753-42.707-79.003-128.199-36.296-190.952C163.755 94.919 249.247 78.668 312 121.376c62.753 42.707 79.004 128.199 36.297 190.952ZM131.513 164.794c-38.773 56.973-24.02 134.591 32.953 173.365s134.591 24.02 173.365-32.953 24.02-134.591-32.953-173.365-134.591-24.02-173.365 32.953Z"
        />
      </g>
      <g filter="url(#d123)">
        <path
          fill="#F4F4F4"
          d="M224.168 365.69c-.28 3.485 2.318 6.552 5.811 6.671a137.434 137.434 0 0 0 96.728-35.284 137.447 137.447 0 0 0 45.37-98.894 137.453 137.453 0 0 0-40.741-100.888 137.442 137.442 0 0 0-94.991-39.726c-3.495-.043-6.232 2.901-6.114 6.394.118 3.494 3.048 6.213 6.543 6.272a124.79 124.79 0 0 1 85.659 36.059 124.791 124.791 0 0 1 36.988 91.596 124.79 124.79 0 0 1-41.19 89.785 124.795 124.795 0 0 1-87.237 32.053c-3.494-.103-6.546 2.478-6.826 5.962Z"
        />
      </g>
      <g filter="url(#e123)">
        <path
          fill="#FFCB14"
          d="M322.733 294.45c-32.817 48.22-98.509 60.706-146.729 27.89-48.22-32.816-60.707-98.509-27.89-146.729 32.816-48.219 98.509-60.706 146.729-27.89 48.219 32.816 60.706 98.509 27.89 146.729ZM156.156 181.084c-29.794 43.778-18.458 103.421 25.321 133.214 43.778 29.794 103.42 18.457 133.214-25.321 29.794-43.778 18.457-103.42-25.321-133.214-43.779-29.794-103.421-18.457-133.214 25.321Z"
        />
      </g>
      <g filter="url(#f123)">
        <path
          fill="#F4F4F4"
          d="M151.811 291.671c-2.125 1.593-2.639 4.579-1.072 6.723 9.83 13.457 22.028 24.133 37.719 31.217 16.4 8.271 35.277 11.968 53.668 10.828a105.618 105.618 0 0 0 87.721-57.771 105.611 105.611 0 0 0-93.941-153.248c-2.686-.008-4.769 2.273-4.653 4.957.115 2.684 2.386 4.751 5.072 4.785 14.993.187 29.515 4.208 42.928 11.147 14.859 7.686 27.555 18.751 36.624 32.808 9.069 14.057 14.382 29.837 15.26 46.542.877 16.706-3 33.019-9.919 48.069-8.34 14.706-18.837 27.422-32.809 36.623-13.971 9.201-30.188 15.344-46.885 16.379a95.881 95.881 0 0 1-82.462-37.933c-1.721-2.279-4.966-2.839-7.251-1.126Z"
        />
      </g>
      <g filter="url(#g123)">
        <path
          fill="#ED2C2C"
          d="M275.074 297.834c-34.854 21.751-80.741 11.129-102.492-23.725-21.751-34.854-11.129-80.741 23.725-102.492 34.854-21.751 80.742-11.129 102.492 23.725 21.751 34.854 11.129 80.741-23.725 102.492ZM199.935 177.43c-31.644 19.747-41.288 61.408-21.54 93.052 19.747 31.643 61.408 41.287 93.052 21.54 31.644-19.748 41.287-61.409 21.54-93.053-19.748-31.643-61.408-41.287-93.052-21.539Z"
        />
      </g>
      <g filter="url(#h123)">
        <path
          fill="#F4F4F4"
          d="M166.63 246.56c-2.541 0-4.468 2.301-3.859 4.768 3.079 12.471 9.387 24.753 18.057 34.029 9.774 10.458 22.734 18.623 36.623 22.127 13.89 3.504 29.437 2.157 42.994-2.414a74.14 74.14 0 0 0 34.375-24.134 73.894 73.894 0 0 0 15.698-38.906 74.08 74.08 0 0 0-8.008-41.218 74.482 74.482 0 0 0-29.149-30.331c-11.303-6.46-24.458-10.27-37.497-10.345-2.124-.012-3.714 1.873-3.474 3.984.22 1.938 1.912 3.357 3.863 3.378 11.798.122 23.421 3.956 33.844 9.515 10.682 6.867 20.721 17.471 26.705 28.994 5.341 12.208 6.946 23.119 5.708 36.023-1.239 12.904-6.471 22.727-14.101 34.172-8.393 9.918-17.549 16.785-29.756 22.126-12.308 4.15-25.978 4.936-38.588 1.754a67.727 67.727 0 0 1-32.913-19.419 67.449 67.449 0 0 1-16.425-30.764c-.447-1.922-2.124-3.339-4.097-3.339Z"
        />
      </g>
      <defs>
        <filter
          id="a123"
          width="343.27"
          height="346.27"
          x="63.365"
          y="63.365"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="b123"
          width="172.499"
          height="256.925"
          x="234.108"
          y="63.527"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="c123"
          width="274.91"
          height="277.91"
          x="97.217"
          y="97.545"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="d123"
          width="147.967"
          height="277.873"
          x="224.146"
          y="97.568"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="e123"
          width="211.241"
          height="214.242"
          x="129.803"
          y="129.41"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="f123"
          width="191.374"
          height="214.22"
          x="149.827"
          y="129.42"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="g123"
          width="148.798"
          height="151.798"
          x="161.292"
          y="160.326"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
        <filter
          id="h123"
          width="148.198"
          height="152.331"
          x="162.657"
          y="160.136"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow_507_3807" />
        </filter>
      </defs>
    </svg>
  );
}

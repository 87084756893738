const points = [
  '24/7 Access to EPASS Dashboard & Analytics',
  'Employment History Assessment',
  'Peer score and reviews',
  'Biometrics (ID verification)',
  'SSN Trace',
  'Sex Offender Registry',
  'Global Watchlist Records',
  'National Criminal Records',
  'County Criminal Search (1)',
  'Multiple County Criminal Search (3)',
  'Education Verification (Highest level)',
  'Employment Verification (Current)',
  'Medical Check',
  'Credit Check',
  'Federal Criminal Search',
  'EPASS Score',
];

export const mockTariffs = [
  {
    name: 'Basic',
    points,
    available: points.map((_, i) => (i < 5 ? true : false)),
    price: 9.9,
    price2: 9.9,
  },
  {
    name: 'Essential',
    points,
    available: points.map((_, i) => (i < 9 || i === 10 || i === 11 ? true : false)),
    price: 39,
    price2: 19,
  },
  {
    name: 'Pro',
    points,
    available: points.map(() => true),
    price: 59,
    price2: 29,
  },
];

// export const mockTariffz = [
//   {
//     name: 'Basic',
//     points: [
//       'Employee data',
//       'Score (credit, drug, criminal, health, reviews from companies, reviews from peers)',
//       'Working experience',
//       'Education',
//       'Skills',
//       'Advanced information on each score',
//       'Continuous data updating',
//     ],
//     available: [true, true, true, true, true, false, false],
//     price: 60,
//   },
//   {
//     name: 'Advanced',
//     points: [
//       'Employee data',
//       'Score (credit, drug, criminal, health, reviews from companies, reviews from peers)',
//       'Working experience',
//       'Education',
//       'Skills',
//       'Advanced information on each score',
//       'Continuous data updating',
//     ],
//     available: [true, true, true, true, true, true, false],
//     price: 130,
//   },
//   {
//     name: 'PRO',
//     points: [
//       'Employee data',
//       'Score (credit, drug, criminal, health, reviews from companies, reviews from peers)',
//       'Working experience',
//       'Education',
//       'Skills',
//       'Advanced information on each score',
//       'Continuous data updating',
//     ],
//     available: [true, true, true, true, true, true, true],
//     price: 230,
//   },
// ];

export const mockPayments = [
  {
    name: 'John Doe',
    date: '12.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 100,
    count: 5,
    method: 'Credit card',
  },
  {
    name: 'Jane Doe',
    date: '13.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 50,
    count: 2,
    method: 'Invoice',
  },
  {
    name: 'Bob Smith',
    date: '14.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 75,
    count: 3,
    method: 'Credit card',
  },
  {
    name: 'Alice Johnson',
    date: '15.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 200,
    count: 1,
    method: 'Credit card',
  },
  {
    name: 'David Lee',
    date: '16.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 150,
    count: 1,
    method: 'Credit card',
  },
  {
    name: 'Sarah Brown',
    date: '17.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 300,
    count: 2,
    method: 'Credit card',
  },
  {
    name: 'Michael Wilson',
    date: '18.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 500,
    count: 1,
    method: 'Invoice',
  },
  {
    name: 'Emily Davis',
    date: '19.12.2023',
    desc: ['Check new employees', 'Check current employees', 'Continuous checks'][
      Math.floor(Math.random() * 3)
    ],
    amount: 250,
    count: 1,
    method: 'Credit card',
  },
];

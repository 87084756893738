export const offers = [
  [
    {
      name: 'Chase Bank',
      text: 'Bank offers mortgage restructuring for 1% below your current rate. ',
      type: 'upgrade',
      time: 'Due in 5 days',
      icon: 'chasebank.png',
      bg: 'bank.webp',
    },
    {
      name: 'Metlife',
      text: 'Insurance offers a 20% discount on life insurance',
      type: '',
      time: 'Due in 5 days',
      icon: 'metlife.jpg',
      bg: 'metlife.webp',
    },
    {
      name: 'Delta Airlines',
      text: 'Airline offers a 20% discount on tickets',
      type: 'details',
      time: 'Due in 5 days',
      icon: 'chasebank.png',
    },
    {
      name: 'Hilton',
      text: 'Hotel Hilton offers a 20% accommodation discount and free breakfast for two people.',
      type: 'details',
      time: 'Due in 5 days',
      icon: 'metlife.jpg',
    },
  ],
  [
    {
      name: 'Emirates Airlines',
      text: 'Airline offers a 15% discount on tickets to any country in the world.',
      type: 'details',
      time: 'Due in 7 days',
      icon: 'chasebank.png',
    },
    {
      name: 'Qatar Airways',
      text: 'Airline offers a 30% discount on business class tickets for two people.',
      type: '',
      time: 'Due in 7 days',
      icon: 'metlife.jpg',
    },
    {
      name: 'Turkish Airlines',
      text: 'Airline offers premium miles for every purchased ticket.',
      type: '',
      time: 'Due in 7 days',
      icon: 'chasebank.png',
    },
  ],
  [
    {
      name: 'New Insurance',
      text: 'Insurance company offers a 25% discount on property insurance.',
      type: '',
      time: 'Due in 3 days',
      icon: 'chasebank.png',
    },
    {
      name: 'Sogaz',
      text: 'Insurance company holds a promotion on travel insurance: 20% off when buying a policy for two people.',
      type: '',
      time: 'Due in 3 days',
      icon: 'metlife.jpg',
    },
    {
      name: 'Renaissance Insurance',
      text: 'Insurance company provides free medical consultation when buying an annual insurance policy.',
      type: '',
      time: 'Due in 3 days',
      icon: 'chasebank.png',
    },
  ],
  [
    {
      name: 'Tinkoff Bank',
      text: 'Bank offers personal loans up to ₽2 million at 12% per annum.',
      type: '',
      time: 'Due in 2 days',
      icon: 'chasebank.png',
    },
    {
      name: 'VTB Bank',
      text: 'Bank holds a promotion on mortgages with only a 10% down payment and an interest rate starting at 6.5% per annum.',
      type: '',
      time: 'Due in 2 days',
      icon: 'metlife.jpg',
    },
    {
      name: 'Open Bank',
      text: 'Bank gives cashback for purchases made with a credit card, up to 10% on all purchases in the first time.',
      type: '',
      time: 'Due in 2 days',
      icon: 'chasebank.png',
    },
  ],
  [
    {
      name: 'Marriott Hotels',
      text: 'Hotel chain offers a 20% discount on accommodation and dining.',
      type: '',
      time: 'Due in 4 days',
      icon: 'chasebank.png',
    },
    {
      name: 'InterContinental Hotels Group',
      text: 'Hotel chain holds a promotion on loyalty program membership with free room upgrades and late check-outs.',
      type: '',
      time: 'Due in 4 days',
      icon: 'metlife.jpg',
    },
    {
      name: 'Hilton Hotels & Resorts',
      text: 'Hotel chain provides free breakfast for two people and a 20% accommodation discount.',
      type: '',
      time: 'Due in 4 days',
      icon: 'chasebank.png',
    },
  ],
  [
    {
      name: 'Avis Car Rental',
      text: 'Car rental company offers a 15% discount on car rentals.',
      type: '',
      time: 'Due in 1 day',
      icon: 'chasebank.png',
    },
    {
      name: 'Budget Car Rental',
      text: 'Car rental company provides a free upgrade to a higher car class.',
      type: '',
      time: 'Due in 1 day',
      icon: 'metlife.jpg',
    },
    {
      name: 'Enterprise Rent-A-Car',
      text: 'Car rental company holds a promotion on weekend rentals with unlimited mileage.',
      type: '',
      time: 'Due in 1 day',
      icon: 'chasebank.png',
    },
  ],
  [
    {
      name: 'Zillow Rentals',
      text: 'Rental Apartments offers $500 off your first month rent.',
      type: '',
      time: 'Due in 6 days',
      icon: 'chasebank.png',
    },
    {
      name: 'Apartments.com',
      text: 'Rental Apartments offers one month free rent with a signed lease.',
      type: '',
      time: 'Due in 6 days',
      icon: 'metlife.jpg',
    },
    {
      name: 'Redfin Rentals',
      text: 'Rental Apartments offers $1000 off your security deposit.',
      type: '',
      time: 'Due in 6 days',
      icon: 'chasebank.png',
    },
  ],
];

import React from 'react';
import { AlertIcon } from './AlertIcon';

export const ErrorField = ({ errors, name }) =>
  errors[name] ? (
    <div className="font-thin text-xs text-[#FF4747] flex gap-1 items-center w-fit ml-auto">
      <AlertIcon />
      {errors[name]}
    </div>
  ) : null;

import { Modal } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../Button';
import { qas } from './qa';
import { Line } from '../../../modules/Company/Self/Line';
import { InputDiv } from '../../Select/Select';
import css from './index.module.css';
import clsx from 'clsx';

interface IProps {
  visible: boolean;
  onCancel: () => void;
  isCompany?: boolean;
}

const percents = [0, 25, 50, 75, 100];

const SelfModal = observer(({ visible, onCancel, isCompany = false }: IProps) => {
  const [qaData, setQAData] = useState<number[]>([]);
  const [qaCompany, setQACompany] = useState<number[]>([]);
  const [comments, setComments] = useState<string[]>([]);
  const [qaIndex, setQAIndex] = useState(0);
  const [isShowFinalQ, setIsShowFinalQ] = useState(false);
  const [data, setData] = useState({
    company: '',
    ein: '',
    HRname: '',
    HRemail: '',
    secName: '',
    secEmail: '',
    immName: '',
    immEmail: '',
  });

  console.log('qaData.length', qaData.length);
  console.log('qaCompany.length', qaCompany.length);
  console.log('qaIndex', qaIndex);

  const handleStep = useCallback(() => {
    sessionStorage.setItem('qa', JSON.stringify(qaData));
    sessionStorage.setItem('comments', JSON.stringify(comments));
    // sessionStorage.setItem('qaCompany', JSON.stringify(qaCompany));
  }, [comments, qaData]);

  const handleFinish = useCallback(() => {
    handleStep();
    if (!isCompany) {
      setIsShowFinalQ(true);
    } else {
      onCancel();
    }
  }, [handleStep, isCompany, onCancel]);

  const handleFinalQ = () => {
    handleStep();
    sessionStorage.setItem('finalQ', JSON.stringify(data));
    onCancel();
  };

  const verification = useCallback(
    (val: number) => {
      const newData = qaCompany.map((p: number, i: number) => (i === qaIndex ? val : p));
      setQACompany(newData);
      sessionStorage.setItem('qaCompany', JSON.stringify(newData));
      if (qaIndex >= qas.length - 1) {
        handleFinish();
      }
      setQAIndex((prev) => prev + 1);
    },
    [handleFinish, qaCompany, qaIndex],
  );

  useEffect(() => {
    const commentsSS = sessionStorage.getItem('comments');
    const qaSS = sessionStorage.getItem('qa');
    const qaSSCompany = sessionStorage.getItem('qaCompany');
    const qaP = qaSS ? JSON.parse(qaSS) : qas.map(() => -1);
    const qaC = qaSSCompany ? JSON.parse(qaSSCompany) : qas.map(() => -1);
    const commentsP = commentsSS ? JSON.parse(commentsSS) : qas.map(() => '');
    setQAData(qaP);
    setQACompany(qaC);
    setComments(commentsP);
    setQAIndex(0);
  }, []);

  useEffect(() => {
    if (qaIndex === 0) setIsShowFinalQ(false);
  }, [qaIndex]);

  return (
    <Modal
      // className={css.modal}
      className={clsx(isShowFinalQ ? '!w-1/2' : '!w-[95%]')}
      wrapClassName={css.wrapper}
      open={visible}
      onCancel={onCancel}
      title={
        isShowFinalQ ? (
          <h3 className="text-[22px] text-center font-medium mb-7 bg-[#f6f7f9]">
            Please fill in the following information
          </h3>
        ) : (
          <h3 className="text-[22px] text-center font-medium mb-7 bg-[#f6f7f9]">
            {qaIndex >= 0 && qaIndex <= 3 && 'General assessment'}
            {qaIndex >= 4 && qaIndex <= 5 && 'Security issues'}
            {qaIndex >= 6 && qaIndex <= 17 && 'Professional assessment'}
            {qaIndex >= 18 && 'Company assessment'}
          </h3>
        )
      }
      footer={null}
    >
      {isShowFinalQ ? (
        <div className="flex flex-col gap-4">
          <input
            placeholder="Company Name"
            className={css.input2}
            value={data.company || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, company: e.target.value }))}
          />
          <input
            placeholder="EIN"
            className={css.input2}
            value={data.ein || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, ein: e.target.value }))}
          />
          <input
            placeholder="Name of your HR manager"
            className={css.input2}
            value={data.HRname || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, HRname: e.target.value }))}
          />
          <input
            placeholder="Email of your HR manager"
            className={css.input2}
            value={data.HRemail || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, HRemail: e.target.value }))}
          />
          <input
            placeholder="Name of your Security manager"
            className={css.input2}
            value={data.secName || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, secName: e.target.value }))}
          />
          <input
            placeholder="Email of your Security manager"
            className={css.input2}
            value={data.secEmail || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, secEmail: e.target.value }))}
          />
          <input
            placeholder="Name of your Immediate manager"
            className={css.input2}
            value={data.immName || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, immName: e.target.value }))}
          />
          <input
            placeholder="Email of your Immediate manager"
            className={css.input2}
            value={data.immEmail || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, immEmail: e.target.value }))}
          />
          <Button
            variant="bigBlue"
            onClick={() => {
              handleFinalQ();
            }}
            className="!w-[160px] mx-auto mt-8"
            disabled={Object.values(data).some((el) => !el)}
          >
            {qaIndex < qas.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </div>
      ) : (
        <div className="flex flex-col w-full font-medium !bg-[#F6F7F9] min-h-[66vh]">
          <div className="w-full flex justify-between mb-2">
            {percents.map((percent, i) => (
              <div className="text-[15px]" key={percent + i}>
                {percent}%
              </div>
            ))}
          </div>
          <Line
            percent={
              ((isCompany ? qaCompany : qaData).filter((el: number) => el !== -1).length /
                qas.length) *
              100
            }
          />
          <div className="mt-6 flex flex-col items-center w-full">
            <div className="text-[24px]">{qas[qaIndex].name}</div>
            <div className="text-[15px] text-center w-3/4">{qas[qaIndex].desc}</div>
            <div className="text-[15px] mt-2 mb-4 font-bold">Choise the best option for you.</div>
            <div className="flex gap-3">
              {qas[qaIndex].est.map((el, i) => (
                <div className={clsx(css.card, qaData[qaIndex] === i && css.active)} key={i}>
                  <div className="inline-flex gap-2">
                    <div>{el.icon}</div>
                    <div>{el.name}</div>
                  </div>
                  <div className={css.cardDesc}>{el.desc}</div>
                  <div
                    className={clsx(
                      'text-[#0446F1] text-[13px] mt-auto w-full flex justify-center pt-4',
                      qaData[qaIndex] === i && '!text-[#000]',
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={() =>
                      !isCompany &&
                      setQAData((prev: number[]) => {
                        const arr = [...prev];
                        arr[qaIndex] = i;
                        return arr;
                      })
                    }
                  >
                    {qaData[qaIndex] !== i ? (isCompany ? '' : 'Choose') : 'Selected'}
                  </div>
                </div>
              ))}
            </div>

            {isCompany ? (
              comments[qaIndex] ? (
                <div className="my-6 mx-auto text-[15px] font-medium">
                  Comment from employee: {comments[qaIndex]}
                </div>
              ) : (
                <div className="h-10 w-1" />
              )
            ) : (
              <InputDiv
                placeholder="Please write here your comment that describes your estimation"
                label={undefined}
                color={undefined}
                containerClassName="my-6 w-3/4 mx-auto"
                className={css.input}
                onChange={(e: any) =>
                  setComments((prev: string[]) => {
                    const arr = [...prev];
                    arr[qaIndex] = e.target.value;
                    return arr;
                  })
                }
                value={comments[qaIndex]}
                disabled={undefined}
                searchIcon={undefined}
                children={undefined}
              />
            )}
          </div>
          {isCompany ? (
            <div className="flex gap-4 w-fit mx-auto select-none mt-auto">
              <Button
                onClick={() => {
                  verification(1);
                }}
                className="!w-[140px] !h-[30px] !bg-[#DEEED4] !text-[20px] !text-[#5ECA1B]"
              >
                Confirm
              </Button>
              <Button
                onClick={() => {
                  verification(0);
                }}
                className="!w-[140px] !h-[30px] !bg-[#fff] !text-[20px]"
              >
                Refrain
              </Button>
            </div>
          ) : (
            <div className="flex gap-4 w-fit mx-auto select-none mt-auto">
              {qaIndex > 0 && (
                <Button
                  variant="bigBlue"
                  onClick={() => {
                    handleStep();
                    setQAIndex((prev) => prev - 1);
                  }}
                  className="!w-[160px]"
                >
                  Back
                </Button>
              )}
              <Button
                variant="bigBlue"
                onClick={() => {
                  if (qaIndex < qas.length - 1) {
                    handleStep();
                    setQAIndex((prev) => prev + 1);
                  } else {
                    handleFinish();
                  }
                }}
                className="!w-[160px]"
              >
                {qaIndex < qas.length - 1 ? 'Next' : 'Finish'}
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
});

export default SelfModal;

import React from 'react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import css from './index.module.css';
import globalController from '../../mobx/GlobalController';

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
  variant?: string;
}

const Button = observer(
  ({
    className,
    onClick,
    children,
    loading = false,
    disabled = false,
    variant,
    ...otherProps
  }: IProps) => (
    <button
      {...otherProps}
      className={clsx(
        css.blueButton,
        variant === 'smallBlue' && css.smallBlue,
        variant === 'mediumBlue' && css.mediumBlue,
        variant === 'bigBlue' && css.bigBlue,
        variant === 'lightBlue' && css.lightBlue,
        className,
      )}
      disabled={globalController.waitForAnswer || loading || disabled}
      onClick={onClick}
    >
      {children}
    </button>
  ),
);

export default Button;

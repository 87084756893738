export const mockResults = [
  {
    name: 'David B.',
    position: 'Security specialist',
    src: '/images/mocks/photos/DavidB.jpg',
    points: 43,
  },
  {
    name: 'Ilon A.',
    position: 'Security specialist',
    src: '/images/mocks/photos/IlonAsk.jpg',
    points: 61,
  },
  {
    name: 'Iren L.',
    position: 'Security specialist',
    src: '/images/mocks/photos/IrenLi.jpg',
    points: 77,
  },
  {
    name: 'Mary D.',
    position: 'Security specialist',
    src: '/images/mocks/photos/MaryD.jpg',
    points: 45,
  },
  {
    name: 'Natali H.',
    position: 'Security specialist',
    src: '/images/mocks/photos/NataliH.jpg',
    points: 63,
  },
  {
    name: 'Sten L.',
    position: 'Security specialist',
    src: '/images/mocks/photos/StenL.jpg',
    points: 35,
  },
];

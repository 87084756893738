import PropTypes from 'prop-types';
import React from 'react';

const Layout = ({ children, device }) => <div className="layout-container">{children}</div>;

Layout.propTypes = {
  children: PropTypes.node,
  device: PropTypes.oneOf(['mobile', 'tablet', 'desktop']),
};

export default Layout;

export const userData = {
  name: 'Vladlen Voitenko',
  photo: '/images/mocks/user-photo.jpg',
  SSN: '****321',
  position: 'Product Middle Designer, UX/UI & Web/mobile designer',
  place: 'San Francisco, CA, USA (GMT-7)',
  job: 'ActiveCampaign',
  jobsrc: '/images/mocks/ActiveCampaign.jpg',
  cvLink: 'https://www.example.com/cv.pdf',
  totalWorkTime: '72 month',
  avgWorkTime: '12 month',

  about: `Design is not just a profession for me; it's a way of life. From an early age, I found myself captivated by the power of visuals and how they can evoke emotions, tell stories, and create a lasting impact. As a designer, I constantly seek to blend functionality with aesthetics to deliver unique and memorable experiences. 
  
  💼 Work Experience:
  Over the last [X] years, I've had the privilege of working with some amazing companies, agencies, and clients from diverse industries. Each opportunity has added valuable layers to my skills and expertise. Through these experiences, I've honed my ability to adapt to various design challenges while keeping user-centricity at the forefront.
  
  During my journey, I have:
  🔹 Led Creative Initiatives: I've successfully led cross-functional teams, collaborating with stakeholders to conceptualize and execute design projects that align with brand strategies and business goals.
  🔹 UX/UI Design: Crafting seamless user experiences and intuitive interfaces is my forte. I immerse myself in understanding user behavior, conducting research, and wireframing to deliver designs that strike a perfect balance between usability and aesthetics.
  🔹 Visual Branding: Building and revamping brand identities excite me. From logo design to choosing the perfect color palette, I love curating visual elements that leave a lasting impression.
  🔹 Print & Digital Collateral: Whether it's designing eye-catching brochures, posters, or engaging digital content, I ensure that each piece aligns with the brand ethos and tells a compelling story.
  🔹 Web & Mobile Design: Creating responsive and user-friendly web and mobile interfaces is my passion. I thrive on the challenges that come with designing for different platforms and devices.
  🔹 Illustration & Animation: As a lover of storytelling, I often incorporate illustrations and animations to bring life and dynamism to my designs.
  🎯 My Approach:
  For me, every project is an opportunity to create something extraordinary. I believe in a collaborative and iterative design process, where feedback and continuous improvement play a crucial role. By staying updated with the latest design trends and technologies, I aim to provide cutting-edge solutions that push the boundaries of creativity.
  🌟 Let's Connect!
  I'm always open to new opportunities, challenges, and creative discussions. Whether you're interested in my work, wish to collaborate, or simply want to chat about design, feel free to drop me a message. Let's connect and explore the exciting world of design together!`,

  jobs: [
    {
      src: '/images/mocks/ActiveCampaign.jpg',
      company: 'ActiveCampaign',
      position: 'Product Middle Designer',
      rating: 0,
      years: '2022 - Now',
      duration: '',
      responsibilities: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'Design Concept',
        'User Flow',
        'UI Design',
        'Visual Design',
        'Interaction Design',
        'User Testing',
        'Front-End Development',
        'Back-End Development',
        'Database Management',
        'Project Management',
        'Agile Methodologies',
        'Content Strategy',
      ],
    },
    {
      src: '/images/mocks/AirTable.jpg',
      company: 'AirTable',
      position: 'Product Middle Designer',
      rating: 4.6,
      years: '2022 - 2023',
      duration: '1 yr.',
      responsibilities: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'User Flow',
        'UI Design',
        'Visual Design',
        'Interaction Design',
        'User Testing',
        'Front-End Development',
        'Back-End Development',
        'Database Management',
        'Agile Methodologies',
        'Content Strategy',
      ],
    },
    {
      src: '/images/mocks/Adobe.jpg',
      company: 'Adobe',
      position: 'Product Middle Designer',
      rating: 4.7,
      years: '2022 - 2023',
      duration: '1 yr.',
      responsibilities: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'Design Concept',
        'User Flow',
        'Visual Design',
        'Interaction Design',
        'User Testing',
        'Back-End Development',
        'Database Management',
        'Agile Methodologies',
        'Content Strategy',
      ],
    },
    {
      src: '/images/mocks/ActiveCampaign.jpg',
      company: 'Microsoft',
      position: 'Product Middle Designer',
      rating: 4.0,
      years: '2022 - 2023',
      duration: '1 yr.',
      responsibilities: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'Design Concept',
        'User Flow',
        'UI Design',
        'Visual Design',
        'Interaction Design',
        'User Testing',
        'Front-End Development',
        'Back-End Development',
        'Database Management',
        'Project Management',
        'Agile Methodologies',
        'Content Strategy',
      ],
    },
    {
      src: '/images/mocks/ActiveCampaign.jpg',
      company: 'Tesla',
      position: 'Product Middle Designer',
      rating: 4.2,
      years: '2022 - 2023',
      duration: '1 yr.',
      responsibilities: [
        'User Research',
        'Wireframing',
        'Prototyping',
        'Design Concept',
        'User Flow',
        'UI Design',
        'Visual Design',
        'Interaction Design',
        'User Testing',
        'Front-End Development',
        'Back-End Development',
        'Database Management',
        'Project Management',
        'Agile Methodologies',
        'Content Strategy',
      ],
    },
  ],

  languages: [
    {
      language: 'English',
      level: 'Native',
    },
    {
      language: 'Spanish',
      level: 'Basic',
    },
    {
      language: 'French',
      level: 'Basic',
    },
  ],
  EmployerReviews: {
    place: 'ActiveCampaign',
    src: '/images/mocks/ActiveCampaign.jpg',
    years: '2022 - Now',
    reviews: [
      {
        name: 'Mary D.',
        years: '2021-2023',
        photo: '/images/mocks/photos/MaryD.jpg',
        position: 'HR manager',
        rating: 4.6,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'David B.',
        years: '2021-2023',
        photo: '/images/mocks/photos/DavidB.jpg',
        position: 'Security specialist',
        rating: 4.7,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Sten L.',
        years: '2021-2023',
        photo: '/images/mocks/photos/StenL.jpg',
        position: 'Immediate supervisor',
        rating: 4.5,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Mary D.',
        years: '2021-2023',
        photo: '/images/mocks/photos/MaryD.jpg',
        position: 'HR manager',
        rating: 4.6,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'David B.',
        years: '2021-2023',
        photo: '/images/mocks/photos/DavidB.jpg',
        position: 'Security specialist',
        rating: 4.7,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
    ],
  },
  PeerReviews: {
    reviews: [
      {
        name: 'Dave D.',
        years: '2021-2023',
        photo: '/images/mocks/photos/DaveD.jpg',
        rating: 4.6,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Natali H.',
        years: '2021-2023',
        photo: '/images/mocks/photos/NataliH.jpg',
        rating: 4.7,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Andrey B.',
        years: '2021-2023',
        photo: '/images/mocks/photos/AndreyB.jpg',
        rating: 4.5,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Natali H.',
        years: '2021-2023',
        photo: '/images/mocks/photos/NataliH.jpg',
        rating: 4.7,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
      {
        name: 'Dave D.',
        years: '2021-2023',
        photo: '/images/mocks/photos/DaveD.jpg',
        rating: 4.6,
        text: `I had the pleasure of working with John at Stellar Solutions, and I must say, he is an exceptional team player. His dedication to collaboration and his ability to foster a positive and cohesive work environment made our projects run smoothly and efficiently. John's strong communication skills and willingness to go above and beyond to support his colleagues truly set him apart. His attention to detail and problem-solving abilities were instrumental in delivering high-quality results. I highly recommend working with John for any team or project, as his contributions will undoubtedly make a significant impact.`,
      },
    ],
  },
  education: [
    {
      name: 'Oxford University',
      grade: 'Bachelor of Visual Communication Design',
      years: '2015-2019',
      src: '/images/mocks/edu/oxford.jpg',
    },
    {
      name: 'Stanford University',
      grade: 'UX/UI Design',
      years: '2019-2021',
      src: '/images/mocks/edu/stanford.jpg',
    },
    {
      name: 'Advanced Training Certificate',
      grade: 'Bachelor of Visual Communication Design',
      years: '2017-2018',
      src: '/images/mocks/edu/Exam.jpg',
    },
    {
      name: 'Massachusetts Institute of Technology (MIT)',
      grade: 'Master of Science in Computer Science',
      years: '2022-2024',
      src: '/images/mocks/edu/Exam.jpg',
    },
    {
      name: 'California Institute of the Arts (CalArts)',
      grade: 'Bachelor of Fine Arts in Graphic Design',
      years: '2016-2020',
      src: '/images/mocks/edu/Exam.jpg',
    },
  ],
  skills: [
    {
      name: 'User Research',
      confirmed: 12,
    },
    {
      name: 'Wireframing',
      confirmed: 3,
    },
    {
      name: 'Prototyping',
      confirmed: 4,
    },
    {
      name: 'Design Concept',
      confirmed: 5,
    },
    {
      name: 'User Flow',
      confirmed: 2,
    },
    {
      name: 'UI Design',
      confirmed: 8,
    },
    {
      name: 'Visual Design',
      confirmed: 6,
    },
    {
      name: 'Interaction Design',
      confirmed: 4,
    },
    {
      name: 'User Testing',
      confirmed: 7,
    },
    {
      name: 'Front-End Development',
      confirmed: 3,
    },
    {
      name: 'Back-End Development',
      confirmed: 2,
    },
    {
      name: 'Database Management',
      confirmed: 1,
    },
    {
      name: 'Project Management',
      confirmed: 5,
    },
    {
      name: 'Agile Methodologies',
      confirmed: 3,
    },
    {
      name: 'Content Strategy',
      confirmed: 4,
    },
  ],
};

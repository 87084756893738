import React from 'react';

const Medal = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" fill="none">
    <path
      stroke={fill}
      strokeWidth="1.2"
      d="M9.288 6.432c.317-.568.475-.852.712-.852s.395.284.712.852l.082.147c.09.162.135.242.205.296.07.053.157.073.332.112l.16.036c.614.14.921.209.995.444.073.235-.137.48-.556.97l-.108.127c-.12.14-.18.21-.206.296-.027.086-.018.179 0 .364l.017.17c.063.654.095.98-.097 1.126-.191.146-.48.013-1.055-.252l-.149-.069c-.164-.075-.245-.113-.332-.113-.087 0-.169.038-.332.113l-.15.069c-.575.265-.863.397-1.054.252-.192-.145-.16-.472-.097-1.126l.017-.17c.018-.185.027-.278 0-.364-.027-.087-.086-.156-.206-.296l-.108-.126c-.42-.49-.629-.736-.556-.971.074-.235.381-.305.996-.444l.159-.036c.175-.04.262-.06.332-.112.07-.054.115-.134.205-.296l.082-.147Z"
    />
    <path
      stroke={fill}
      strokeWidth="1.2"
      d="M15.833 8.08a5.833 5.833 0 1 1-11.667 0 5.833 5.833 0 0 1 11.667 0Z"
    />
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeWidth="1.2"
      d="m6.126 13.08-.53 1.936c-.524 1.91-.786 2.865-.437 3.388.123.183.287.33.477.427.544.277 1.384-.16 3.063-1.036.56-.291.839-.437 1.136-.468.11-.012.22-.012.33 0 .297.031.577.177 1.136.468 1.679.875 2.519 1.313 3.063 1.036.19-.097.354-.244.477-.427.35-.523.087-1.478-.436-3.388l-.53-1.936"
    />
  </svg>
);

const Book = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M9.706 1.949a.625.625 0 0 1 .588 0l9.375 5a.625.625 0 0 1 0 1.102l-9.375 5a.625.625 0 0 1-.588 0l-9.375-5a.625.625 0 0 1 0-1.102l9.375-5ZM1.953 7.5 10 11.792 18.047 7.5 10 3.208 1.953 7.5Z" />
      <path d="M9.449 7.206a.625.625 0 0 1 .845-.258l4.688 2.5c.203.109.33.321.33.552v8.75a.625.625 0 1 1-1.25 0v-8.375L9.707 8.05a.625.625 0 0 1-.257-.845Z" />
      <path d="M2.813 8.04c.345 0 .625.279.625.624v4.26c.464.622 2.52 3.014 6.562 3.014 4.043 0 6.098-2.392 6.561-3.014l.002-.002V8.664a.625.625 0 1 1 1.25 0v4.268c-.002.268-.09.528-.251.74-.587.788-2.969 3.515-7.562 3.515s-6.975-2.727-7.562-3.514a1.234 1.234 0 0 1-.25-.74V8.663c0-.345.28-.625.625-.625Z" />
    </g>
  </svg>
);

const Briefcase = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M1.875 6.25c0-.69.56-1.25 1.25-1.25h13.75c.69 0 1.25.56 1.25 1.25v10c0 .69-.56 1.25-1.25 1.25H3.125c-.69 0-1.25-.56-1.25-1.25v-10Zm15 0H3.125v10h13.75v-10Z" />
      <path d="M6.8 3.05c.35-.352.828-.55 1.325-.55h3.75a1.875 1.875 0 0 1 1.875 1.875v1.25a.625.625 0 1 1-1.25 0v-1.25a.625.625 0 0 0-.625-.625h-3.75a.625.625 0 0 0-.625.625v1.25a.625.625 0 1 1-1.25 0v-1.25c0-.497.198-.974.55-1.326ZM18.041 9.554a.625.625 0 0 1-.228.854 15.57 15.57 0 0 1-7.812 2.092 15.493 15.493 0 0 1-7.815-2.092.625.625 0 0 1 .628-1.081 14.242 14.242 0 0 0 7.184 1.923h.003a14.32 14.32 0 0 0 7.186-1.924.625.625 0 0 1 .854.228Z" />
      <path d="M8.438 9.375c0-.345.28-.625.624-.625h1.876a.625.625 0 1 1 0 1.25H9.062a.625.625 0 0 1-.624-.625Z" />
    </g>
  </svg>
);

const Chartbar = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M12.85 5.563A7.5 7.5 0 0 0 9.971 5C5.839 5.014 2.5 8.43 2.5 12.586v1.79h15V12.5a7.499 7.499 0 0 0-4.65-6.937ZM9.967 3.75a8.75 8.75 0 0 1 8.783 8.75v1.875a1.25 1.25 0 0 1-1.25 1.25h-15a1.25 1.25 0 0 1-1.25-1.25v-1.789c0-4.828 3.88-8.819 8.716-8.836" />
      <path d="M10 3.75c.345 0 .625.28.625.625v2.5a.625.625 0 1 1-1.25 0v-2.5c0-.345.28-.625.625-.625ZM1.545 10.237a.625.625 0 0 1 .765-.442l2.422.648a.625.625 0 0 1-.323 1.207l-2.422-.648a.625.625 0 0 1-.442-.765ZM18.456 10.237a.625.625 0 0 1-.443.765l-2.422.648a.625.625 0 1 1-.323-1.207l2.422-.648a.625.625 0 0 1 .765.442ZM13.803 7.543c.273.21.325.603.115.877l-5.344 6.96a.625.625 0 1 1-.992-.76l5.344-6.961a.625.625 0 0 1 .877-.116Z" />
    </g>
  </svg>
);

const Gift = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M9.123 1.042h1.753c.75 0 1.373 0 1.87.066.522.07.995.225 1.374.605.38.38.534.852.605 1.375.066.496.066 1.12.066 1.869v1.71h-1.25V5c0-.803-.001-1.343-.055-1.745-.052-.385-.141-.55-.25-.658-.108-.109-.273-.198-.658-.25-.402-.054-.942-.055-1.745-.055H9.166c-.803 0-1.343.001-1.745.055-.385.052-.55.141-.658.25-.108.108-.198.273-.25.658-.054.402-.055.942-.055 1.745v1.667h-1.25v-1.71c0-.749 0-1.373.067-1.869.07-.523.225-.995.604-1.375.38-.38.852-.534 1.375-.605.496-.066 1.12-.066 1.869-.066Z" />
      <path d="M8.504 4.27a3.125 3.125 0 0 1 2.992 0l3.995 2.179a3.125 3.125 0 0 1 1.63 2.744v4.114a3.125 3.125 0 0 1-1.63 2.744l-3.995 2.178a3.125 3.125 0 0 1-2.992 0l-3.995-2.178a3.125 3.125 0 0 1-1.63-2.744V9.193c0-1.144.626-2.196 1.63-2.744l3.995-2.178Zm2.394 1.098a1.875 1.875 0 0 0-1.796 0L5.107 7.546c-.602.329-.977.96-.977 1.647v4.114c0 .687.375 1.318.977 1.646l3.995 2.179c.56.305 1.236.305 1.796 0l3.995-2.179c.602-.328.977-.96.977-1.646V9.193c0-.687-.375-1.318-.977-1.647l-3.995-2.178Z" />
      <path d="M10 9.612c-.048.084-.102.18-.165.295l-.082.147-.019.034c-.065.12-.174.317-.354.455-.185.14-.407.188-.537.216l-.036.008-.159.036c-.146.033-.265.06-.366.085.066.082.152.183.263.313l.109.127.025.03c.09.103.235.27.302.486.067.213.044.433.03.572l-.005.039-.016.17c-.015.156-.027.281-.035.383.087-.038.186-.084.303-.138l.149-.068.261.568-.261-.568.034-.016c.12-.056.327-.154.56-.154.232 0 .44.098.56.154l.033.016.149.068c.117.054.216.1.303.138-.008-.102-.02-.227-.035-.384l-.016-.17a4.458 4.458 0 0 0-.004-.038c-.015-.139-.038-.359.029-.572.067-.216.212-.383.302-.487l.025-.029.108-.127c.112-.13.198-.231.264-.313-.101-.025-.22-.052-.366-.085l-.16-.036-.036-.008c-.13-.028-.351-.077-.536-.216-.18-.138-.289-.336-.354-.455a45.811 45.811 0 0 1-.019-.034l.546-.305-.546.305-.082-.147c-.063-.114-.117-.21-.166-.295Zm1.051 3.731v-.002.002Zm-2.101 0Zm.228-4.76c.144-.187.404-.458.822-.458.418 0 .679.27.822.458.138.18.275.427.417.68l.02.035-.547.304.546-.304.082.147.047.084.082.019.16.036.039.008c.273.062.544.124.753.206.23.09.542.267.662.654.118.38-.031.701-.161.908-.12.191-.304.405-.491.624l-.026.03-.108.127-.069.08.01.113.017.17.004.038c.028.293.056.577.046.804-.01.237-.068.597-.39.842-.333.253-.7.198-.934.13-.215-.06-.469-.178-.724-.295l-.037-.017.249-.54-.249.54-.149-.069a7.749 7.749 0 0 0-.07-.032l-.071.032-.15.069-.036.017c-.256.117-.51.234-.724.296-.233.067-.6.122-.934-.13-.322-.246-.38-.606-.39-.843-.01-.227.017-.51.046-.804l.004-.038.016-.17.01-.112-.068-.081-.109-.127-.025-.03c-.187-.219-.37-.433-.49-.624-.131-.207-.28-.529-.162-.908.12-.387.432-.564.662-.654.209-.082.48-.144.753-.206l.04-.008.158-.036.083-.02.047-.083.082-.147.546.304-.546-.304.02-.035a5.98 5.98 0 0 1 .415-.68Z" />
    </g>
  </svg>
);

const SignOut = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M13.152 6.277a.625.625 0 0 1 .884 0l3.28 3.281a.625.625 0 0 1 0 .884l-3.28 3.281a.625.625 0 1 1-.884-.884L15.992 10l-2.84-2.84a.625.625 0 0 1 0-.883Z" />
      <path d="M7.5 10c0-.345.28-.625.625-.625h8.75a.625.625 0 1 1 0 1.25h-8.75A.625.625 0 0 1 7.5 10Z" />
      <path d="M2.866 2.866A1.25 1.25 0 0 1 3.75 2.5h4.375a.625.625 0 1 1 0 1.25H3.75v12.5h4.375a.625.625 0 1 1 0 1.25H3.75a1.25 1.25 0 0 1-1.25-1.25V3.75c0-.332.132-.65.366-.884Z" />
    </g>
  </svg>
);

const User = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g fill={fill} fillRule="evenodd" clipRule="evenodd">
      <path d="M10 3.125a4.375 4.375 0 1 0 0 8.75 4.375 4.375 0 0 0 0-8.75ZM4.375 7.5a5.625 5.625 0 1 1 11.25 0 5.625 5.625 0 0 1-11.25 0Z" />
      <path d="M10 13.124a8.125 8.125 0 0 0-7.037 4.063.625.625 0 1 1-1.082-.625 9.375 9.375 0 0 1 16.238 0 .625.625 0 1 1-1.082.625A8.124 8.124 0 0 0 10 13.124Z" />
    </g>
  </svg>
);

const Home = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" fill="none">
    <path
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M7.602 15.579v-2.3c0-.585.477-1.06 1.068-1.065h2.165a1.07 1.07 0 0 1 1.075 1.064v2.308c0 .496.4.902.902.914h1.443c1.438 0 2.604-1.155 2.604-2.579v0-6.543a1.83 1.83 0 0 0-.721-1.428l-4.935-3.936a2.385 2.385 0 0 0-2.959 0L3.331 5.957c-.45.34-.716.868-.722 1.429v6.535c0 1.425 1.167 2.579 2.605 2.579h1.443c.514 0 .93-.413.93-.921v0"
    />
  </svg>
);

const Search = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none">
    <g stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <ellipse cx="8.956" cy="8.825" rx="6.367" ry="6.741" />
      <path d="M13.384 13.864 15.88 16.5" />
    </g>
  </svg>
);

const Document = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M12.918 13.55h-6.07M12.918 10.03h-6.07M9.164 6.52H6.85" />
      <path
        d="m13.079 2.225-6.463.003c-2.32.014-3.757 1.54-3.757 3.87v7.729c0 2.34 1.448 3.873 3.788 3.873l6.463-.003c2.32-.014 3.757-1.542 3.757-3.87v-7.73c0-2.34-1.448-3.872-3.788-3.872Z"
        clipRule="evenodd"
      />
    </g>
  </svg>
);

const Wallet = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
    <g stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M17.709 11.996h-3.374a2.244 2.244 0 0 1 0-4.485h3.374M14.717 9.702h-.26" />
      <path
        d="M6.133 2.5h7.203a4.373 4.373 0 0 1 4.373 4.373v5.98a4.373 4.373 0 0 1-4.373 4.374H6.133a4.373 4.373 0 0 1-4.373-4.373v-5.98A4.373 4.373 0 0 1 6.133 2.5Z"
        clipRule="evenodd"
      />
      <path d="M5.54 6.282h4.498" />
    </g>
  </svg>
);

const DangerCircle = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="20" fill="none">
    <g stroke={fill} strokeLinecap="round" strokeLinejoin="round">
      <path
        strokeWidth="1.5"
        d="M9.235 17.708c4.044 0 7.323-3.282 7.323-7.33 0-4.049-3.28-7.331-7.323-7.331-4.045 0-7.323 3.282-7.323 7.33 0 4.05 3.278 7.331 7.323 7.331Z"
        clipRule="evenodd"
      />
      <path strokeWidth="1.5" d="M9.232 13.386V9.884" />
      <path strokeWidth="2" d="M9.232 7.37h.008" />
    </g>
  </svg>
);

const User3 = ({ fill }: { fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="23" height="18" fill="none">
    <g stroke={fill} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
      <path d="M17.138 8.001a2.876 2.876 0 0 0 2.472-2.84 2.875 2.875 0 0 0-2.406-2.837M18.979 11.355c1.35.202 2.294.675 2.294 1.65 0 .671-.445 1.107-1.163 1.381" />
      <path
        d="M11.137 11.769c-3.214 0-5.96.487-5.96 2.432 0 1.944 2.729 2.445 5.96 2.445 3.214 0 5.958-.482 5.958-2.428s-2.727-2.45-5.958-2.45ZM11.136 8.992a3.819 3.819 0 1 0-3.819-3.819 3.804 3.804 0 0 0 3.791 3.82h.028Z"
        clipRule="evenodd"
      />
      <path d="M5.135 8.001a2.875 2.875 0 0 1-2.472-2.84A2.875 2.875 0 0 1 5.07 2.323M3.294 11.355C1.943 11.557 1 12.03 1 13.005c0 .671.444 1.107 1.162 1.381" />
    </g>
  </svg>
);

export const getIcon = ({ name, fill }: { name: string; fill: string }) => {
  if (name === 'user') return <User fill={fill} />;
  if (name === 'logout') return <SignOut fill={fill} />;
  if (name === 'gift') return <Gift fill={fill} />;
  if (name === 'briefcase') return <Briefcase fill={fill} />;
  if (name === 'book') return <Book fill={fill} />;
  if (name === 'chartbar') return <Chartbar fill={fill} />;
  if (name === 'User3') return <User3 fill={fill} />;
  if (name === 'DangerCircle') return <DangerCircle fill={fill} />;
  if (name === 'Wallet') return <Wallet fill={fill} />;
  if (name === 'Document') return <Document fill={fill} />;
  if (name === 'Search') return <Search fill={fill} />;
  if (name === 'Home') return <Home fill={fill} />;
  if (name === 'Medal') return <Medal fill={fill} />;

  return null;
};

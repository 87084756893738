import React, { useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageBlock from '../../../components/PageBlock';
import clsx from 'clsx';
import css from './index.module.css';
import { helpData } from './data';
import { FAQ } from './dataU';
import ArrowDown from '../../../components/Icons/ArrowDown';
import globalController from '../../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';

const MenuItem = ({ name, selected, onClick }) => (
  <div
    className={clsx(
      css.MenuItem,
      'bg-[#EDF0F4] text-[#CFD2D5] font-medium text-[15px] transition-all',
      selected && 'bg-white !text-[#000]',
    )}
    onClick={onClick}
  >
    {name}
  </div>
);

const Help = observer(() => {
  const account = globalController.getAccount();
  const isCompany = account === 'company';
  const [selected, setSelected] = useState(0);
  const data = Object.entries(isCompany ? helpData : { FAQ, Contacts: helpData.Contacts });
  const [open, setOpen] = useState(
    data.map(([_, el]) => (Array.isArray(el) ? el.map((__, j) => j === 0) : [])),
  );

  return (
    <div className="flex flex-col">
      <PageTitle title="Help" showUser />
      <div className="flex gap-4">
        <div className="flex flex-col gap-1 w-[16%]">
          {data.map(([el, _], i) => (
            <MenuItem name={el} key={i} selected={i === selected} onClick={() => setSelected(i)} />
          ))}
        </div>
        <PageBlock className="!justify-start !items-start gap-8 w-full">
          {Array.isArray(data[selected][1])
            ? data[selected][1]?.map((item, j) => (
                <div className="w-full" key={item.q}>
                  <div
                    className="flex w-full justify-between items-center mb-4 cursor-pointer"
                    role="button"
                    onClick={() =>
                      setOpen((prev) => {
                        const newOpen = [...prev];
                        newOpen[selected][j] = !newOpen[selected][j];
                        return newOpen;
                      })
                    }
                    tabIndex={0}
                  >
                    <div className="font-semibold text-[22px]">{item.q}</div>
                    <div
                      className="transition-all"
                      style={{ transform: `rotate(${open[selected][j] ? '180deg' : 0})` }}
                    >
                      <ArrowDown />
                    </div>
                  </div>
                  <div
                    className="whitespace-pre-wrap text-base overflow-hidden"
                    style={{
                      transition: 'height 0.25s',
                      height: open[selected][j] ? '100%' : 0,
                    }}
                  >
                    {item.a}
                  </div>
                </div>
              ))
            : data[selected][1]}
        </PageBlock>
      </div>
    </div>
  );
});

export default Help;

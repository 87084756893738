import React from 'react';
import PageBlock from '../../../components/PageBlock';
import { blocks, blocksCompany } from './data';
import clsx from 'clsx';

export const Advantage = ({ image, title, description, isComplete }) => (
  <PageBlock
    shadow
    className={clsx(
      '!justify-start !items-start !pl-8 min-h-[8rem] ',
      isComplete && '!flex-1 !pl-2 !pr-2',
    )}
  >
    {isComplete ? (
      <div>
        <div className="flex items-center justify-center">
          <div
            className="bg-no-repeat bg-contain min-w-[24px] min-h-[24px] mr-2"
            style={{ backgroundImage: `url(${image})` }}
          />
          <div className="text-[18px] mb-1">{title}</div>
        </div>
        <div className="text-sm text-center">{description}</div>
      </div>
    ) : (
      <div className="flex gap-5">
        <div
          className="bg-no-repeat bg-contain min-w-[70px] min-h-[70px]"
          style={{ backgroundImage: `url(${image})`, alignSelf: 'center' }}
        />
        <div>
          <div className="text-[18px] mb-1">{title}</div>
          <div className="text-sm w-[76%]">{description}</div>
        </div>
      </div>
    )}
  </PageBlock>
);

export const Advantages = ({ isCompany, isUser, isComplete }) =>
  isComplete && !isCompany ? (
    <div className="flex flex-col w-full font-medium">
      <div className="flex justify-between w-full gap-6 mb-6">
        <Advantage
          isComplete
          image="/images/target.webp"
          title="Be Honest, Be Accurate"
          description="Your self-assessment is a golden ticket to self-impovement"
        />
        <Advantage
          isComplete
          image="/images/search-big.webp"
          title="Why It Matters"
          description="This isn’t just a formality. It’s your chance to honestly gauge achievements and pinpoint areas for growth."
        />
        <Advantage
          isComplete
          image="/images/hands.webp"
          title="We’re Here for You"
          description="Got questions? Check out our FAQ or contact our Support Team for custom help."
        />
      </div>

      <div className="grid grid-cols-5 gap-4">
        <PageBlock shadow noPadding>
          <div className="text-[18px] w-1/2 text-center">
            Don’t miss out on these incredible advantages
          </div>
        </PageBlock>
        {blocks.map((block) => (
          <PageBlock shadow className="!items-start !justify-start" key={block.desc}>
            <div className="text-[32px] text-[#0446F1]">{block.title}</div>
            <div>{block.desc}</div>
          </PageBlock>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex gap-[4%] w-full font-medium">
      <div className="flex flex-col gap-6 w-full">
        <PageBlock shadow>
          <div className="text-[18px] w-1/2 text-center">
            Don’t miss out on these incredible advantages
          </div>
        </PageBlock>
        <div className="grid grid-cols-2 gap-4">
          {(isCompany ? blocksCompany : blocks).map((block) => (
            <PageBlock shadow className="!items-start !justify-start" key={block.desc}>
              <div className="text-[32px] text-[#0446F1]">{block.title}</div>
              <div>{block.desc}</div>
            </PageBlock>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-between w-full">
        <Advantage
          image="/images/target.webp"
          title="Be Honest, Be Accurate"
          description={
            isCompany
              ? 'Your verification is a golden ticket to improvement your work area'
              : 'Your self-assessment is a golden ticket to self-impovement'
          }
        />
        <Advantage
          image="/images/search-big.webp"
          title="Why It Matters"
          description={
            isCompany
              ? 'It’s chance to honestly gauge achievements and pinpoint areas for growth of your employees.'
              : 'This isn’t just a formality. It’s your chance to honestly gauge achievements and pinpoint areas for growth.'
          }
        />
        <Advantage
          image="/images/hands.webp"
          title="We’re Here for You"
          description="Got questions? Check out our FAQ or contact our Support Team for custom help."
        />
      </div>
    </div>
  );

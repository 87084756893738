import React from 'react';

export const SelectArrow = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 8" fill="none" {...props}>
    <path
      fill="#8A95B1"
      d="M12.435 1.206a.894.894 0 0 0-1.264 0L6.743 5.634 2.316 1.206A.894.894 0 0 0 1.05 2.471l5.06 5.06a.894.894 0 0 0 1.265 0l5.06-5.06a.894.894 0 0 0 0-1.265Z"
    />
  </svg>
);

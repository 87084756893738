import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Filler,
  Title,
  Tooltip,
  Legend,
);

interface IProps {
  lineColor: string;
  pointBorderColor?: string;
  labels: string[];
  tableData: string[];
  data?: any;
  selected: number;
  lineWidth?: number;
  xGrid?: boolean;
  yGrid?: boolean;
  xBorder?: boolean;
  yBorder?: boolean;
  pointRadius?: number;
  tension?: number;
  barLabels?: string[];
  barDatasets?: any[];
  barOptions?: any;
}

const Chart = React.memo(
  ({
    lineColor,
    labels,
    tableData,
    data,
    lineWidth = 1,
    xGrid = false,
    yGrid = false,
    xBorder = false,
    yBorder = false,
    pointRadius = 1,
    tension = 0.5,
    barLabels,
    barDatasets,
    barOptions,
  }: IProps) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: false },
      },
      categoryPercentage: 1,
      scales: {
        y: {
          border: { display: yBorder },
          grid: { display: yGrid },
          max: 100,
          min: 0,
          ticks: { stepSize: 25 },
        },
        x: {
          border: { display: xBorder },
          grid: { display: xGrid },
          ticks: {},
        },
      },
    };

    const linedata = {
      labels,
      datasets: [
        {
          data: tableData,
          backgroundColor: lineColor,
          borderColor: lineColor,
        },
      ],
    };

    const newData = data ? { ...data } : { ...linedata };
    if (newData) {
      newData.datasets = newData.datasets.map((el: any) => ({
        ...el,
        borderWidth: el.borderWidth || lineWidth,
        tension: el.tension || tension, // Настраиваем плавность линии
        pointRadius: el.pointRadius || pointRadius, // Задаем радиус точек
      }));
    }

    return (
      <div className="relative w-full h-full">
        <Bar
          data={{ labels: barLabels, datasets: barDatasets || [] }}
          options={barOptions || options}
          style={{
            display: !barDatasets ? 'none' : 'flex',
            position: !barDatasets ? 'inherit' : 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          }}
        />
        <Line
          data={newData}
          options={options}
          style={
            barDatasets
              ? {
                  position: 'absolute',
                  display: 'flex',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }
              : {}
          }
        />
      </div>
    );
  },
);

export default Chart;

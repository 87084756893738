import clsx from 'clsx';
import React from 'react';
import css from './index.module.css';

interface IProps {
  children: any;
  noBG?: boolean;
  noPadding?: boolean;
  small?: boolean;
  smallBlue?: boolean;
  shadow?: boolean;
  className?: string;
  bg?: string;
}

export default function PageBlock({
  className,
  children,
  noBG,
  small,
  noPadding,
  smallBlue,
  shadow,
  bg,
  ...otherProps
}: IProps) {
  return (
    <div
      className={clsx(
        'flex flex-col flex-wrap justify-center items-center rounded-3xl',
        !noBG && 'bg-white',
        small ? `${noPadding ? '' : 'p-3'} items-center` : `${noPadding ? '' : 'p-6'}`,
        smallBlue && css.smallBlue,
        shadow && css.shadow,
        className,
      )}
      {...otherProps}
      style={bg ? { background: bg } : {}}
    >
      {children}
    </div>
  );
}

interface IProps {
  children: any;
  className?: string;
}

export const PageBlockTitle = ({ children, className }: IProps) => (
  <div className={clsx('text-[22px] font-semibold', className)}>{children}</div>
);

import React from 'react';
import { Gear } from '../Icons/Gear';
import { useNavigate } from 'react-router-dom';
import { InputDiv, SelectDiv } from '../Select/Select';

interface IProps {
  title: string;
  showUser?: boolean;
  search?: boolean;
  selectedOption?: string;
  searchValue?: string;
  searchOptions?: string[];
  searchOnChange?: (value: string) => void;
  searchOptionChange?: (searchOptionChange: string) => void;
  settings?: boolean;
  onClick?: () => void;
}

export default function PageTitle({
  title,
  showUser,
  search,
  searchValue,
  searchOnChange,
  searchOptions = [],
  searchOptionChange,
  selectedOption = '',
  settings,
  onClick,
}: IProps) {
  const navigate = useNavigate();
  const iconClass = 'w-6 h-6 mr-6 cursor-pointer';

  return (
    <div className="flex justify-between items-center mb-8">
      <h1 className="text-[28px] font-bold" onClick={onClick}>
        {title}
      </h1>
      <div className="flex gap-6 items-center">
        {search && (
          <div className="relative">
            <InputDiv
              placeholder="Search"
              label={undefined}
              color={undefined}
              containerClassName="h-[49px]"
              className={'!bg-white !min-w-[430px] !h-full !pl-[190px]'}
              onChange={(e: any) => searchOnChange && searchOnChange(e.target.value)}
              value={searchValue}
              disabled={false}
              searchIcon="end"
              children={undefined}
            />

            <SelectDiv
              className="!h-[41px]"
              containerClassName="absolute !h-[41px] left-1 top-1/2 transform -translate-y-1/2 z-50"
              color={undefined}
              disabled={false}
              label=""
              options={searchOptions}
              onChange={(val: any) => {
                searchOptionChange && searchOptionChange(val);
              }}
              value={selectedOption}
              customOpenBtn={undefined}
            />
          </div>
        )}
        {showUser && (
          <div className="flex items-center gap-4">
            <Gear color={settings ? '#0446F1' : '#8A95B1'} onClick={() => navigate('/settings')} />
            <img src="/images/BellSimpleRinging.svg" alt="Notifications" className={iconClass} />
            <img
              src="/images/mocks/photo.jpg"
              alt="User avatar"
              className="w-12 h-12 cursor-pointer"
            />
          </div>
        )}
      </div>
    </div>
  );
}

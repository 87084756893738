import { COLORS } from './consts';
const gpc = require('generate-pincode');

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const getLastDay = () => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const lastDay = new Date(year, month, 0).getDate();
  return lastDay;
};

export const get6Month = () => {
  const months = [];
  const currentDate = new Date();
  for (let i = 0; i < 6; i++) {
    months.unshift(currentDate.toLocaleString('en', { month: 'short' }).toUpperCase());
    currentDate.setMonth(currentDate.getMonth() - 1);
  }
  return months;
};

export const getColor = (percent) => {
  let color = COLORS.green;

  if (percent < 25) {
    color = COLORS.black;
  } else if (percent < 50) {
    color = COLORS.red;
  } else if (percent < 75) {
    color = COLORS.yellow;
  }

  return color;
};

export const getCountries = async () => {
  try {
    const response = await fetch('https://restcountries.com/v3.1/all');
    const json = await response.json();
    // const us = json.find((country) => country.name.common === 'United States');
    return json.map((country) => country.name.common);
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const generatePincode = (length) => {
  return gpc(length);
}

export const Bank = ({ color = '#29B4EF', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <g
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M2.417 9h19.5l-9.75-6-9.75 6ZM5.417 9v7.5M9.917 9v7.5M14.417 9v7.5M18.917 9v7.5M3.167 16.5h18M1.667 19.5h21" />
    </g>
  </svg>
);

export const Employerscore = ({ color = '#FD8026', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <g stroke={selected ? '#fff' : color} strokeWidth="1.5" clipPath="url(#a666)">
      <circle cx="12.5" cy="6" r="4" />
      <path
        strokeLinecap="round"
        d="M18.5 9c1.657 0 3-1.12 3-2.5S20.157 4 18.5 4M6.5 9c-1.657 0-3-1.12-3-2.5S4.843 4 6.5 4"
        opacity=".5"
      />
      <ellipse cx="12.5" cy="17" rx="6" ry="4" />
      <path
        strokeLinecap="round"
        d="M20.5 19c1.754-.385 3-1.359 3-2.5s-1.246-2.115-3-2.5M4.5 19c-1.754-.385-3-1.359-3-2.5s1.246-2.115 3-2.5"
        opacity=".5"
      />
    </g>
    <defs>
      <clipPath id="a666">
        <path fill="#fff" d="M.5 0h24v24H.5z" />
      </clipPath>
    </defs>
  </svg>
);

export const FirstAid = ({ color = '#17DA7C', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <path
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M9.834 15h-5.25a.75.75 0 0 1-.75-.75v-4.5a.75.75 0 0 1 .75-.75h5.25V3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75V9h5.25a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-.75.75h-5.25v5.25a.75.75 0 0 1-.75.75h-4.5a.75.75 0 0 1-.75-.75V15Z"
    />
  </svg>
);

export const PoliceCar = ({ color = '#2A5CDD', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <g
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="M2.333 11.25h21M21.834 17.25v2.25a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1-.75-.75v-2.25M7.583 17.25v2.25a.75.75 0 0 1-.75.75h-2.25a.75.75 0 0 1-.75-.75v-2.25M6.833 14.25h1.5M17.334 14.25h1.5" />
      <path d="m21.834 11.25-2.785-4.875A.74.74 0 0 0 18.402 6H7.265a.74.74 0 0 0-.647.375L3.833 11.25v6h18v-6ZM8.334 3.75h3.75M13.584 3.75h3.75" />
    </g>
  </svg>
);

export const Syringe = ({ color = '#8E25E1', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <g
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    >
      <path d="m17.75 2.25 4.5 4.5M12.5 4.5 20 12M13.625 5.625l-8.41 8.41a.74.74 0 0 0-.215.524V19.5h4.94a.741.741 0 0 0 .526-.216l8.409-8.409M10.063 9.188l2.437 2.437M7.438 11.813l2.437 2.437M16.25 8.25 20 4.5M5 19.5l-2.25 2.25" />
    </g>
  </svg>
);

export const User = ({ color = '#FD4A23', selected }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
    <path
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m20.167 21.5-.65-5.52c-.2-1.7-1.64-2.98-3.35-2.98h-8c-3 0-4.933-2.731-5.618-5.472L2.167 6"
    />
    <path
      stroke={selected ? '#fff' : color}
      strokeLinecap="round"
      strokeWidth="1.5"
      d="M8.167 13v5c0 1.886 0 2.828.586 3.414.586.586 1.528.586 3.414.586 1.886 0 2.828 0 3.414-.586.586-.586.586-1.528.586-3.414v-5"
      opacity=".5"
    />
    <circle
      cx="12.167"
      cy="6"
      r="4"
      stroke={selected ? '#fff' : color}
      strokeWidth="1.5"
      opacity=".9"
    />
  </svg>
);

export const qas = [
  {
    name: 'Attendance',
    desc: `This criterion assesses an employee's punctuality, regularity, and overall presence at work. A consistent record of attendance is often considered a sign of reliability and commitment to one's role and responsibilities.`,
    est: [
      {
        icon: '🌀',
        name: 'The Time Traveller',
        desc: `You are constant and unjustifiable tardiness, exceeding acceptable norms (more than 10 times a month). You are absent without a valid reason or prior warning.
        These actions negatively impact team workflow and projects.`,
      },
      {
        icon: '🐢',
        name: 'The Chronically Late',
        desc: 'You frequently late (more than 5 times a month), sometimes with a reason but still causing issues. You have rare warnings or notifications about being late or absent.',
      },
      {
        icon: '🕰️',
        name: 'The 50/50 Timekeeper',
        desc: `You occasionally late (no more than 5 times a month) but usually warn and have valid reasons. These actions don't significantly affect team work or task completion. You are aware of the importance of punctuality but has room for improvement.`,
      },
      {
        icon: '🌟',
        name: 'The Nearly Perfect Watch',
        desc: `You rarely late (no more than 3 times a month) and always notify in advance. You are responsible about work hours and respects colleagues' time. You arrive and leave within the standard work time frames.`,
      },
      {
        icon: '⏱️',
        name: 'The Time Lord',
        desc: `You never late and always on time. You serve as a role model for other employees in observing work hours. You shows initiative in being on time even under circumstances where others may be late (e.g., bad weather).`,
      },
    ],
  },
  {
    name: 'Compliance with the contract and company rules',
    desc: `This evaluates how well an employee adheres to the terms outlined in their employment contract as well as any rules and policies set forth by the company. Compliance is crucial for maintaining a fair, safe, and effective work environment.`,
    est: [
      {
        icon: '🚫',
        name: 'The Rebel Without a Clause',
        desc: `You consistently violate company policies and contractual obligations. You have undergone multiple disciplinary actions due to non-compliance. You think that company's rules and guidelines are inapropriate.`,
      },
      {
        icon: '📏',
        name: 'The Rule-Bender',
        desc: 'You occasionally violate company policies or contractual obligations. You had received warnings or minor disciplinary actions. You have limited understanding of company rules.',
      },
      {
        icon: '🌳',
        name: 'The Fence-Sitter',
        desc: `You generally comply with company policies and contractual obligations, but minor infringements occur. You don't have serious disciplinary actions, yet room for improvement exists. You are aware of rules and mostly abides by them.`,
      },
      {
        icon: '🛡️',
        name: 'The Loyalist',
        desc: `You steadily adhere to all company policies and contractual obligations. You don't have any disciplinary actions or warnings. You are well-informed of all rules and actively abides by them.`,
      },
      {
        icon: '🌟',
        name: 'The Compliance Crusader',
        desc: `You exemplarily adhere to all company policies and contractual obligations. You serve as a role model for other employees in following rules and procedures. You take initiative in maintaining and improving compliance standards.`,
      },
    ],
  },
  {
    name: 'Work ethic',
    desc: `This criterion examines an employee's commitment, dedication, and professional behavior in accomplishing tasks and responsibilities. It includes aspects like integrity, responsibility, and diligence.`,
    est: [
      {
        icon: '🏴‍☠️',
        name: 'The Office Outlaw',
        desc: `You openly disrespect colleagues and undermine team cohesion. You act unprofessionally in the office and at corporate events. You volate ethical standards, possibly including discrimination and harassment.`,
      },
      {
        icon: '🏜️',
        name: 'The Ethical Nomad',
        desc: 'You occasionally crosse the line in interactions with colleagues but generally avoid serious incidents. You have onconsistent behavior concerning respect and courtesy. You show limited understanding of ethical conduct in the workplace.',
      },
      {
        icon: '🌓',
        name: 'The Neutral Force',
        desc: `You are generally respectful but avoid taking a stand against unethical behaviors in the workplace. You display neutral-to-positive manners during office hours and corporate events. You understand the importance of ethics but may overlook some details.`,
      },
      {
        icon: '🎗️',
        name: 'The Ethical Advocate',
        desc: `You actively respect all colleagues and set a good example in terms of office behavior. You participatespositively in office culture and at corporate events. You demonstrate a strong grasp of ethical behavior and counters minor infractions.`,
      },
      {
        icon: '🦸‍♂️',
        name: 'The Ethical Hero',
        desc: `You take the initiative to address and prevent unethical conduct. You model ideal behavior in the workplace and during all work-related events. You highly respected by colleagues for consistently upholding the highest ethical standards.`,
      },
    ],
  },
  {
    name: 'Meet NDA',
    desc: `This assesses compliance with any Non-Disclosure Agreements that the employee may have signed, either at the onset of employment or during their tenure at the company. Violations of an NDA can have legal repercussions and may result in immediate termination.`,
    est: [
      {
        icon: '🚱',
        name: 'The Leaky Faucet',
        desc: `You have open and systematic violation of the Non-Disclosure Agreement. You spread confidential information without permission and ignore or violate corporate policies on maintaining confidentiality.`,
      },
      {
        icon: '🤔',
        name: 'The Unaware Offender',
        desc: `YOu occasionally violate the NDA, possibly without understanding the consequences. You leak confidential information due to insufficient security measures. You have imited understanding of the importance of confidentiality.`,
      },
      {
        icon: '😐',
        name: 'The Inattentive Guardian',
        desc: `You generally adhere to the NDA but have room for improvement. You had minimal violations that don't affect the company as a whole. You understand the value of confidentiality but may overlook details.`,
      },
      {
        icon: '🛡️',
        name: 'The Dependable Custodian',
        desc: `You carefully follow the terms of the Non-Disclosure Agreement. You handle confidential information responsibly. You exhibit high attention to detail and adhere to all corporate policies.`,
      },
      {
        icon: '🏆',
        name: 'The Confidentiality Champion',
        desc: `You strictly adhere to all terms of the Non-Disclosure Agreement and set an example for others. You take initiative in improving confidentiality measures. You fully understand and respect the importance of confidential information and its impact on the company's success.`,
      },
    ],
  },
  {
    name: 'Theft of wealth',
    desc: `Evaluation of any unauthorized taking or appropriation of company assets, money, or property. Theft of wealth can severely undermine trust within the organization and may lead to immediate disciplinary action, up to and including termination and legal consequences.`,
    est: [
      {
        icon: '🏴‍☠️',
        name: 'The Pirate King',
        desc: `A notorious character stealing company's treasure, causing massive losses over 10 000 usd. You need to get back on track and complete the "Code of Ethics" training.`,
      },
      {
        icon: '🦝',
        name: 'The Treasure Sneak',
        desc: 'You take from the company treasure and cause losses between 5 000 usd and 10 000 usd. You are on the watchlist. ',
      },
      {
        icon: '🙄',
        name: 'The Careless Deckhand',
        desc: `You have a casual attitude toward safeguarding the company’s treasure, resulting in losses between 1 000 usd and 5 000 usd. There's room for improvement. `,
      },
      {
        icon: '🌟',
        name: 'The Trusted Quartermaster',
        desc: `You have minor losses (up to 1 000 usd) but generally follow all company rules. Considered a reliable guardian. Continued adherence to corporate laws is encouraged.`,
      },
      {
        icon: '🛡',
        name: 'The Legendary Guardian',
        desc: `You have no losses are associated with your career. You display high respect and responsibility toward the company’s assets. You are mentoring others is encouraged to help them reach your level.`,
      },
    ],
  },
  {
    name: 'Theft of intellectual property',
    desc: `Evaluation of the unauthorized use, sharing, or theft of proprietary information, trade secrets, or other intellectual assets belonging to the organization. Like theft of wealth, this is a serious offense that can result in disciplinary action, termination, and potential legal repercussions.`,
    est: [
      {
        icon: '🚫',
        name: 'Urgent Attention Required',
        desc: `You misuses a substantial amount of the company's intellectual property  (damage over 10 000 usd) with significant financial implications. You require close oversight and intervention. You do not align with legal and company policy standards.`,
      },
      {
        icon: '😡',
        name: 'Limited Adherence to Policies',
        desc: `Unintentionally compromises the company's intellectual property with noticeable financial impact (damage between 5 000 usd and 10 000 usd). You need to better understand and follow corporate and legal standards.`,
      },
      {
        icon: '😕',
        name: 'Improvement Needed',
        desc: `You occasionally overlook the importance of safeguarding the company's intellectual property with some financial consequences (damage between 1 000 usd and 5 000 usd). You generally abide by guidelines but could improve attention to detail.`,
      },
      {
        icon: '📘',
        name: 'The Responsible Citizen',
        desc: `You are cautious and respectful in handling the company's intellectual property with minimal financial impact (less than 1 000  usd). You adhere to corporate and legal regulations concerning the company's property.`,
      },
      {
        icon: '🌟',
        name: 'The Paragon of Virtue',
        desc: `You exhibit a high degree of responsibility and respect for the company's intellectual property. You set an example for colleagues in following corporate and legal standards. You proactively safeguard against potential compromises.`,
      },
    ],
  },
  {
    name: 'Understanding the tasks',
    desc: `Ability to fully comprehend the scope, objectives, and requirements of assigned tasks. The employee should not only understand what is expected but also why it's important and how it fits into the bigger picture.`,
    est: [
      {
        icon: '🚫',
        name: 'The Misunderstander',
        desc: `Systematic errors due to misunderstanding or inattention to task details. You complete lack of understanding of the project's goals and requirements. You are unable to perform tasks without constant oversight and correction from management.`,
      },
      {
        icon: '😕',
        name: 'The Confused Performer',
        desc: 'Frequent errors and shortcomings related to incomplete understanding of the task. You require additional time and resources to correct mistakes. You have limited understanding of the project’s goals and task requirements.',
      },
      {
        icon: '🛠',
        name: 'The Adequate Taskmaster',
        desc: `You generally perform tasks correctly but occasionally make errors due to lapses in understanding. You sometimes require clarification of details and requirements. You have a basic understanding of the project's goals and requirements.`,
      },
      {
        icon: '📘',
        name: 'The Competent Executor',
        desc: `You accurately and effectively perform most tasks. You rarely require additional clarifications and seldom makes mistakes. You have a good understanding of the project’s goals and requirements, and can adapt to changes.`,
      },
      {
        icon: '🌟',
        name: 'The Taskmaster Pro',
        desc: `You always clearly and precisely understand all tasks and requirements. You are capable of analyzing and optimizing the workflow for increased efficiency. You have excellent understanding of not only individual tasks but also the overall goals of the project, serve as a role model for the team.`,
      },
    ],
  },
  {
    name: 'Learnability',
    desc: `The capacity to quickly acquire new skills or adapt to new systems, technologies, or procedures. Learnability is crucial in rapidly changing work environments.`,
    est: [
      {
        icon: '🚫',
        name: 'The Non-learner',
        desc: `You don't love to adapt to new tasks or technologies. You refuse training and professional development. You consistently make the same mistakes and fail to learn from them.`,
      },
      {
        icon: '🐢',
        name: 'The Slow Adapter',
        desc: 'You adapt slowly to new tasks and working conditions. You require excessive time and resources for learning. You tend to repeat mistakes and show little initiative in self-education.',
      },
      {
        icon: '🛣',
        name: 'The Middle-of-the-Road',
        desc: `You are capable of adapting to new tasks but require extra time and guidance. You accept new information but are slow to apply it in practice. You occasionally show initiative in professional development.`,
      },
      {
        icon: '🎓',
        name: 'The Quick Learner',
        desc: `You adapt quickly to new conditions and tasks. You actively learn and apply new skills at work. You regularly seek opportunities for professional development and self-education.`,
      },
      {
        icon: '🌠',
        name: 'The Master Learner',
        desc: `You very quickly master new tasks, technologies, and working methods. You not only actively learn but are also capable of teaching others. You are continuously engaged in professional development and serve as a role model for colleagues.`,
      },
    ],
  },
  {
    name: 'Responsibility',
    desc: `Taking ownership of tasks and projects, including accountability for outcomes—both successful and otherwise. A responsible employee is reliable and follows through on commitments.`,
    est: [
      {
        icon: '🙈',
        name: 'The Unreliable',
        desc: `You systematically fail to complete assigned tasks or does so poorly. You prefer to Ignore deadlines and corporate standards. You have lack of autonomy and require constant oversight from management.`,
      },
      {
        icon: '⏰',
        name: 'The Bare-Minimum Worker',
        desc: `You often fall behind on task completion and do not always adhere to quality standards. You don't have sufficient interest in duties.`,
      },
      {
        icon: '📋',
        name: 'The Average Joe',
        desc: `You generally meet deadlines and standards but sometimes make mistakes. You perform duties at an acceptable level but without displaying initiative. You are capable of independent work but require clarification of details and action plans.`,
      },
      {
        icon: '🛡',
        name: 'The Dependable Worker',
        desc: `You almost always adhere to deadlines and high-quality standards. You take an active interest in fulfilling responsibilities and project development. You are capable of making independent decisions and do not require constant oversight.`,
      },
      {
        icon: '🌟',
        name: 'The Exemplary Performer',
        desc: `You always meet deadlines and exceed quality expectations. You display initiative, suggest ways to optimize the workflow. You are fully accountable for actions and decisions, serve as a role model for colleagues.`,
      },
    ],
  },
  {
    name: 'Professional skills',
    desc: `The technical skills and knowledge required to perform one's job effectively. This includes any certifications, ongoing training, or expertise that are necessary for the role.`,
    est: [
      {
        icon: '🤷‍♂️',
        name: 'The Novice',
        desc: `You have lack of the essential skills needed to perform basic tasks. You are unable to adequately respond to work challenges and problems. You require constant oversight and additional training.`,
      },
      {
        icon: '🛠',
        name: 'The Apprentice',
        desc: 'You possess only basic skills needed for simple tasks. You frequently make mistakes and need help in solving complex issues. You have limited toolkit and methodologies for performing work.',
      },
      {
        icon: '👷‍♀️',
        name: 'The Competent Worker',
        desc: `You are capable of solving most tasks but may sometimes require consultation. You have a sufficient skill set for performing most tasks. You occasionally update your skills and knowledge.`,
      },
      {
        icon: '🖋',
        name: 'The Skilled Professional',
        desc: `You boast a wide range of skills and can solve complex issues. You rarely make mistakes and proactively improve skills. You are well-versed in contemporary methods and tools of their field.`,
      },
      {
        icon: '🏆',
        name: 'The Master Craftsman',
        desc: `You possess all necessary skills at the highest level. You are capable of teaching others and serves as an example of professionalism. You love ontinuously improve skills and are focused on innovation.`,
      },
    ],
  },
  {
    name: 'Communication skills',
    desc: `The ability to convey ideas clearly, listen effectively, and work well with colleagues, supervisors, and sometimes clients. This also includes written communication like emails and reports.`,
    est: [
      {
        icon: '📵',
        name: 'The Broken Phone',
        desc: `You are unable to communicate effectively, both orally and in writing. You consistently make errors in interpreting information and expressing thoughts. You prefer to Ignore feedback and are unable to work in a team setting.`,
      },
      {
        icon: '📻',
        name: 'The Static-filled Radio',
        desc: `You struggle with understanding and transmitting information. You are limitedly capable of participating in team discussions and dialogues. You don't always consider other people's viewpoints and rarely listen to feedback.`,
      },
      {
        icon: '💬',
        name: 'The Basic Chat',
        desc: `You are generally able to communicate and relay information effectively. You participate in teamwork but sometimes misunderstandings occur. You acceptably consider feedback and make an effort to improve communication skills.`,
      },
      {
        icon: '📞',
        name: 'The Clear Channel',
        desc: `You express thoughts well both orally and in writing, and work effectively in a team. You actively engage in discussions, offers ideas, and considers the opinions of others. You pay careful attention to feedback and can adapt communication style.`,
      },
      {
        icon: '🎤',
        name: 'The Communication Maestro',
        desc: `You excellently understand and interpret information, and articulate thoughts in an exceptionally clear and precise manner. You effectively participate in team dynamics and can improve the communication skills of others. You show exceptional empathy and active listening skills, setting a high standard in communication.`,
      },
    ],
  },
  {
    name: 'Time management',
    desc: `Efficient utilization of available time to prioritize and complete tasks. Good time management is often indicative of strong organizational skills and a high level of productivity.`,
    est: [
      {
        icon: '🕰️',
        name: 'The Broken Clock',
        desc: `You consistently fail to meet deadlines and lack the skills to manage time effectively. You prefer to Ignore priorities and frequently engage in inconsequential tasks. You are unable to work effectively without constant oversight from management.`,
      },
      {
        icon: '⏳',
        name: 'The Leaking Hourglass',
        desc: 'You often misse established deadlines and struggles to make effective use of workday hours. You have difficulty with setting priorities and requires frequent reminders. You need extra time to complete routine tasks.',
      },
      {
        icon: '⏰',
        name: 'The Steady Clock',
        desc: `You generally meet deadlines and strives to make efficient use of time. You set priorities but occasionally deviate from the plan. You can self-manage time but may occasionally need some adjustments.`,
      },
      {
        icon: '⚙️',
        name: 'The Well-oiled Machine',
        desc: `You almost always meet deadlines and efficiently manages work time. You clearly identify and adhere to priorities, rarely straying from the plan. You are capable of managing time effectively and aiding colleagues in doing so as well.`,
      },
      {
        icon: '🧙‍♂️',
        name: 'The Time Wizard',
        desc: `You consistently meet deadlines, optimizing work processes for both oneself and colleagues. You perfectly set priorities and adhere to a strategic plan. You possesse exceptional time management skills and serve as an example for others.`,
      },
    ],
  },
  {
    name: 'Innovation and creativity',
    desc: `The ability to come up with new ideas, methods, or solutions to problems, contributing to the organization's growth and effectiveness.`,
    est: [
      {
        icon: '📌',
        name: 'The Broken Pencil',
        desc: `Incapable of generating new ideas or solutions, even when it's necessary. You prefers sticking to old methods and approaches, ignoring possibilities for improvement. You have Lack of interest in acquiring new knowledge or skills and don't participate in creative processes.`,
      },
      {
        icon: '💡',
        name: 'The Flickering Light Bulb',
        desc: `You occasionally suggest new ideas but usually only after being nudged or pressured. You struggle with adapting to new circumstances or changes. You don't have interest in learning and improving.`,
      },
      {
        icon: '🎨',
        name: 'The Tentative Painter',
        desc: `You sometimes offer new ideas or approaches, but may not always implement them effectively. You are fairly flexible and can adapt to new circumstances. You are open to learning and self-improvement, but don't actively seek out new opportunities.`,
      },
      {
        icon: '🌳',
        name: 'The Flourishing Tree',
        desc: `You consistently come up with fresh ideas and successfully execute them. You display adaptability and openness, swiftly adapting to new conditions or technologies. You actively engage in learning and self-improvement, motivating peers in the process.`,
      },
      {
        icon: '🚀',
        name: 'The Soaring Rocket',
        desc: `You constantly identify and implement innovative solutions, setting an example for others. You are exceptionally flexible and adaptive, easily embracing and implementing new approaches and technologies. You continually update your knowledge and skills, actively motivating and inspiring the team for innovation.`,
      },
    ],
  },
  {
    name: 'Leadership',
    desc: `The ability to guide, motivate, and influence peers or subordinates, even if the employee is not in a formal leadership role.`,
    est: [
      {
        icon: '🚢',
        name: 'The Lost Ship',
        desc: `You can't motivate or guide others, even in basic tasks. Lack of the will to take responsibility and be proactive. You don't want to follow organizational principles and values.`,
      },
      {
        icon: '🌱',
        name: 'The Fledgling Scout',
        desc: 'You have limited ability to manage small projects or tasks. You rarely take initiative and struggles with fully taking responsibility. You find it challenging to build trust within the team.',
      },
      {
        icon: '🧭',
        name: 'The Amateur Navigator',
        desc: `You can manage projects and tasks but occasionally has issues working with the team. You show initiative but might not always fully realize it. You consider team opinions and generally adheres to organizational principles.`,
      },
      {
        icon: '🎖️',
        name: 'The Skilled Captain',
        desc: `You effectively manage projects and works well within a team. You frequently take the initiative and are willing to assume responsibility. You create a positive atmosphere, motivates, and guides the team towards objectives.`,
      },
      {
        icon: '🌟',
        name: 'The Master Commander',
        desc: `You are exceptionally effective at managing complex projects and teams. You constantly take and successfully realize initiatives, serving as an example for others. You excellently motivate the team, build a culture of trust and openness, and strongly represent organizational values.`,
      },
    ],
  },
  {
    name: 'Teamwork',
    desc: `The ability to work well in a team, contribute to group objectives, and successfully collaborate with colleagues on projects.`,
    est: [
      {
        icon: '🐺',
        name: 'Lone Wolf',
        desc: `You operate independently, often missing out on the advantages of team collaboration.`,
      },
      {
        icon: '👀',
        name: 'Team Observer',
        desc: 'You engage occasionally in team activities; more an observer than an active participant.',
      },
      {
        icon: '⚽',
        name: 'Team Contributor',
        desc: `You participate regularly in team activities but may sometimes struggle with active collaboration.`,
      },
      {
        icon: '🌟',
        name: 'Team Strategist',
        desc: `You consistently collaborate and communicate effectively within the team, often helping to shape group decisions.`,
      },
      {
        icon: '🏆',
        name: 'Team Leader',
        desc: `You take charge in team settings, ensuring cohesion, facilitating communication, and driving results.`,
      },
    ],
  },
  {
    name: 'Customer-centric',
    desc: `Understanding and meeting the needs of internal and/or external customers, providing quality service that meets or exceeds expectations.`,
    est: [
      {
        icon: '🎮',
        name: 'The Novice',
        desc: `Not yet dialed into the customer's needs and mostly dismissive of their concerns.`,
      },
      {
        icon: '🌱',
        name: 'The Apprentice',
        desc: 'Occasionally attentive but has much to learn. Sporadic efforts to improve service.',
      },
      {
        icon: '🛵',
        name: 'The Journeyman',
        desc: `Generally aware of customer needs but still missing the mark on fully satisfying them.`,
      },
      {
        icon: '🛡️',
        name: 'The Knight',
        desc: `Champion of the customer, ever vigilant in offering excellent service. Still, the quest for perfection continues.`,
      },
      {
        icon: '🌟',
        name: 'The Master',
        desc: `Exceptional in anticipating and exceeding customer needs; the ultimate customer service guru.`,
      },
    ],
  },
  {
    name: 'Hungry (passion)',
    desc: `Willingness to take on tasks or projects without being told, showing a proactive approach to work.`,
    est: [
      {
        icon: '😴',
        name: 'The Sleepwalker',
        desc: `You show little to no interest in tasks or career growth. Lack of enthusiasm and is generally disengaged.`,
      },
      {
        icon: '👟',
        name: 'The Dabbler',
        desc: 'Minimal interest in assigned tasks and rarely steps up for extra projects. Little ambition for self-improvement.',
      },
      {
        icon: '🚗',
        name: 'The Cruiser',
        desc: `Generally engaged but not especially enthusiastic. Occasionally take part in extra initiatives and have some ambition for growth, but it's not a defining trait.`,
      },
      {
        icon: '🧗',
        name: 'The Climber',
        desc: `Frequently show enthusiasm and interest in tasks. Actively engage in extra projects and seeks ways to grow and improve. Open to new challenges for professional development.`,
      },
      {
        icon: '🚀',
        name: 'The Trailblazer',
        desc: `Fully committed and exceptionally enthusiastic. Constantly looking for opportunities for growth and takes on extra responsibility. Exemplifie motivation and strive for excellence in all endeavors.`,
      },
    ],
  },
  {
    name: 'Diverse (being understanding, patient)',
    desc: `Alignment with company culture, values, and ethics, contributing positively to the work environment.`,
    est: [
      {
        icon: '🏝️',
        name: 'The Lone Island',
        desc: `You show no tolerance or understanding towards diversity. You are intolerant of others' opinions and cultural norms. You easily lose patience and is quick to get frustrated.`,
      },
      {
        icon: '👓',
        name: 'The Casual Observer',
        desc: 'You make minimal efforts to understand and tolerate differences, not a priority for them. You may ay sometimes act intolerantly or fail to understand other people. You tend to be impatient in complex or stressful situations.',
      },
      {
        icon: '⚖️',
        name: 'The Balancing Act',
        desc: `You generally appreciate and respect diversity but could improve. You might occasionally lose patience or fail to grasp others' perspectives. You are able to maintain patience in some but not all complex situations.`,
      },
      {
        icon: '🌉',
        name: 'The Bridge Builder',
        desc: `You frequently demonstrate a deep understanding and respect for individual differences and needs. You maintain calm and patience even in challenging or stressful situations. You adapt well to different cultural and social settings.`,
      },
      {
        icon: '🌍',
        name: 'The Global Citizen',
        desc: `You always show exceptional tolerance and understanding towards individual needs and diversities. You never lose patience, even in the most challenging and stressful situations. You have an outstanding ability to adapt and understand people from various cultural and social backgrounds.`,
      },
    ],
  },
  {
    name: `Company recommendation as a workplace`,
    desc: `This criterion assesses your willingness to endorse your company as a desirable place to work`,
    est: [
      {
        icon: '🚢',
        name: 'The Ship is Sinking!',
        desc: `You would never recommend the company to anyone. You see pervasive issues like poor management, lack of growth opportunities, or a toxic work culture.`,
      },
      {
        icon: '🔍',
        name: 'Lost in the Woods',
        desc: `You might not actively deter people, but certainly wouldn't recommend the company. You think that several issues such as inconsistent policies, lack of team spirit, or low work-life balance can be improved.`,
      },
      {
        icon: '💭',
        name: 'On the Fence',
        desc: `You might recommend the company but with reservations. You see both positives and negatives; think the company is "okay" but has room for improvement, especially in terms of employee benefits, career growth, or corporate culture.`,
      },
      {
        icon: '🌴',
        name: 'Almost Paradise',
        desc: `You would recommend the company but would also provide advice on how to navigate its minor issues. You are generally happy with work conditions, team spirit, management, and work-life balance.`,
      },
      {
        icon: '🌈',
        name: 'Living the Dream!',
        desc: `You would strongly recommend the company to anyone and everyone. You think the company excels in all aspects: culture, growth opportunities, work-life balance, and has an overall great working environment.`,
      },
    ],
  },
  {
    name: 'Job task and process satisfaction',
    desc: `This measures your satisfaction with your day-to-day tasks and the processes involved.`,
    est: [
      {
        icon: '🙁',
        name: 'Groundhog Day',
        desc: `You completely dissatisfied with the repetitiveness and lack of challenge in your role. You feel stifled by inefficient or outdated processes that hinder productivity.`,
      },
      {
        icon: '🌧',
        name: 'Walking Through Mud',
        desc: `You mostly dissatisfied, feeling like you're making slow progress in a non-supportive environment. You notice several bottlenecks or outdated procedures that make tasks unnecessarily complicated.`,
      },
      {
        icon: '🚗',
        name: 'Cruising Speed',
        desc: `You are neutral about the work process; see both its merits and flaws. Your responsibilities are clear, but the job might lack variety or present a few obstacles in the work process that need addressing.`,
      },
      {
        icon: '🚤',
        name: `Smooth Sailing`,
        desc: `You mostly satisfied; your work process is streamlined, and responsibilities are challenging yet achievable. You find your tasks engaging and feel like you're contributing meaningfully to the company.`,
      },
      {
        icon: '🚀',
        name: 'Rocketing to Success',
        desc: `You extremely satisfied with a highly efficient work process and a role that perfectly matches your skills and ambitions. You feel fully engaged, motivated, and empowered to perform your best.`,
      },
    ],
  },
  {
    name: 'Satisfaction with your work-life balance',
    desc: `This evaluates how well you think the company accommodates for a balanced work-life experience.`,
    est: [
      {
        icon: '🏃‍♀️',
        name: 'Treadmill Trap',
        desc: `You extremely dissatisfied with work-life balance; feel like you're always "on" and never have time for personal life.`,
      },
      {
        icon: '🌊',
        name: 'Sinking Ship',
        desc: 'You are generally unhappy with the balance; feel overwhelmed with work most of the time, barely keeping your head above water in personal matters.',
      },
      {
        icon: '⚖️',
        name: 'Steady Seesaw',
        desc: `You are neutral; you manage to balance work and personal time, but there's room for improvement.`,
      },
      {
        icon: '🤹‍♀️',
        name: 'Balancing Act',
        desc: `You mostly satisfied; you can maintain a healthy work-life balance most of the time and enjoy some leisure activities and family time.`,
      },
      {
        icon: '🧘‍♂️',
        name: 'Zen Master',
        desc: `You extremely satisfied; you've mastered the art of balancing work and personal life, allowing ample time for rest, hobbies, and family.`,
      },
    ],
  },
  {
    name: 'Team culture and atmosphere rating',
    desc: `This assesses the quality of the interpersonal relationships and the general atmosphere within your team.`,
    est: [
      {
        icon: '☠️',
        name: 'Toxic Wasteland',
        desc: `You extremely dissatisfied; the culture feels toxic, hostile, or uninviting. Team dynamics are severely lacking.`,
      },
      {
        icon: '🏝️',
        name: 'Lonely Island',
        desc: `You are generally unhappy; you feel disconnected from the team and the company culture doesn't support collaboration or growth.`,
      },
      {
        icon: '🤷‍♀️',
        name: 'Neutral Zone',
        desc: `Middle of the road; the atmosphere is neither harmful nor supportive, and there's room for improvement in the corporate culture.`,
      },
      {
        icon: '👋',
        name: 'Friendly Neighborhood',
        desc: `You mostly satisfied; the culture promotes openness, cooperation, and you generally feel supported by your teammates.`,
      },
      {
        icon: '🦄',
        name: 'Utopia Inc.',
        desc: `You extremely satisfied; the corporate culture is excellent, encouraging, inclusive, and you feel proud to be part of the team.`,
      },
    ],
  },
  {
    name: 'Professional growth and development opportunities',
    desc: `This gauges the opportunities for career advancement and skill development within the company.`,
    est: [
      {
        icon: '💩',
        name: 'Stuck in the Mud',
        desc: `No opportunities for growth; you feel stagnant and see no clear path for career advancement or skill development.`,
      },
      {
        icon: '🪜',
        name: 'Climbing a Broken Ladder',
        desc: `Few opportunities; there's some talk about growth, but the action is lacking. Limited or ineffective development programs.`,
      },
      {
        icon: '🌄',
        name: 'Rolling Hill',
        desc: `Moderate opportunities; you've experienced some professional development, but it could be more structured or frequent.`,
      },
      {
        icon: '✈️',
        name: `Sky's the Limit`,
        desc: `Good opportunities; company offers regular training, mentorship, and career development paths that you find beneficial.`,
      },
      {
        icon: '🚀',
        name: 'Rocket Ship',
        desc: `Excellent opportunities; the company excels in providing a multitude of avenues for growth, both professionally and personally.`,
      },
    ],
  },
];

import React, { useState } from 'react';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import Language from '../../../components/Language';
import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import PageTitle from '../../../components/PageTitle';

import { userData } from './mockData';
import clsx from 'clsx';

const Education = ({ data, horizontal }) =>
  data ? (
    <div key={data.name} className="w-full flex px-6 items-center">
      <div
        className="bg-contain bg-no-repeat w-[40px] h-[40px] rounded flex self-center mr-3"
        style={{ backgroundImage: `url(${data.src})` }}
      />
      <div className="flex flex-col w-full py-4 font-medium">
        <div className="flex flex-wrap text-base font-medium items-center">
          {data.name}
          {horizontal && <div className="text-[#939393] text-[13px] ml-1">{data.years}</div>}
        </div>
        <div className="font-medium text-[#939393] !leading-tight">
          <div>{data.grade}</div>
          {!horizontal && <div>{data.years}</div>}
        </div>
      </div>
      <Button variant="smallBlue" className="bg-[#F6F7FC] h-fit ml-2">
        View
      </Button>
    </div>
  ) : null;

export default function EducationPage() {
  const [showAbout, setShowAbout] = useState(false);

  return (
    <div className="flex flex-col">
      <PageTitle title="Education & Courses" showUser />
      <div className="flex gap-4">
        <div className="flex flex-col flex-[1.1] gap-4">
          <PageBlock>
            <div className="flex justify-between w-full">
              <PageBlockTitle>About</PageBlockTitle>
              <Button variant="smallBlue" onClick={() => setShowAbout((prev) => !prev)}>
                {showAbout ? 'Hide' : 'View'}
              </Button>
            </div>
            <div
              className={clsx(
                'font-medium text-[15px] mt-4 whitespace-pre-wrap',
                !showAbout && 'line-clamp-[40]',
              )}
            >
              {userData.about}
            </div>
          </PageBlock>
        </div>

        <div className="flex flex-col flex-[1.1] gap-4">
          <PageBlock>
            <PageBlockTitle>Languages</PageBlockTitle>
            <div className="flex flex-wrap gap-4 mt-4">
              {userData.languages?.map((el) => (
                <Language {...el} key={el.language} />
              ))}
            </div>
          </PageBlock>

          <PageBlock noPadding>
            <PageBlockTitle className="p-6">Education</PageBlockTitle>
            <div className="flex gap-4 w-full px-6 pb-4">
              <div className="flex flex-col justify-center items-center font-medium rounded-2xl bg-[#EFF4FF] p-3">
                <div className="text-[32px] text-[#0446F1] leading-none">88</div>
                <div className="text-xs text-center leading-none">
                  Average
                  <br />
                  score
                </div>
              </div>
              <div className="flex flex-col gap-1 justify-center">
                <div className="text-sm text-[#979797] leading-none">Speciality</div>
                <div className="text-base leading-none">
                  Product Middle Designer, UX/UI & Web/mobile designer
                </div>
              </div>
            </div>
            <Divider />
            {userData.education.map((el, i, arr) => (
              <React.Fragment key={i}>
                <Education data={el} />
                {i !== arr.length - 1 && <Divider className="w-full" />}
              </React.Fragment>
            ))}
          </PageBlock>
        </div>

        <div className="flex flex-col flex-1 gap-4">
          <PageBlock noPadding>
            <PageBlockTitle className="p-6">Skills</PageBlockTitle>
            <Divider />
            {userData.skills.map((el, i, arr) => (
              <div key={el.name} className="w-full">
                <div className="flex flex-col py-4 font-medium">
                  <div className="text-base font-medium px-6">{el.name}</div>
                  <div className="font-medium text-[13px] text-[#939393] flex gap-1 px-6">
                    <img src="/images/tick-green.svg" alt="" />
                    Confirmed by {el.confirmed} recommendations
                  </div>
                </div>

                {i !== arr.length - 1 && <Divider className="w-full" />}
              </div>
            ))}
          </PageBlock>
        </div>
      </div>
    </div>
  );
}

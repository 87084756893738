import React from 'react';
import iso6391 from 'iso-639-1';
import { hasFlag } from 'country-flag-icons';

interface IProps {
  language: string;
  level: string;
}

export default function Language({ language, level }: IProps) {
  let code = iso6391.getCode(language).toUpperCase();
  if (code === 'EN' || !hasFlag(code)) code = 'GB';

  return (
    <div className="flex">
      <div className="flex items-center">
        <img
          alt=""
          src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${code}.svg`}
          className="w-[30px] h-[20px] mr-2 rounded"
        />
      </div>
      <div className="flex flex-col">
        <div className="font-medium text-[15px] leading-5">{language}</div>
        <div className="font-medium text-[13px] leading-4 text-[#ADADAD]">{level}</div>
      </div>
    </div>
  );
}

import React from 'react';

interface IProps {
  rating: number;
  big?: boolean;
  bigStar?: boolean;
  color?: string;
}

export default function Star({ rating, big, bigStar, color = '#000' }: IProps) {
  return (
    <div className="flex items-center mx-[1px]">
      {rating ? (
        <>
          {bigStar ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M7.27582 1.5427C7.56377 0.929296 8.43623 0.929296 8.72418 1.5427L10.2908 4.88004C10.4041 5.12131 10.63 5.29032 10.8935 5.33081L14.4622 5.87931C15.1056 5.9782 15.3678 6.76315 14.913 7.22891L12.2822 9.9234C12.1047 10.1051 12.0241 10.3604 12.065 10.6111L12.6787 14.3737C12.7863 15.0329 12.0865 15.5258 11.502 15.2026L8.38714 13.4801C8.14624 13.3469 7.85376 13.3469 7.61286 13.4801L4.49796 15.2026C3.91351 15.5258 3.21374 15.0329 3.32125 14.3737L3.93498 10.6111C3.97587 10.3604 3.89527 10.1051 3.71781 9.9234L1.08696 7.22891C0.632195 6.76315 0.89443 5.9782 1.53783 5.87931L5.10654 5.33081C5.36997 5.29032 5.59594 5.12131 5.70919 4.88004L7.27582 1.5427Z"
                fill="#FCDA27"
                stroke="white"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.84582 2.93415C8.07291 2.47409 8.72894 2.47409 8.95603 2.93415L10.1699 5.39329C10.26 5.57582 10.4341 5.70239 10.6355 5.73183L13.351 6.12874C13.8585 6.20293 14.0608 6.82683 13.6934 7.18474L11.7294 9.09762C11.5834 9.23984 11.5167 9.44483 11.5512 9.64573L12.0146 12.3474C12.1013 12.8531 11.5704 13.2388 11.1163 12.9999L8.68906 11.7235C8.50868 11.6286 8.29317 11.6286 8.11279 11.7235L5.68556 12.9999C5.2314 13.2388 4.70054 12.8531 4.78728 12.3474L5.25065 9.64573C5.2851 9.44483 5.21845 9.23984 5.07244 9.09762L3.10849 7.18474C2.74103 6.82683 2.94332 6.20293 3.45089 6.12874L6.16639 5.73183C6.3678 5.70239 6.54187 5.57582 6.63196 5.39329L7.84582 2.93415Z"
                fill="#FCDA27"
                stroke="white"
                strokeWidth="0.619048"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <div
            className={`text-${big ? '[32px]' : bigStar ? 'sm' : 'xs'} font-medium text-[${color}]`}
          >
            {rating}
          </div>
        </>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M6.94494 3.2674C7.17203 2.80735 7.82806 2.80735 8.05515 3.2674L9.26901 5.72654C9.35911 5.90907 9.53317 6.03564 9.73458 6.06508L12.4501 6.46199C12.9577 6.53618 13.1599 7.16008 12.7925 7.51799L10.8285 9.43087C10.6825 9.5731 10.6159 9.77808 10.6503 9.97898L11.1137 12.6806C11.2004 13.1864 10.6696 13.572 10.2154 13.3332L7.78818 12.0567C7.6078 11.9619 7.39229 11.9619 7.21191 12.0567L4.78468 13.3332C4.33053 13.572 3.79966 13.1864 3.8864 12.6806L4.34977 9.97898C4.38422 9.77808 4.31758 9.5731 4.17156 9.43087L2.20761 7.51799C1.84015 7.16008 2.04244 6.53618 2.55001 6.46199L5.26551 6.06508C5.46692 6.03564 5.64099 5.90907 5.73108 5.72654L6.94494 3.2674Z"
            fill="#D9D9D9"
            stroke="white"
            strokeWidth="0.619048"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </div>
  );
}

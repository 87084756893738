export const Line = ({ percent = 0 }) => (
  <div
    className="w-full h-[29px] relative"
    style={{
      borderRadius: '48px',
      background: 'lightgrey',
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset',
    }}
  >
    {percent ? (
      <>
        <div
          className="absolute top-0 left-0 h-full transition-all"
          style={{
            width: `${percent}%`,
            borderRadius: '48px',
            background: 'linear-gradient(90deg, #FA5A60 19.9%, #FFCB14 58.95%, #74DC34 100%)',
          }}
        />
        {false && (
          <div
            className={`absolute top-1/2 text-white`}
            style={{ left: `${percent / 2}%`, transform: 'translate(-50%,-50%)' }}
          >
            {percent.toFixed()}%
          </div>
        )}
      </>
    ) : null}
  </div>
);

import clsx from 'clsx';
import React from 'react';

import classes from './Selector.module.css';

interface Props {
  className?: string;
  classNameChildren?: string;
  classNameSelected?: string;
  children?: React.ReactNode;
  selected: number;
  noSelect?: boolean;
  padding?: number;
  onClick?: any;
}

export default function Selector(props: Props) {
  const {
    children,
    className,
    classNameChildren,
    classNameSelected,
    selected = 0,
    noSelect,
    padding = 0,
    onClick,
    ...otherProps
  } = props;
  const childrenCount = React.Children.count(children);
  let width = 100 / childrenCount;
  if (childrenCount === 0) {
    width = 50;
  }

  return (
    <div className={clsx(classes.container, className)} onClick={onClick} {...otherProps}>
      <div className={clsx(classes.children, classNameChildren)}>{children}</div>

      {!noSelect && (
        <div
          className={clsx(classes.selector, classNameSelected)}
          style={{
            // left: selected ? `calc(${width * selected}%)` : '0px',
            // width: `calc(${width}%)`,
            left: selected ? `calc(${width * selected}% - ${padding / 2}px)` : `${padding}px`,
            width: `calc(${width}% - ${padding / 2}px)`,
          }}
        />
      )}
    </div>
  );
}

import React, { useCallback, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import Button from '../../../components/Button';
import { InputDiv, SelectDiv } from '../../../components/Select/Select';
import { mockLogs } from './mockData';
import clsx from 'clsx';
import { Basket, Pencil } from '../../../components/Icons/SettingsIcons';
import { iconsData } from '../../User/MyScores/icons';
import globalController from '../../../mobx/GlobalController';

export const Cell = ({ children, className }) => (
  <div className={clsx('text-[15px] py-2 border-b border-gray', className)}>{children}</div>
);

const Selector = ({ checked, onClick, disabled }) => (
  <div
    className="bg-[#F0F0F0] rounded-xl w-8 h-4 relative select-none"
    onClick={() => {
      if (!disabled) onClick();
    }}
  >
    <div
      className={clsx(
        'rounded-full bg-[#0446F1] absolute top-0 w-4 h-4 transition-all',
        checked ? 'left-4' : 'left-0',
      )}
    />
  </div>
);

export default function Settings() {
  const companyData = globalController.getData();
  const noData =
    companyData?.companyId &&
    !companyData?.lastReports?.length &&
    !companyData?.lastChangesRecent?.length;

  const [data, setData] = useState(mockLogs);
  const [selectors, setSelectors] = useState(iconsData.map((el) => el.active));
  const [filters, setFilters] = useState({
    login: '',
    role: '',
    position: '',
    name: '',
  });

  const handleFilters = useCallback(() => {
    const newData = [...mockLogs]
      .filter((el) =>
        filters.login ? el.login.toLowerCase().includes(filters.login.toLowerCase()) : true,
      )
      .filter((el) =>
        filters.name ? el.name.toLowerCase().includes(filters.name.toLowerCase()) : true,
      )
      .filter((log) => (filters.position ? log.position === filters.position : true))
      .filter((log) =>
        filters.role
          ? log.role.toLowerCase() === filters.role.toLowerCase() ||
            (log.role === 'HR' && filters.role === 'Manager')
          : true,
      );
    setData(newData);
  }, [filters]);

  return (
    <div className="flex flex-col">
      <PageTitle title="Settings" showUser settings />
      <PageBlock className="!items-start !justify-start">
        <div className="flex w-full justify-between mb-[50px]">
          <PageBlockTitle>Role managment</PageBlockTitle>
          <div className="w-fit">
            <Button
              variant="bigBlue"
              className="!px-4 !py-[10px] !font-semibold !rounded-[25px]"
              onClick={() => {
                globalController.setModal({ name: 'addEmployee', value: true });
              }}
            >
              Add employee
            </Button>
          </div>
        </div>
        <div className="flex gap-6 items-end mb-8">
          <InputDiv
            label="Login"
            value={filters.login}
            onChange={(e) => setFilters((prev) => ({ ...prev, login: e.target.value }))}
          />
          <SelectDiv
            label="Role"
            options={['Admin', 'Manager', 'Viewer']}
            onChange={(e) => setFilters((prev) => ({ ...prev, role: e }))}
            value={filters.role}
          />
          <SelectDiv
            label="Position"
            options={['HR', 'Security', 'Immediate manager']}
            onChange={(e) => setFilters((prev) => ({ ...prev, position: e }))}
            value={filters.position}
          />
          <InputDiv
            label="Name"
            value={filters.name}
            onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
          />
          <Button variant="mediumBlue" className="!py-1 !px-2 !h-[30px]" onClick={handleFilters}>
            Search
          </Button>
        </div>

        {!noData && (
          <div
            className="grid w-full font-medium"
            style={{ gridTemplateColumns: '17% 17% 17% 17% 32%' }}
          >
            <div className="text-[#8E8E8E] mb-2">Login</div>
            <div className="text-[#8E8E8E]">Role</div>
            <div className="text-[#8E8E8E]">Position</div>
            <div className="text-[#8E8E8E]">Name</div>
            <div className="text-[#8E8E8E]"></div>

            {data.map((log, i) => (
              <React.Fragment key={JSON.stringify(data) + i}>
                <Cell>{log.login}</Cell>
                <Cell>{log.role}</Cell>
                <Cell>{log.position}</Cell>
                <Cell>{log.name}</Cell>
                <Cell className="flex items-center gap-[5px]">
                  <Pencil className="cursor-pointer" />
                  <Basket className="cursor-pointer" />
                </Cell>
              </React.Fragment>
            ))}
          </div>
        )}
      </PageBlock>
      <PageBlock className="!items-start !justify-start mt-4">
        <PageBlockTitle>Functions</PageBlockTitle>
        <div className="flex gap-[10px] w-3/4 mt-6">
          {iconsData.map((item, i) => (
            <div
              className={
                item.active && !noData ? 'cursor-pointer' : 'opacity-50 cursor-not-allowed'
              }
              key={item.name}
            >
              <PageBlock small className={'flex-1 border !py-3 !px-4 mb-2'}>
                <img src={`/images/score/${item.icon}.svg`} alt="" />
                <div className="text-[13px] py-1 flex flex-wrap text-center font-medium">
                  {item.name}
                </div>
              </PageBlock>
              <Selector
                disabled={!item.active || noData}
                checked={selectors[i]}
                onClick={() =>
                  setSelectors((prev) => prev.map((el, idx) => (idx === i ? !el : el)))
                }
              />
            </div>
          ))}
        </div>
      </PageBlock>
    </div>
  );
}

import React from 'react';

const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.27582 1.5427C7.56377 0.929296 8.43623 0.929296 8.72418 1.5427L10.2908 4.88004C10.4041 5.12131 10.63 5.29032 10.8935 5.33081L14.4622 5.87931C15.1056 5.9782 15.3678 6.76315 14.913 7.22891L12.2822 9.9234C12.1047 10.1051 12.0241 10.3604 12.065 10.6111L12.6787 14.3737C12.7863 15.0329 12.0865 15.5258 11.502 15.2026L8.38714 13.4801C8.14624 13.3469 7.85376 13.3469 7.61286 13.4801L4.49796 15.2026C3.91351 15.5258 3.21374 15.0329 3.32125 14.3737L3.93498 10.6111C3.97587 10.3604 3.89527 10.1051 3.71781 9.9234L1.08696 7.22891C0.632195 6.76315 0.89443 5.9782 1.53783 5.87931L5.10654 5.33081C5.36997 5.29032 5.59594 5.12131 5.70919 4.88004L7.27582 1.5427Z"
      fill="#FCDA27"
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const StarPart = ({ value }: { value: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M7.27582 1.5427C7.56377 0.929296 8.43623 0.929296 8.72418 1.5427L10.2908 4.88004C10.4041 5.12131 10.63 5.29032 10.8935 5.33081L14.4622 5.87931C15.1056 5.9782 15.3678 6.76315 14.913 7.22891L12.2822 9.9234C12.1047 10.1051 12.0241 10.3604 12.065 10.6111L12.6787 14.3737C12.7863 15.0329 12.0865 15.5258 11.502 15.2026L8.38714 13.4801C8.14624 13.3469 7.85376 13.3469 7.61286 13.4801L4.49796 15.2026C3.91351 15.5258 3.21374 15.0329 3.32125 14.3737L3.93498 10.6111C3.97587 10.3604 3.89527 10.1051 3.71781 9.9234L1.08696 7.22891C0.632195 6.76315 0.89443 5.9782 1.53783 5.87931L5.10654 5.33081C5.36997 5.29032 5.59594 5.12131 5.70919 4.88004L7.27582 1.5427Z"
      fill={`url(#paint0_linear_1_6154${value.replace('.', '')})`}
      stroke="white"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id={'paint0_linear_1_6154' + value.replace('.', '')}
        x1="1.5"
        y1="7"
        x2="15"
        y2="7"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={+value} stopColor="#FCDA27" />
        <stop offset={+value} stopColor="#F5F5F5" />
      </linearGradient>
    </defs>
  </svg>
);

export const Star5 = ({ rating }: { rating: number }) => {
  const count = Math.floor(rating);
  const arr = Array(count).fill(1);
  const diff = rating - count;

  return (
    <div className="flex items-center">
      {arr.map((_, i) => (
        <Star key={i} />
      ))}
      {diff > 0 && <StarPart value={diff.toFixed(1)} />}
      <div className="ml-1">{rating}</div>
    </div>
  );
};

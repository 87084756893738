export const mockLogs = [
  {
    login: 'a.i.ivanova',
    role: 'Admin',
    position: 'HR',
    name: 'Anna',
  },
  {
    login: 'm.spencer',
    role: 'Manager',
    position: 'Security',
    name: 'Mark',
  },
  {
    login: 'j.j.abramovich',
    role: 'Viewer',
    position: 'HR',
    name: 'Johny',
  },
  {
    login: 'b.kilmer',
    role: 'Admin',
    position: 'Immediate manager',
    name: 'Bill',
  },
];

export const mockData = {
  performanceReview: {
    data: [30, 40, 30],
  },
  EmployeeSatisfaction: [
    { name: 'Satisfaction', data: [15, 10, 10, 25, 40] },
    { name: 'Work live balance', data: [15, 10, 10, 25, 40] },
    { name: 'Professional', data: [10, 20, 15, 25, 30] },
    { name: 'Company', data: [25, 5, 5, 25, 40] },
    { name: 'Recommendation', data: [15, 10, 10, 35, 30] },
  ],
  legends: ['Poor', 'Fair', 'Average', 'Good', 'Excellent'],
  reports: [
    { name: 'David B.', position: 'Security specialist', src: '/images/mocks/photos/DavidB.jpg' },
    { name: 'Ilon A.', position: 'Security specialist', src: '/images/mocks/photos/IlonAsk.jpg' },
    { name: 'Iren L.', position: 'Security specialist', src: '/images/mocks/photos/IrenLi.jpg' },
    { name: 'Mary D.', position: 'Security specialist', src: '/images/mocks/photos/MaryD.jpg' },
    { name: 'Natali H.', position: 'Security specialist', src: '/images/mocks/photos/NataliH.jpg' },
    { name: 'Sten L.', position: 'Security specialist', src: '/images/mocks/photos/StenL.jpg' },
  ],
  ratingChanges: [
    {
      name: 'David B.',
      position: 'Security specialist',
      src: '/images/mocks/photos/DavidB.jpg',
      points: 43,
      change: 20,
    },
    {
      name: 'Mary D.',
      position: 'Security specialist',
      src: '/images/mocks/photos/MaryD.jpg',
      points: 67,
      change: -23,
    },
    {
      name: 'Natali H.',
      position: 'Security specialist',
      src: '/images/mocks/photos/NataliH.jpg',
      points: 55,
      change: -12,
    },
    {
      name: 'Iren L.',
      position: 'Security specialist',
      src: '/images/mocks/photos/IrenLi.jpg',
      points: 34,
      change: 2,
    },
  ],
};

export const mockLogs = [
  {
    date: '12.12.2023',
    action: { name: 'Adding a new employee', type: 'Added employee' },
    login: 'a.i.ivanova',
    role: 'admin',
  },
  {
    date: '13.12.2023',
    action: { name: 'Delete employee', type: 'Deleted employee' },
    login: 'petrov.i',
    role: 'HR',
  },
  {
    date: '14.12.2023',
    action: { name: 'Adding a new employee', type: 'Added employee' },
    login: 'a.i.ivanova',
    role: 'admin',
  },
  {
    date: '15.12.2023',
    action: { name: 'Made review', type: 'Made review' },
    login: 'smith.j',
    role: 'viewer',
  },
  {
    date: '16.12.2023',
    action: { name: 'Delete employee', type: 'Deleted employee' },
    login: 'a.i.ivanova',
    role: 'admin',
  },
  {
    date: '17.12.2023',
    action: { name: 'Made review', type: 'Made review' },
    login: 'petrov.i',
    role: 'HR',
  },
];

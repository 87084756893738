import React, {useEffect, useState} from 'react';
import { sha256 } from 'js-sha256';
import {generatePincode} from "../../utils/helpers";
import {shuftiPro} from "../../utils/consts";

interface IProps {
    language?: string,
    phone?: string,
    email?: string,
    nextStep?: (message: any) => void,
    onError?: (message: any) => void
}

const validateSignature = (data: string, signature: any, SK: any) => {
    data = JSON.stringify(data);
    data = data.replace(/\//g,"\\/")
    data = `${data}${sha256(SK)}`;

    sha256(data);
    var hash = sha256.create();
    hash.update(data);

    if(hash.hex() === signature){
        return true;
    }else{
        return false;
    }
}

const ShuftiPro = ({ language, phone, email, nextStep, onError }:IProps) => {
    const reference = `SP_REQUEST_${Math.random()}`;
    const pin = generatePincode(5);
    const token = btoa(`${shuftiPro.clientId}:${shuftiPro.secretKey}`);

    const rPayload = {
        "reference": reference,
        "callback_url": "",
        "email": email,
        "country": "",
        "language": language,
        //"redirect_url": redirectUrl,
        "verification_mode": "any",
        "allow_offline": "1",
        "allow_online": "1",
        "show_privacy_policy": "1",
        "show_results": "1",
        "show_consent": "1",
        "show_feedback_form": "1",
        "face": "",
        "document": {
            "name": "",
            "dob": "",
            "gender": "",
            "place_of_issue": "",
            "document_number": "",
            "expiry_date": "",
            "issue_date": "",
            "fetch_enhanced_data": "1",
            "supported_types": ['id_card', 'passport']
        },
        "phone": {
            "phone_number": phone,
            "random_code": pin,
            "text": `Your verification code is ${pin}`
        }
    }

    const headers = {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": `Basic ${token}`
    };

    let rs: any
    const [url, setUrl] = useState('');
     useEffect(() => {
         let vs = ''
         let rs: string|null = null
         window.addEventListener('message', (e) => {
            if (e.origin !== shuftiPro.origin || !e.data.verification_status || vs === e.data.verification_status) return;

            vs = e.data.verification_status;
            if (vs === "verification.accepted") {
                if (nextStep) {
                    fetch(shuftiPro.statusEndpoint,
                        {
                            method: "post",
                            headers: headers,
                            body: JSON.stringify(rPayload)
                        })
                        .then(function (r) {
                            rs = r.headers.get('Signature');
                            return r.json();
                        }).then(function (d) {
                        if (validateSignature(d, rs, shuftiPro.secretKey)) {
                            nextStep(d)
                        } else {
                            console.log('signature not validated', d)
                        }
                    });
                }
            } else if (vs === "verification.declined") {
                if (onError) onError(e);
            }
        });
    }, );
    if (url === '') {
        fetch(shuftiPro.requestEnpoint,
            {
                method: "post",
                headers: headers,
                body: JSON.stringify(rPayload)
            })
            .then(function (r) {
                rs = r.headers.get('Signature');
                return r.json();
            }).then(function (d) {
            if (validateSignature(d, rs, shuftiPro.secretKey)) {
                setUrl(d.verification_url)
            } else {
                console.log('signature not validated', d)
            }
        });
    }
    return (
        <iframe
            title={reference}
            style={{width: "800px", height: "500px"}}
            allow={"camera"}
            id={'shuftipro_'+pin}
            width="device-width"
            height="100%"
            src={url}/>
    )
}

export default ShuftiPro;

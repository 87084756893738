import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../../components/Button';
import css from './index.module.css';
import clsx from 'clsx';
import { ErrorField } from './ErrorField';
import { Label } from './Label';
import Divider from '../../../components/Divider';
import { InputDiv, SelectDiv, TextareaDiv } from '../../../components/Select/Select';
import Document from '../../../components/Icons/Document';
import { levels } from './lang-levels';
import { SearchIcon } from '../../../components/Icons/SearchIcon';
import { skillsOptions } from './mockData';
import { initCompany } from '.';
import Selector from '../../../components/Selector/Selector';
import ShuftiPro from "../../../components/ShuftiPro";
import { inviteCustomer, inviteCustomerCheck } from '../../../utils/httpServices/company';
import { toast } from 'react-toastify';
import globalController from '../../../mobx/GlobalController';
import { observer } from 'mobx-react-lite';
import { USStates } from '../../../utils/states';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const descs = [
  // 'Enter your name & email',
  '',
  'Add identification data',
  'Add info about education',
  'Add info about your work experience',
  `Let's set up the accessibility of your CV`,
];

export const Skills = ({ data = [], onDelete, withDelBtn = true }: any) => (
  <div className="flex flex-wrap gap-1 w-full">
    {data.map((u: any, i: number) => (
      <div
        className="pl-4 pr-6 py-2 rounded-[51px] border border-[#3F74FF] font-medium text-xs relative"
        key={u}
      >
        {u}
        {withDelBtn && (
          <div
            role="button"
            className="absolute top-1 right-2 text-[#989FAD] font-semibold"
            onClick={() => onDelete(i)}
            tabIndex={0}
          >
            ⨉
          </div>
        )}
      </div>
    ))}
  </div>
);

interface IProps {
  values: {
    data: any;
    setData: any;
    handleOk: any;
    countries: any[];
  };
}

export const RegisterUser = observer(({ values }: IProps) => {
  const { data, setData, countries, handleOk } = values;
  const userData = globalController.getUserData();
  const [searchSkillStr, setSearchSkillStr] = useState('');
  const [searchSkillStrC, setSearchSkillStrC] = useState(['']);
  const [selectors, setSelectors] = useState([true, true, true]);
  const [step, setStep] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [focus, setFocus] = useState(0);
  const [isVisibleAddress2, setIsVisibleAddress2] = useState(false);
  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    license: '',
    SSN: '',
    email: '',
    phone: ''
  });

  const toggle = (index: number, newValue: any) => {
    const newSelectors = selectors.map(() => false);
    newSelectors[index] = newValue;
    if (index === 1 && newSelectors.every((el) => !el)) {
      newSelectors[0] = true;
    }
    if ((index === 2 || index === 0) && newSelectors.every((el) => !el)) {
      newSelectors[1] = true;
    }
    setSelectors(newSelectors);
  };

  useEffect(() => {
    setHasError(false);
    if (data?.email && !/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/.test(data.email)) {
      setErrors((prev) => ({ ...prev, email: 'Invalid email' }));
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, email: '' }));
    }

    if (data?.phone && !/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/.test(data.phone)) {
      setErrors((prev) => ({ ...prev, phone: 'Invalid phone' }))
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, phone: '' }));
    }

    if (data?.SSN && !/^\d{3}-\d{2}-\d{4}$/.test(data.SSN)) {
      setErrors((prev) => ({ ...prev, SSN: 'Invalid SSN' }));
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, SSN: '' }));
    }

    if (data?.license && !/^[A-Z]{1}\d{1,9}$/.test(data.license)) {
      setErrors((prev) => ({ ...prev, license: `Invalid Driver's License` }));
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, license: '' }));
    }

    if (data?.firstname && !/^[a-zA-Z]+$/.test(data.firstname)) {
      setErrors((prev) => ({ ...prev, firstname: 'Use only letters' }));
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, firstname: '' }));
    }

    if (data?.lastname && !/^[a-zA-Z]+$/.test(data.lastname)) {
      setErrors((prev) => ({ ...prev, lastname: 'Use only letters' }));
      setHasError(true);
    } else {
      setErrors((prev) => ({ ...prev, lastname: '' }));
    }
  }, [data]);

  const invite = useCallback(async () => {
    const result = await inviteCustomer({ email: data.email, name: data.name });
    console.log(result);
    if (!result?.error) {
      // setData({...data, ...result.data})
      setStep(0.2);
    }
  }, [data]);

  const checkInvite = useCallback(async () => {
    const result = await inviteCustomerCheck(data.password);
    console.log(result);

    if (!result?.successful) {
      toast('Wrong password', { type: 'error' });
      setStep(0.21);
    } else {
      setStep(0.5);
    }
  }, [data.password]);

  useEffect(() => {
    if (step === 0.2) {
      invite();
    }

    if (step > 0.21 && step < 0.5 && data.password) {
      checkInvite();
    }

    if (step === 5) {
      console.log('Finish');
      console.log('userData', userData);
      console.log('data', data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      {step <= 0.21 && (
        <div
          className="text-[20px] font-bold"
          onDoubleClick={() => {
            if (step >= 0.2 && step < 0.3 && data.password) {
              setStep(1);
            }
          }}
        >
          Registration
        </div>
      )}
      {step >= 1 && <div className="text-[20px] font-bold">Step {step}</div>}
      {descs[step] && <div className="text-base font-thin">{descs[step]}</div>}
      {step >= 0.2 && step < 0.3 && (
        <div className="text-base font-thin">Enter password from email</div>
      )}

      {step === 0 && (
        <>
          <input
            placeholder="Name"
            className={css.input}
            value={data.name || ''}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            autoComplete="name"
          />
          <input
            placeholder="Email"
            value={data.email || ''}
            className={clsx(css.inputU, errors.email && css.inputUInvalid)}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            autoComplete="email"
          />
          <input
            placeholder="Phone"
            className={clsx(css.inputU, errors.phone && css.inputUInvalid)}
            value={data.phone || ''}
            onChange={(e) => setData({ ...data, phone: e.target.value })}
            autoComplete="phone"
          />
          <SelectDiv
            placeholder="Country"
            containerClassName="w-full"
            className={clsx(css.input, '!bg-white')}
            value={data.country || ''}
            options={countries}
            label=""
            color={undefined}
            disabled={false}
            onChange={(country: any) => setData((prev: any) => ({ ...prev, country }))}
            customOpenBtn={undefined}
          />
          {data.country === 'United States' && (
            <SelectDiv
              placeholder="State"
              containerClassName="w-full "
              className={clsx(css.input, '!bg-white')}
              value={data.state || ''}
              options={USStates}
              label=""
              color={undefined}
              disabled={false}
              onChange={(state: any) => setData((prev: any) => ({ ...prev, state }))}
              customOpenBtn={undefined}
            />
          )}
          <input
            placeholder="ZIP Code"
            className={css.input}
            value={data.code || ''}
            onChange={(e) => setData((prev: any) => ({ ...prev, code: e.target.value }))}
          />

          <div className="relative w-full">
            <input
              placeholder="Address"
              className={css.input}
              value={data.address || ''}
              onChange={(e) => setData((prev: any) => ({ ...prev, address: e.target.value }))}
            />
            <button
              className="absolute right-1 border flex items-center justify-center top-1/2 cursor-pointer w-6 h-6"
              onClick={() => setIsVisibleAddress2((prev) => !prev)}
              style={{
                transform: 'translateY(-50%)',
                fontSize: '20px',
                borderRadius: '50%',
                color: 'grey',
              }}
            >
              {isVisibleAddress2 ? '-' : '+'}
            </button>
          </div>
          {isVisibleAddress2 && (
            <input
              placeholder="Address 2"
              className={css.input}
              value={data.otherCompanyAddress || ''}
              onChange={(e) =>
                setData((prev: any) => ({ ...prev, otherCompanyAddress: e.target.value }))
              }
            />
          )}
        </>
      )}

      {step >= 0.2 && step < 0.3 && (
        <>
          <input
            type="password"
            placeholder="Password"
            className={clsx(css.input, 'mb-2')}
            value={data.password || ''}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            autoComplete="current-password"
          />
        </>
      )}

      {step >= 0.5 && step < 1 && (
          <ShuftiPro
              email={data.email}
              phone={data.phone}
              language={"EN"}
              nextStep={(message) => {
                const updData = userData ? { ...userData, 'shuftipro': message } : { 'shuftipro': message };
                data.firstname = message.verification_data.document.name.first_name;
                data.lastname = message.verification_data.document.name.last_name;
                globalController.setUserData(updData);
                setStep(1);
              }}
              onError={message => console.log(message)}
              />
      )}

      {step === 1 && (
        <>
          <div className="w-full">
            <Label text="First name" active={data.firstname} />
            <input
              placeholder="First name"
              readOnly={true}
              className={clsx(css.inputU, errors.firstname && css.inputUInvalid)}
              value={data.firstname || ''}
              onChange={(e) => setData((prev: any) => ({ ...prev, firstname: e.target.value }))}
            />
            <ErrorField errors={errors} name="firstname" />
          </div>
          <div className="w-full">
            <Label text="Last name" active={data.lastname} />
            <input
              placeholder="Last name"
              className={clsx(css.inputU, errors.lastname && css.inputUInvalid)}
              readOnly={true}
              value={data.lastname || ''}
              onChange={(e) => setData((prev: any) => ({ ...prev, lastname: e.target.value }))}
            />
            <ErrorField errors={errors} name="lastname" />
          </div>
          <div className="w-full">
            <Label text="SSN (XXX-XX-XXXX)" active={data.SSN} />
            <input
              placeholder="SSN"
              className={clsx(css.inputU, errors.SSN && css.inputUInvalid)}
              value={data.SSN || ''}
              onChange={(e) => setData((prev: any) => ({ ...prev, SSN: e.target.value }))}
            />
            <ErrorField errors={errors} name="SSN" />
          </div>
          <div className="w-full">
            <Label text="Driver’s licence" active={data.license} />
            <input
              placeholder="Driver’s license"
              className={clsx(css.inputU, errors.license && css.inputUInvalid)}
              value={data.license || ''}
              onChange={(e) => setData((prev: any) => ({ ...prev, license: e.target.value }))}
            />
            <ErrorField errors={errors} name="license" />
          </div>
          {/* <div className="w-full">
            <Label text="Email" active={data.email} />
            <input
              placeholder="Email"
              className={clsx(css.inputU, errors.email && css.inputUInvalid)}
              value={data.email || ''}
              onChange={(e) => setData((prev) => ({ ...prev, email: e.target.value }))}
            />
            <ErrorField errors={errors} name="email" />
          </div> */}
        </>
      )}

      {step === 2 && (
        <>
          <div className="flex justify-between w-full">
            <div className="font-bold text-sm flex gap-2">
              University
              {focus !== 1 && data.universities.length > 0 && (
                <div className="text-[#0446F1]">({data.universities.length})</div>
              )}
            </div>
            <div
              className="text-[#0446F1] text-sm leading-4 cursor-pointer flex gap-1 items-end w-fit"
              role="button"
              onClick={() => {
                setData((prev: any) => {
                  const obj = { ...prev };
                  obj.universities.push({
                    name: '',
                    date: '',
                    faculty: '',
                    speciality: '',
                    averageScore: '',
                    docs: [],
                  });
                  return obj;
                });
                setFocus(1);
              }}
            >
              {data.universities.length ? 'Add more ' : ''}
              <div className="text-[20px] leading-none">+</div>
            </div>
          </div>
          {focus === 1 &&
            data.universities.map((u: any, i: number, arr: any[]) => (
              <div className="mb-2 w-full flex flex-col gap-4" key={i}>
                <InputDiv
                  value={u.name}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.universities[i].name = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Name"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                {/* <InputDiv
                  value={u.date}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.universities[i].date = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Date"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                /> */}
                <RangePicker
                  onChange={(dates, dateStrings) => {
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.universities[i].date1 = new Date(dateStrings[0]).toISOString();
                      obj.universities[i].date2 = new Date(dateStrings[1]).toISOString();
                      return obj;
                    });
                  }}
                />
                <InputDiv
                  value={u.faculty}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.universities[i].faculty = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Faculty"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                <InputDiv
                  value={u.speciality}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.universities[i].speciality = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Speciality"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                <div className="flex justify-between items-center gap-4">
                  <InputDiv
                    value={u.averageScore}
                    onChange={(e: any) =>
                      setData((prev: any) => {
                        const obj = { ...prev };
                        obj.universities[i].averageScore = e.target.value;
                        return obj;
                      })
                    }
                    className="!h-[40px] !w-1/4"
                    placeholder="Average score"
                    label={undefined}
                    color={undefined}
                    containerClassName={undefined}
                    disabled={undefined}
                    searchIcon={undefined}
                    children={undefined}
                  />
                  <Button className="!bg-[#F3F6FE] whitespace-nowrap">
                    Add document <Document />
                  </Button>
                </div>
                {i !== arr.length - 1 && <Divider className="mt-3" />}
              </div>
            ))}
          <Divider />
          <div className="w-full flex justify-between">
            <div className="font-bold text-sm flex gap-2">
              Courses
              {focus !== 2 && data.courses.length > 0 && (
                <div className="text-[#0446F1]">({data.courses.length})</div>
              )}
            </div>
            <div
              className="text-[#0446F1] text-sm leading-4 cursor-pointer flex gap-1 items-end"
              role="button"
              onClick={() => {
                setData((prev: any) => {
                  const obj = { ...prev };
                  obj.courses.push({
                    companyName: '',
                    courseName: '',
                    date: '',
                    docs: [],
                  });
                  return obj;
                });
                setFocus(2);
              }}
            >
              {data.courses.length ? 'Add more ' : ''}
              <div className="text-[20px] leading-none">+</div>
            </div>
          </div>

          {focus === 2 &&
            data.courses.map((u: any, i: number, arr: any[]) => (
              <div className="mb-2 w-full flex flex-col gap-4" key={i}>
                <InputDiv
                  value={u.companyName}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.courses[i].companyName = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Company name"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                <InputDiv
                  value={u.courseName}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.courses[i].courseName = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Course name"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                {/* <InputDiv
                  value={u.date}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.courses[i].date = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Date"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                /> */}
                <RangePicker
                  onChange={(dates, dateStrings) => {
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.courses[i].date1 = new Date(dateStrings[0]).toISOString();
                      obj.courses[i].date2 = new Date(dateStrings[1]).toISOString();
                      return obj;
                    });
                  }}
                />
                <Button className="!bg-[#F3F6FE] whitespace-nowrap ml-auto">
                  Add document <Document />
                </Button>
                {i !== arr.length - 1 && <Divider className="mt-3" />}
              </div>
            ))}

          <Divider />
          <div className="w-full flex justify-between">
            <div className="font-bold text-sm flex gap-2">
              Languages
              {focus !== 3 && data.languages.length > 0 && (
                <div className="text-[#0446F1]">({data.languages.length})</div>
              )}
            </div>
            <div
              className="text-[#0446F1] text-sm leading-4 cursor-pointer flex gap-1 items-end"
              role="button"
              onClick={() => {
                setData((prev: any) => {
                  const obj = { ...prev };
                  obj.languages.push({
                    language: '',
                    level: '',
                    docs: [],
                  });
                  return obj;
                });
                setFocus(3);
              }}
            >
              {data.languages.length ? 'Add more ' : ''}
              <div className="text-[20px] leading-none">+</div>
            </div>
          </div>
          {focus === 3 &&
            data.languages.map((u: any, i: number, arr: any[]) => (
              <div className="mb-2 w-full flex flex-col gap-4" key={i}>
                <InputDiv
                  value={u.name}
                  onChange={(e: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.languages[i].name = e.target.value;
                      return obj;
                    })
                  }
                  className="!h-[40px]"
                  placeholder="Language"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  searchIcon={undefined}
                  children={undefined}
                />
                <SelectDiv
                  value={u.level}
                  onChange={(val: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      obj.languages[i].level = val;
                      return obj;
                    })
                  }
                  label=""
                  options={levels}
                  className="!h-[40px]"
                  placeholder="Level"
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  customOpenBtn={undefined}
                />
                <Button className="!bg-[#F3F6FE] whitespace-nowrap ml-auto">
                  Add document <Document />
                </Button>
                {i !== arr.length - 1 && <Divider className="mt-3" />}
              </div>
            ))}

          <Divider />
          <div className="w-full flex justify-between">
            <div className="font-bold text-sm flex gap-2">
              Skills
              {data.skills.length > 0 && (
                <div className="text-[#0446F1]">({data.skills.length})</div>
              )}
            </div>
            {focus !== 4 && (
              <div
                className="text-[#0446F1] text-sm leading-4 cursor-pointer flex gap-1 items-end"
                role="button"
                onClick={() => setFocus(4)}
              >
                <div className="text-[20px] leading-none">+</div>
              </div>
            )}
          </div>
          {focus === 4 && (
            <div className="relative w-full">
              <InputDiv
                searchIcon={<SearchIcon />}
                value={searchSkillStr}
                onChange={(e: any) => setSearchSkillStr(e.target.value)}
                onKeyPress={(e: any) => {
                  if (e.key === 'Enter' && searchSkillStr) {
                    setData((prev: any) => {
                      const obj = { ...prev };
                      if (!obj.skills.includes(searchSkillStr)) {
                        obj.skills.push(searchSkillStr);
                      }
                      return obj;
                    });
                    setSearchSkillStr('');
                  }
                }}
                className="!h-[40px]"
                placeholder="Enter skill"
                label={undefined}
                color={undefined}
                containerClassName={undefined}
                disabled={undefined}
                children={undefined}
              />
              <SelectDiv
                customOpenBtn={
                  <div
                    className="absolute top-1/2 right-2 z-50 text-[#0446F1] bg-white px-2 py-1 rounded-[48px] font-medium"
                    style={{ transform: 'translateY(-50%)' }}
                  >
                    Search
                  </div>
                }
                className="!hidden"
                containerClassName="absolute left-0 top-1/2 w-full z-50 !bg-[red]"
                options={
                  searchSkillStr
                    ? skillsOptions.filter((el) =>
                        el.toLowerCase().includes(searchSkillStr.toLowerCase()),
                      )
                    : []
                }
                onChange={(val: any) => {
                  setData((prev: any) => {
                    const obj = { ...prev };
                    if (!obj.skills.includes(val)) {
                      obj.skills.push(val);
                    }
                    obj.skills.filter(Boolean);
                    return obj;
                  });
                  setSearchSkillStr('');
                }}
                label={undefined}
                color={undefined}
                value={undefined}
                disabled={undefined}
              />
            </div>
          )}
          {focus === 4 && (
            <Skills
              data={data.skills}
              onDelete={(i: any) =>
                setData((prev: any) => {
                  const obj = { ...prev };
                  obj.skills.splice(i, 1);
                  return obj;
                })
              }
            />
          )}

          <Divider />
        </>
      )}

      {step === 3 && (
        <div className="w-full my-1">
          {data.companies.map((u: any, i: number) => (
            <div key={i} className="flex flex-col gap-4 w-full">
              <InputDiv
                value={u.name}
                onChange={(e: any) =>
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].name = e.target.value;
                    return obj;
                  })
                }
                className="!h-[40px]"
                placeholder="Company name"
                label={undefined}
                color={undefined}
                containerClassName={undefined}
                disabled={undefined}
                searchIcon={undefined}
                children={undefined}
              />
              {/* <InputDiv
                value={u.date}
                onChange={(e: any) =>
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].date = e.target.value;
                    return obj;
                  })
                }
                className="!h-[40px]"
                placeholder="Date"
                label={undefined}
                color={undefined}
                containerClassName={undefined}
                disabled={undefined}
                searchIcon={undefined}
                children={undefined}
              /> */}
              <RangePicker
                onChange={(dates, dateStrings) => {
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].date1 = new Date(dateStrings[0]).toISOString();
                    obj.companies[i].date2 = new Date(dateStrings[1]).toISOString();
                    return obj;
                  });
                }}
              />
              <InputDiv
                value={u.position}
                onChange={(e: any) =>
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].position = e.target.value;
                    return obj;
                  })
                }
                className="!h-[40px]"
                placeholder="Position"
                label={undefined}
                color={undefined}
                containerClassName={undefined}
                disabled={undefined}
                searchIcon={undefined}
                children={undefined}
              />
              <div className="text-sm font-bold">Skills</div>
              <div className="relative w-full">
                <InputDiv
                  searchIcon={<SearchIcon />}
                  value={searchSkillStrC[i]}
                  onChange={(e: any) => {
                    setSearchSkillStrC((prev) => {
                      const arr = [...prev];
                      arr[i] = e.target.value;
                      return arr;
                    });
                  }}
                  onKeyPress={(e: any) => {
                    if (e.key === 'Enter' && searchSkillStrC[i]) {
                      setData((prev: any) => {
                        const obj = { ...prev };
                        if (!obj.companies[i].skills.includes(searchSkillStrC[i])) {
                          obj.companies[i].skills.push(searchSkillStrC[i]);
                        }
                        obj.companies[i].skills.filter(Boolean);
                        return obj;
                      });
                      setSearchSkillStrC((prev) => {
                        const arr = [...prev];
                        arr[i] = '';
                        return arr;
                      });
                    }
                  }}
                  className="!h-[40px]"
                  placeholder="Enter skill"
                  label={undefined}
                  color={undefined}
                  containerClassName={undefined}
                  disabled={undefined}
                  children={undefined}
                />
                <SelectDiv
                  customOpenBtn={
                    <div
                      className="absolute top-1/2 right-2 z-50 text-[#0446F1] bg-white px-2 py-1 rounded-[48px] font-medium"
                      style={{ transform: 'translateY(-50%)' }}
                    >
                      Search
                    </div>
                  }
                  className="!hidden"
                  containerClassName="absolute left-0 top-1/2 w-full z-50"
                  options={
                    searchSkillStrC[i]
                      ? skillsOptions.filter((el) =>
                          el.toLowerCase().includes(searchSkillStrC[i].toLowerCase()),
                        )
                      : []
                  }
                  onChange={(val: any) =>
                    setData((prev: any) => {
                      const obj = { ...prev };
                      if (!obj.skills.includes(val)) {
                        obj.companies[i].skills.push(val);
                      }
                      obj.companies[i].skills.filter(Boolean);
                      return obj;
                    })
                  }
                  label={undefined}
                  color={undefined}
                  value={undefined}
                  disabled={undefined}
                />
              </div>
              <Skills
                data={u.skills}
                onDelete={(idx: any) =>
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].skills.splice(idx, 1);
                    return obj;
                  })
                }
              />
              <TextareaDiv
                value={u.descriptions}
                onChange={(e: any) =>
                  setData((prev: any) => {
                    const obj = { ...prev };
                    obj.companies[i].descriptions = e.target.value;
                    return obj;
                  })
                }
                placeholder="Descriptions"
                label={undefined}
                color={undefined}
                containerClassName={undefined}
                className={undefined}
                disabled={undefined}
                children={undefined}
              />
              <Divider className="mb-4" />
            </div>
          ))}
          <Button
            variant="lightBlue"
            className="ml-auto !w-fit flex items-center"
            onClick={() =>
              setData((prev: any) => {
                const obj = { ...prev };
                obj.companies.push(JSON.parse(JSON.stringify(initCompany)));
                return obj;
              })
            }
          >
            Add company<div className="text-[22px] leading-none">+</div>
          </Button>
        </div>
      )}

      {step === 4 && (
        <div className="w-full flex flex-col gap-y-8 font-bold text-sm my-4">
          <div className="flex items-center justify-between w-full">
            Available to everyone{' '}
            <Selector
              classNameSelected="!bg-white"
              className={clsx(
                '!w-[64px] !h-[32px] border cursor-pointer border-2',
                selectors[0]
                  ? '!bg-[#0446F1] !border-[#0446F1]'
                  : '!bg-[#EAEAEA] !border-[#EAEAEA]',
              )}
              selected={selectors[0] ? 1 : 0}
              onClick={() => toggle(0, !selectors[0])}
            />
          </div>
          <div className="flex items-center justify-between w-full">
            Hide for everyone
            <Selector
              classNameSelected="!bg-white"
              className={clsx(
                '!w-[64px] !h-[32px] border cursor-pointer border-2',
                selectors[1]
                  ? '!bg-[#0446F1] !border-[#0446F1]'
                  : '!bg-[#EAEAEA] !border-[#EAEAEA]',
              )}
              selected={selectors[1] ? 1 : 0}
              onClick={() => toggle(1, !selectors[1])}
            />
          </div>
          <div className="flex items-center justify-between w-full">
            Available to selected companies
            <Selector
              classNameSelected="!bg-white"
              className={clsx(
                '!w-[64px] !h-[32px] border cursor-pointer border-2',
                selectors[2]
                  ? '!bg-[#0446F1] !border-[#0446F1]'
                  : '!bg-[#EAEAEA] !border-[#EAEAEA]',
              )}
              selected={selectors[2] ? 1 : 0}
              onClick={() => toggle(2, !selectors[2])}
            />
          </div>
        </div>
      )}

      <div className={`flex w-full items-center justify-${step < 2 ? 'center' : 'between'}`}>
        {step > 1 && (
          <Button
            variant="bigBlue"
            onClick={() => setStep((prev) => prev - 1)}
            className={css.btnBack}
          >
            ❮ Back
          </Button>
        )}
        <Button
          variant="bigBlue"
          onClick={() => {
            if (step >= 0.2 && step < 0.3 && !data.password) {
              toast('Please enter password', { type: 'error' });
              return;
            }

            if (step < 4) {
              setStep(
                (p) =>
                  p +
                  (step === 0
                    ? 0.2
                    : step >= 0.2 && step < 0.3
                    ? 0.1
                    : step >= 0.5 && step < 1
                    ? 0.5
                    : 1),
              );
            } else {
              console.log('data', data);
              handleOk();
            }
          }}
          className={css.btn}
          style={{ display: step > 0.21 && step < 1 ? 'none' : 'flex' }}
          disabled={
            hasError ||
            step === 1 &&
            (!data.firstname || !data.lastname || !data.SSN || !data.email || !data.license) ||
            step === 0 &&
            (!data.name || !data.email || !data.phone || !data.country || !data.code || !data.address)

          }
        >
          {step === 4 ? 'Finish' : step === 0 ? 'Send' : 'Continue'}
        </Button>
      </div>
    </>
  );
});

type Icon = 'Bank' | 'Employerscore' | 'FirstAid' | 'PoliceCar' | 'Syringe' | 'User';

interface IconData {
  icon: Icon;
  name: string;
  percent: number;
}

export const iconsData: IconData[] = [
  {
    icon: 'PoliceCar',
    name: 'Criminal score',
    percent: 50,
  },
  {
    icon: 'Syringe',
    name: 'Drug score',
    percent: 100,
  },
  {
    icon: 'Bank',
    name: 'Credit score',
    percent: 28,
  },
  {
    icon: 'FirstAid',
    name: 'Medical score',
    percent: 14,
  },
  {
    icon: 'Employerscore',
    name: 'Employer score',
    percent: 75,
  },

  {
    icon: 'User',
    name: 'Peer score',
    percent: 86,
  },
];

export const blocksCompany = [
  {
    title: 'by 80%',
    desc: 'verifying self-assessments reduces the risk of hiring or promoting the wrong candidate',
  },
  {
    title: '41%',
    desc: 'increase in employee engagement, reducing turnover rates',
  },
  {
    title: '75%',
    desc: 'higher level of confidence in performance metrics using verification of self-assessment',
  },
  {
    title: '62%',
    desc: 'increase of effectiveness of allocating funds during communication with employees',
  },
];

export const blocks = [
  {
    title: '97%',
    desc: 'employers say that truthful and accurate self-assesments are a deciding factor in hiring',
  },
  {
    title: '81%',
    desc: 'See an average salary increase when you accurately represent your skills and achievements.',
  },
  {
    title: '5X',
    desc: 'Experience a 5-fold acceleration in career advancement',
  },
  {
    title: '62%',
    desc: 'Employees who complete their self-assessments are more likely to land a new job quickly.',
  },
];

export const labelsDays = ['1 JUN', '6 JUN', '12 JUN', '18 JUN', '24 JUN', '30 JUN', '31 JUN'];

export const barOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: { display: false },
  },
  scales: {
    y: {
      border: { display: false },
      grid: { display: false },
      max: 100,
      min: 0,
      ticks: {
        stepSize: 25,
        color: 'transparent',
      },
    },
    x: {
      border: { display: false },
      grid: { display: false },
      ticks: { color: 'transparent' },
    },
  },
};

export const barDatasets = [
  {
    data: [80, 55, 70, 90, 50, 45],
    backgroundColor: '#74DC34',
    barThickness: 16,
    borderWidth: 0,
    borderRadius: 12,
  },
];

export const chart5years = {
  labels: labelsDays,
  datasets: [
    {
      data: [66, 55, 30, 45, 45, 55, 15],
      borderColor: '#0446F1',
      pointBackgroundColor: '#0446F1',
      backgroundColor: '#0446F11A',
      fill: true,
    },
    {
      data: [10, 35, 70, 60, 55, 66, 40],
      fill: true,
      borderColor: '#74DC34',
      pointBackgroundColor: '#74DC34',
      backgroundColor: '#5ECA1B1A',
    },
  ],
};

export const chart500 = {
  labels: labelsDays,
  datasets: [
    {
      data: labelsDays.map(() => Math.floor(Math.random() * 71)),
      borderColor: '#0446F1',
      pointBackgroundColor: '#0446F1',
      backgroundColor: '#0446F11A',
      fill: true,
    },
  ],
};

export const chartES = {
  labels: labelsDays,
  datasets: [
    {
      data: [45, 15, 74, 25, 30, 50, 20],
      borderColor: '#0446F1',
      pointBackgroundColor: '#0446F1',
      backgroundColor: '#0446F11A',
      fill: true,
      tension: 0.3,
    },
  ],
};

export const chartESBars = [
  {
    data: chartES.datasets[0].data.map((el, i, arr) => {
      const sorted = [...arr].sort((a, b) => b - a);
      const [max1, max2] = sorted.slice(0, 2);
      if (el === max1 || el === max2) return el + 1;
      return 0;
    }),
    backgroundColor: 'rgba(4, 70, 241, 0.5)',
    barThickness: 27,
    borderWidth: 0,
    borderRadius: 5,
  },
];

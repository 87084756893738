// import { Navigate } from 'react-router-dom';
import EducationPage from './modules/User/Education';
import MyScores from './modules/User/MyScores';
import PersonalOffers from './modules/User/PersonalOffers';
import Profile from './modules/User/Profile';
import WorkExpirience from './modules/User/WorkExpirience';
import Help from './modules/Company/Help';
import Stats from './modules/Company/Stats';
import Logs from './modules/Company/Logs';
import Payments from './modules/Company/Payments';
import EmpSearch from './modules/Company/EmpSearch';
import Settings from './modules/Company/Settings';
import SettingsUser from './modules/User/Settings';

export const routes = {
  company: [
    { path: '/stats', element: <Stats /> },
    { path: '/logs', element: <Logs /> },
    { path: '/payments', element: <Payments /> },
    { path: '/search', element: <EmpSearch /> },
    { path: '/settings', element: <Settings /> },
    // { path: '/self', element: <Self /> },
    { path: '/help', element: <Help /> },
  ],
  user: [
    { path: '/help', element: <Help /> },
    { path: '/profile', element: <Profile /> },
    { path: '/scores', element: <MyScores /> },
    { path: '/education', element: <EducationPage /> },
    { path: '/experience', element: <WorkExpirience /> },
    { path: '/offers', element: <PersonalOffers /> },
    // { path: '/self', element: <Self /> },
    { path: '/settings', element: <SettingsUser /> },
  ],
};

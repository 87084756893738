import React, { useCallback, useEffect, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import { mockPayments, mockTariffs } from './mockData';
import { Tick } from '../../../components/Icons/Tick';
import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import clsx from 'clsx';
import { InputDiv, SelectDiv } from '../../../components/Select/Select';
import { Cell, NoResults } from '../Logs';
import { DatePicker } from 'antd';
import globalController from '../../../mobx/GlobalController';
import { getTariffs } from '../../../utils/httpServices/tariffs';

export const descOptions = ['Check new employees', 'Check current employees', 'Continuous checks'];
export const paymentMethods = ['Credit card', 'Invoice'];
const initFilters = {
  name: '',
  paymentMethod: '',
  desc: '',
};

const fetchTariffs = async () => {
  try {
    const res = await getTariffs();
    console.log('tariffs', res);
  } catch (error) {
    globalController.handleError({ error });
  }
};

export default function Payments() {
  const companyData = globalController.getData();
  const noData =
    companyData?.companyId &&
    !companyData?.lastReports?.length &&
    !companyData?.lastChangesRecent?.length;

  const [selected, setSelected] = useState(1);
  const [filters, setFilters] = useState(initFilters);
  const [data, setData] = useState(mockPayments);

  const handleFilters = useCallback(() => {
    const newData = [...mockPayments]
      .filter((log) =>
        filters.name ? log.name.toLowerCase().includes(filters.name.toLowerCase()) : true,
      )
      .filter((log) => (filters.paymentMethod ? log.method === filters.paymentMethod : true))
      .filter((log) => (filters.desc ? log.desc === filters.desc : true))
      .filter((log) => (filters.date ? log.date === filters.dateStr : true));
    setData(newData);
  }, [filters]);

  useEffect(() => {
    fetchTariffs();
  }, []);

  return (
    <div className="flex flex-col">
      <PageTitle title="Tariffs" showUser />
      <div className="flex gap-3">
        {mockTariffs.map((item, i) => (
          <PageBlock
            onClick={() => setSelected(i)}
            key={item.name}
            className={clsx(
              '!items-start !justify-start flex-1 !transition-all',
              selected === i && '!bg-[#F1F4FD]',
            )}
            shadow
            style={selected === i ? { boxShadow: '0px 0px 13px 0px rgba(0, 0, 0, 0.13)' } : {}}
          >
            <PageBlockTitle>{item.name}</PageBlockTitle>
            <div className="flex flex-col gap-1 mt-5 text-base font-medium mb-8">
              {item.points.map((el, j) => (
                <div
                  className="flex gap-1 items-center whitespace-pre-wrap"
                  key={el || j}
                  style={{
                    opacity: item.available[j] ? 1 : 0.4,
                  }}
                >
                  <Tick /> {el}
                </div>
              ))}
            </div>
            <Divider />
            <div className="flex items-center gap-1 mx-auto text-[#0446F1] font-medium mt-3">
              Main Check From
              <div className="flex items-end gap-1 leading-none">
                <div className="text-[32px]">{item.price.toFixed(2)}</div>
                <div className="text-[16px] !leading-6">$</div>
              </div>
            </div>
            <div className="flex items-center gap-1 mx-auto text-[#0446F1] font-medium mb-3">
              Repeat Check From
              <div className="flex items-end gap-1 leading-none">
                <div className="text-[32px]">{item.price2.toFixed(2)}</div>
                <div className="text-[16px] !leading-6">$</div>
              </div>
            </div>
            <Button
              variant="bigBlue"
              className="!px-4 !py-[10px] !font-semibold !rounded-[25px] mx-auto min-w-[160px]"
            >
              Details
            </Button>
          </PageBlock>
        ))}
      </div>
      <PageBlock className="my-[20px]">
        <PageBlockTitle>Your current tariff is {mockTariffs[selected].name}.</PageBlockTitle>
        <div className="mt-8 mb-4 text-[15px] font-medium w-[40%] text-center">
          To change the tariff, click on the tariff and read the terms. If you still have questions,
          please contact our chat or call the manager
        </div>
        <div className="flex gap-4 text-[13px]">
          <a href="tel:+18001234567">
            <Button variant="mediumBlue" className="!py-3 !px-8 !h-[40px]">
              Call
            </Button>
          </a>
          <Button variant="mediumBlue" className="!py-3 !px-8 !h-[40px]">
            Contact chat
          </Button>
        </div>
      </PageBlock>
      <PageBlock className="!items-start !justify-start flex-1 !transition-all">
        <PageBlockTitle>Payments</PageBlockTitle>
        {noData ? (
          <NoResults />
        ) : (
          <>
            <div className="flex gap-6 items-end my-8">
              <div className="text-sm font-medium gap-[6px] flex flex-col">
                Date
                <DatePicker
                  format="DD.MM.YYYY"
                  value={filters.date}
                  onChange={(date, dateStr) => {
                    setFilters((prev) => ({ ...prev, date, dateStr }));
                  }}
                />
              </div>
              <InputDiv
                label="Name"
                value={filters.name}
                onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
                containerClassName="w-[200px]"
                className="!min-w-0"
              />
              <SelectDiv
                className="!w-[220px]"
                label="Descripton"
                options={descOptions}
                onChange={(desc) => setFilters((prev) => ({ ...prev, desc }))}
                value={filters.desc}
              />
              <InputDiv
                label="Amount"
                disabled
                containerClassName="w-[70px]"
                className="!min-w-0"
                value={mockPayments.reduce((acc, cur) => acc + cur.amount, 0)}
              />
              <InputDiv
                label="Total"
                disabled
                containerClassName="w-[70px]"
                className="!min-w-0"
                value={mockPayments.reduce((acc, cur) => acc + cur.count * cur.amount, 0)}
              />
              <SelectDiv
                label="Payment method"
                options={paymentMethods}
                onChange={(paymentMethod) => setFilters((prev) => ({ ...prev, paymentMethod }))}
                value={filters.paymentMethod}
              />
              <Button
                variant="mediumBlue"
                className="!py-1 !px-2 !h-[30px]"
                onClick={handleFilters}
              >
                Search
              </Button>
            </div>
            <div
              className="grid grid-cols-7 w-full font-medium"
              style={{ gridTemplateColumns: '10% 15% 30% 10% 10% 10% 15%' }}
            >
              <div className="text-[#8E8E8E] mb-2">Date</div>
              <div className="text-[#8E8E8E]">Name</div>
              <div className="text-[#8E8E8E]">Descripton</div>
              <div className="text-[#8E8E8E]">Amount</div>
              <div className="text-[#8E8E8E]">Count</div>
              <div className="text-[#8E8E8E]">Total</div>
              <div className="text-[#8E8E8E]">Payment method</div>

              {data.map((log, i) => (
                <React.Fragment key={i}>
                  <Cell>{log.date}</Cell>
                  <Cell>{log.name}</Cell>
                  <Cell>{log.desc}</Cell>
                  <Cell>${log.amount}</Cell>
                  <Cell>{log.count}</Cell>
                  <Cell>${log.count * log.amount}</Cell>
                  <Cell>{log.method}</Cell>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </PageBlock>
    </div>
  );
}

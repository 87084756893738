import React from 'react';

const activeColor = '#0446F1';
const color = '#B8C6DF';

const Star = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M9.15316 5.40838C10.4198 3.13613 11.0531 2 12 2C12.9469 2 13.5802 3.13612 14.8468 5.40837L15.1745 5.99623C15.5345 6.64193 15.7144 6.96479 15.9951 7.17781C16.2757 7.39083 16.6251 7.4699 17.3241 7.62805L17.9605 7.77203C20.4201 8.32856 21.65 8.60682 21.9426 9.54773C22.2352 10.4886 21.3968 11.4691 19.7199 13.4299L19.2861 13.9372C18.8096 14.4944 18.5713 14.773 18.4641 15.1177C18.357 15.4624 18.393 15.8341 18.465 16.5776L18.5306 17.2544C18.7841 19.8706 18.9109 21.1787 18.1449 21.7602C17.3788 22.3417 16.2273 21.8115 13.9243 20.7512L13.3285 20.4768C12.6741 20.1755 12.3469 20.0248 12 20.0248C11.6531 20.0248 11.3259 20.1755 10.6715 20.4768L10.0757 20.7512C7.77268 21.8115 6.62118 22.3417 5.85515 21.7602C5.08912 21.1787 5.21588 19.8706 5.4694 17.2544L5.53498 16.5776C5.60703 15.8341 5.64305 15.4624 5.53586 15.1177C5.42868 14.773 5.19043 14.4944 4.71392 13.9372L4.2801 13.4299C2.60325 11.4691 1.76482 10.4886 2.05742 9.54773C2.35002 8.60682 3.57986 8.32856 6.03954 7.77203L6.67589 7.62805C7.37485 7.4699 7.72433 7.39083 8.00494 7.17781C8.28555 6.96479 8.46553 6.64194 8.82547 5.99623L9.15316 5.40838Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
  </svg>
);

const Airplane = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M2.25 20.25H15.75"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.58455 13.1624L5.11893 16.4718C5.5902 16.9136 6.19147 17.1914 6.83334 17.2641C7.4752 17.3367 8.12336 17.2002 8.68143 16.8749L22.8752 8.6249L21.1314 6.4874C20.6778 5.9334 20.041 5.55991 19.3361 5.4344C18.6312 5.30889 17.9046 5.43964 17.2877 5.80303L13.1252 8.2499L7.50018 6.3749L5.9533 7.04053C5.83758 7.09012 5.7362 7.16805 5.65851 7.26714C5.58083 7.36622 5.52934 7.48327 5.50879 7.60749C5.48824 7.73171 5.4993 7.85911 5.54094 7.97793C5.58258 8.09675 5.65346 8.20318 5.74705 8.2874L8.62518 10.8749L6.00018 12.3749L3.37518 11.2499L1.80018 11.9249C1.68559 11.9741 1.58509 12.0511 1.50776 12.149C1.43044 12.2468 1.37873 12.3624 1.35732 12.4853C1.33592 12.6081 1.34548 12.7344 1.38516 12.8526C1.42484 12.9709 1.49337 13.0773 1.58455 13.1624V13.1624Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Check = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M3 10.4167C3 7.21907 3 5.62028 3.37752 5.08241C3.75503 4.54454 5.25832 4.02996 8.26491 3.00079L8.83772 2.80472C10.405 2.26824 11.1886 2 12 2C12.8114 2 13.595 2.26824 15.1623 2.80472L15.7351 3.00079C18.7417 4.02996 20.245 4.54454 20.6225 5.08241C21 5.62028 21 7.21907 21 10.4167C21 10.8996 21 11.4234 21 11.9914C21 17.6294 16.761 20.3655 14.1014 21.5273C13.38 21.8424 13.0193 22 12 22C10.9807 22 10.62 21.8424 9.89856 21.5273C7.23896 20.3655 3 17.6294 3 11.9914C3 11.4234 3 10.8996 3 10.4167Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
    <path
      d="M9.5 12.4L10.9286 14L14.5 10"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Credit = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M9 15L15 9" stroke="#B8C6DF" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M15.5 14.5C15.5 15.0523 15.0523 15.5 14.5 15.5C13.9477 15.5 13.5 15.0523 13.5 14.5C13.5 13.9477 13.9477 13.5 14.5 13.5C15.0523 13.5 15.5 13.9477 15.5 14.5Z"
      fill={active ? activeColor : color}
    />
    <path
      d="M10.5 9.5C10.5 10.0523 10.0523 10.5 9.5 10.5C8.94772 10.5 8.5 10.0523 8.5 9.5C8.5 8.94772 8.94772 8.5 9.5 8.5C10.0523 8.5 10.5 8.94772 10.5 9.5Z"
      fill={active ? activeColor : color}
    />
    <path
      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
  </svg>
);

const Hotels = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M22 22L2 22" stroke="#B8C6DF" strokeWidth="1.5" strokeLinecap="round" />
    <path
      d="M17 22V6C17 4.11438 17 3.17157 16.4142 2.58579C15.8284 2 14.8856 2 13 2H11C9.11438 2 8.17157 2 7.58579 2.58579C7 3.17157 7 4.11438 7 6V22"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
    <path
      d="M21 22V11.5C21 10.0955 21 9.39331 20.6629 8.88886C20.517 8.67048 20.3295 8.48298 20.1111 8.33706C19.6067 8 18.9045 8 17.5 8"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
    <path
      d="M3 22V11.5C3 10.0955 3 9.39331 3.33706 8.88886C3.48298 8.67048 3.67048 8.48298 3.88886 8.33706C4.39331 8 5.09554 8 6.5 8"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
    <path
      d="M12 22V19"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 5H14"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 8H14"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 11H14"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M10 14H14"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

const Car = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M1.5 11.25H22.5"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 17.25V19.5C21 19.6989 20.921 19.8897 20.7803 20.0303C20.6397 20.171 20.4489 20.25 20.25 20.25H18C17.8011 20.25 17.6103 20.171 17.4697 20.0303C17.329 19.8897 17.25 19.6989 17.25 19.5V17.25"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.75 17.25V19.5C6.75 19.6989 6.67098 19.8897 6.53033 20.0303C6.38968 20.171 6.19891 20.25 6 20.25H3.75C3.55109 20.25 3.36032 20.171 3.21967 20.0303C3.07902 19.8897 3 19.6989 3 19.5V17.25"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 14.25H7.5"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5 14.25H18"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 11.25L18.1969 4.95C18.1387 4.81672 18.043 4.70322 17.9215 4.62333C17.8 4.54343 17.6579 4.50058 17.5125 4.5H6.4875C6.34208 4.50058 6.19997 4.54343 6.07846 4.62333C5.95695 4.70322 5.86129 4.81672 5.80313 4.95L3 11.25V17.25H21V11.25Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Rental = ({ active = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M22 22L2 22"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M2 10.9999L10.1259 4.49919C11.2216 3.62267 12.7784 3.62267 13.8741 4.49919L22 10.9999"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15.5 5.5V3.5C15.5 3.22386 15.7239 3 16 3H18.5C18.7761 3 19 3.22386 19 3.5V8.5"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M4 22V9.5"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20 22V9.5"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M15 22V17C15 15.5858 15 14.8787 14.5607 14.4393C14.1213 14 13.4142 14 12 14C10.5858 14 9.87868 14 9.43934 14.4393C9 14.8787 9 15.5858 9 17V22"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
    <path
      d="M14 9.5C14 10.6046 13.1046 11.5 12 11.5C10.8954 11.5 10 10.6046 10 9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5Z"
      stroke={active ? activeColor : color}
      strokeWidth="1.5"
    />
  </svg>
);

const Clock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_1_5738)">
      <path
        d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4V8L10.6667 9.33333"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_5738">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const DetailsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_1_5727)">
      <path
        d="M1.3335 7.99992C1.3335 4.85722 1.3335 3.28587 2.30981 2.30956C3.28612 1.33325 4.85747 1.33325 8.00016 1.33325C11.1429 1.33325 12.7142 1.33325 13.6905 2.30956C14.6668 3.28587 14.6668 4.85722 14.6668 7.99992C14.6668 11.1426 14.6668 12.714 13.6905 13.6903C12.7142 14.6666 11.1429 14.6666 8.00016 14.6666C4.85747 14.6666 3.28612 14.6666 2.30981 13.6903C1.3335 12.714 1.3335 11.1426 1.3335 7.99992Z"
        stroke="black"
      />
      <path
        d="M6.75 5.91675C6.75 5.22639 7.30964 4.66675 8 4.66675C8.69036 4.66675 9.25 5.22639 9.25 5.91675C9.25 6.37505 9.00336 6.77574 8.63558 6.99333C8.31869 7.18081 8 7.46522 8 7.83341V8.66675"
        stroke="black"
        strokeLinecap="round"
      />
      <circle cx="8.00016" cy="10.6667" r="0.666667" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_1_5727">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const StarYellow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M6.10227 3.6055C6.9467 2.09067 7.36892 1.33325 8.00016 1.33325C8.6314 1.33325 9.05362 2.09067 9.89805 3.6055L10.1165 3.99741C10.3565 4.42788 10.4765 4.64311 10.6635 4.78512C10.8506 4.92714 11.0836 4.97985 11.5496 5.08528L11.9738 5.18127C13.6136 5.55229 14.4335 5.7378 14.6285 6.36507C14.8236 6.99235 14.2647 7.64597 13.1468 8.9532L12.8575 9.2914C12.5399 9.66288 12.381 9.84861 12.3096 10.0784C12.2381 10.3082 12.2621 10.556 12.3102 11.0516L12.3539 11.5028C12.5229 13.247 12.6074 14.1191 12.0967 14.5067C11.586 14.8944 10.8184 14.5409 9.28305 13.834L8.88584 13.6511C8.44955 13.4503 8.2314 13.3498 8.00016 13.3498C7.76893 13.3498 7.55078 13.4503 7.11449 13.6511L6.71728 13.834C5.18195 14.5409 4.41428 14.8944 3.90359 14.5067C3.39291 14.1191 3.47742 13.247 3.64643 11.5029L3.69015 11.0516C3.73818 10.556 3.76219 10.3082 3.69074 10.0784C3.61928 9.84861 3.46045 9.66288 3.14277 9.2914L2.85356 8.9532C1.73566 7.64597 1.17671 6.99235 1.37178 6.36507C1.56684 5.7378 2.38673 5.55229 4.02652 5.18127L4.45075 5.08528C4.91673 4.97985 5.14972 4.92714 5.33679 4.78512C5.52387 4.64311 5.64385 4.42788 5.88381 3.99741L6.10227 3.6055Z"
      fill="#FCDA27"
    />
  </svg>
);

export { Star, Airplane, Check, Credit, Hotels, Car, Rental, Clock, StarYellow, DetailsIcon };

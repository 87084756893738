import clsx from 'clsx';
import React from 'react';

export const periods = ['Last month', 'Last 6 month', 'Last year'];
export const periodsY = ['1 years', '3 years', '5 years'];

const PeriodTag = ({ children, className, selected, onClick }) => (
  <div
    className={clsx(
      'flex items-center py-1 px-2 rounded-[62px] cursor-pointer select-none transition-all',
      selected ? 'bg-[#E3EBFF] text-[#0446F1]' : 'bg-[#fafafa] text-[#8E8E8E]',
      className,
    )}
    onClick={onClick}
  >
    {children}
  </div>
);

export default PeriodTag;

export const jobs = [
  {
    position: 'Product Middle Designer',
    company: 'ActiveCampaign',
    rating: 0,
    years: '2022 - Now',
    src: '/images/mocks/ActiveCampaign.jpg',
    reviews: [
      {
        name: 'Iren Li',
        position: 'HR manager',
        src: '/images/mocks/photos/IrenLi.jpg',
        data: {
          'Adhere to schedule': 4.5,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.6,
          'Collective relationships': 4.7,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Ilon Ask',
        position: 'Security specialist',
        src: '/images/mocks/photos/IlonAsk.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Mark KoFF',
        position: 'Immediate supervisor',
        src: '/images/mocks/photos/MarkKoff.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
    ],
  },
  {
    position: 'Product Middle Designer',
    company: 'AirTable',
    rating: 4.6,
    years: '2022 - Now',
    duration: '1 yr.',
    src: '/images/mocks/AirTable.jpg',
    reviews: [
      {
        name: 'Iren Li',
        position: 'HR manager',
        src: '/images/mocks/photos/IrenLi.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Ilon Ask',
        position: 'Security specialist',
        src: '/images/mocks/photos/IlonAsk.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Mark KoFF',
        position: 'Immediate supervisor',
        src: '/images/mocks/photos/MarkKoff.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
    ],
  },
  {
    position: 'Product Middle Designer',
    company: 'Adobe',
    rating: 4.6,
    years: '2022 - Now',
    duration: '1 yr.',
    src: '/images/mocks/Adobe.jpg',
    reviews: [
      {
        name: 'Iren Li',
        position: 'HR manager',
        src: '/images/mocks/photos/IrenLi.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Ilon Ask',
        position: 'Security specialist',
        src: '/images/mocks/photos/IlonAsk.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
      {
        name: 'Mark KoFF',
        position: 'Immediate supervisor',
        src: '/images/mocks/photos/MarkKoff.jpg',
        data: {
          'Adhere to schedule': 4.9,
          'Compliance with contract terms': 4.2,
          'Meet NDA conditions': 4.9,
          'Collective relationships': 4.2,
          'Comply with company rules': 4.9,
        },
      },
    ],
  },
];

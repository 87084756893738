import axiosInstance from '.';

// Функция для выгрузки всех тарифов
export const getTariffs = async () => {
  try {
    const response = await axiosInstance.get('/api/tariffs');
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error?.response?.data };
  }
};

// Функция для обновления тарифа
export const updateTariff = async (data) => {
  try {
    const response = await axiosInstance.put('/api/tariffs', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Функция для выбора тарифа компании
export const selectTariff = async (data) => {
  try {
    const response = await axiosInstance.post('/api/tariffs', data);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

/* eslint-disable no-restricted-globals */
import React, { useEffect } from 'react';
import PageTitle from '../../components/PageTitle';
import Button from '../../components/Button';
import css from './index.module.css';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import globalController from '../../mobx/GlobalController';
import { menuItemsCompany, menuItemsUser } from '../../components/Menu/menuItems';
import { useNavigate } from 'react-router-dom';
import  { LinkOkta }  from '../../utils/httpServices/user';

const Login = observer(() => {
  const navigate = useNavigate();

  useEffect(() => {
    globalController.setEmployee(null);
  }, []);

  return (
    <div className="flex flex-col w-full" style={{ height: 'calc(100vh - 48px)' }}>
      <PageTitle
        title="Login"
        onClick={() => {
          const isCompany = confirm('Login as Company (else as User)?');
          if (isCompany) {
            globalController.setAccount('company');
            navigate(menuItemsCompany[0].url);
          } else {
            globalController.setAccount('user');
            navigate(menuItemsUser[0].url);
          }
        }}
      />
      <div className="w-full h-full flex justify-center items-center">
        <div className="flex flex-col gap-4 items-center">
          <Button
            variant="bigBlue"
            className={clsx('w-[10rem]', css.button)}
            onClick={() => navigate('/register')}
          >
            Register as Company
          </Button>
          <Button
            variant="bigBlue"
            className={clsx('w-[10rem]', css.button)}
            onClick={() => navigate('/auth')}
          >
            Login as Company
          </Button>
          <Button
            variant="bigBlue"
            className={clsx('w-[10rem]', css.button)}
            onClick={() => navigate('/register-user')}
          >
            Register as User
          </Button>
          <Button
            variant="bigBlue"
            className={clsx('w-[10rem]', css.button)}
            onClick={() => navigate('/auth-user')}
          >
            Login as User
          </Button>

            <Button
                variant="bigBlue"
                className={clsx('w-[10rem]', css.button)}>
                <LinkOkta/>
                OKTA
            </Button>
        </div>
      </div>
    </div>
  );
});

export default Login;

import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

import css from './Select.module.css';
import { SearchIcon } from '../Icons/SearchIcon';
import { SelectArrow } from '../Icons/SelectArrow';

export default function Select({
  label,
  color,
  options = [],
  className,
  onChange,
  value,
  disabled,
  ...otherProps
}) {
  return (
    <div>
      {label && (
        <div className="text-sm font-medium mb-[6px]" style={color ? { color } : {}}>
          {label}
        </div>
      )}
      <div className="relative">
        <select
          className={clsx(css.select, className)}
          onChange={onChange}
          value={value}
          disabled={disabled || !options.length}
          aria-label="select component"
          {...otherProps}
        >
          {options.map((option) => (
            <option
              key={option?.value || option}
              value={option?.value || option}
              className={css.option}
            >
              {option?.label || option}
            </option>
          ))}
        </select>
        <div
          className="w-4 h-[6px] absolute right-4 bg-contain bg-no-repeat"
          style={{
            backgroundImage: `url(/images/SelectArrow.svg)`,
            transform: 'translateY(-50%)',
            top: '50%',
          }}
        />
      </div>
    </div>
  );
}

export function SelectDiv({
  label,
  color,
  options = [],
  className,
  containerClassName,
  onChange,
  value,
  disabled,
  placeholder = '',
  customOpenBtn,
  ...otherProps
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const els = [...document.querySelectorAll(`.country-option`)];
      const el = els.find((el) => el.textContent === value);
      if (el) el.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }, [isOpen, value]);

  return (
    <div className={containerClassName}>
      {label && (
        <div className="text-sm font-medium mb-[6px]" style={color ? { color } : {}}>
          {label}
        </div>
      )}

      <div
        className="relative w-full"
        tabIndex={0}
        role="button"
        onClick={() => setIsOpen(!isOpen)}
        onBlur={() => setIsOpen(false)}
      >
        <div
          className={clsx(css.select, '!px-3', className)}
          disabled={disabled || !options.length}
          aria-label="select component"
          {...otherProps}
        >
          {!value && placeholder && <div className="text-[#8A95B1] font-medium">{placeholder}</div>}
          {value || ''}
        </div>
        {isOpen && (
          <div className={css.optionsDiv}>
            <div
              className={clsx(css.optionDiv, !value && '!font-bold')}
              onClick={() => onChange('')}
            >
              &nbsp;&nbsp;
            </div>
            {options.map((el) => (
              <div
                key={el?.label || el}
                className={clsx(
                  'country-option',
                  css.optionDiv,
                  (value === el?.label || value === el) && value !== placeholder && '!font-bold',
                )}
                onClick={() => onChange(el?.value || el?.label || el)}
              >
                {el?.label || el}
              </div>
            ))}
          </div>
        )}
        {customOpenBtn || (
          <SelectArrow
            className="w-4 h-[6px] absolute top-1/2 right-4 bg-contain bg-no-repeat transition-all cursor-pointer"
            style={{
              transform: `translateY(-50%) rotate(${isOpen ? 180 : 0}deg)`,
            }}
          />
        )}
      </div>
    </div>
  );
}

export const InputDiv = ({
  label,
  color,
  options = [],
  containerClassName,
  className,
  onChange,
  value,
  disabled,
  searchIcon,
  children,
  ...otherProps
}) => (
  <div className="w-full">
    {label && (
      <div className="text-sm font-medium mb-[6px]" style={color ? { color } : {}}>
        {label}
      </div>
    )}

    <div className={clsx('relative', containerClassName)}>
      {searchIcon && (
        <div className={clsx(css.icon, searchIcon === 'end' && css.iconEnd)}>
          <SearchIcon />
        </div>
      )}
      <input
        className={clsx(css.select, css.input, '!px-3 w-full', searchIcon && '!pl-10', className)}
        disabled={disabled}
        aria-label="select component"
        onChange={onChange}
        value={value || ''}
        {...otherProps}
      />
      {!!children && children}
    </div>
  </div>
);

export const TextareaDiv = ({
  label,
  color,
  containerClassName,
  className,
  onChange,
  value,
  disabled,
  children,
  rows = 3,
  ...otherProps
}) => (
  <div className="w-full">
    {label && (
      <div className="text-sm font-medium mb-[6px]" style={color ? { color } : {}}>
        {label}
      </div>
    )}

    <div className={clsx('relative', containerClassName)}>
      <textarea
        className={clsx(css.select, css.input, '!px-3 w-full !h-auto resize-none', className)}
        disabled={disabled}
        onChange={onChange}
        value={value || ''}
        rows={rows}
        {...otherProps}
      />
      {!!children && children}
    </div>
  </div>
);

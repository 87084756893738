import React, { useCallback, useEffect, useState } from 'react';
import css from './index.module.css';
import Button from '../../../components/Button';
import { authLoginCompany, registerCompany } from '../../../utils/httpServices/company';
import globalController from '../../../mobx/GlobalController';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCountries } from '../../../utils/helpers';
import clsx from 'clsx';
import { RegisterCompany } from './RegisterCompany';
import { RegisterUser } from './RegisterUser';
import { loginUser, registerUser } from '../../../utils/httpServices/user';
import { toast } from 'react-toastify';
import { menuItemsCompany, menuItemsUser } from '../../../components/Menu/menuItems';

interface IProps {
  user?: boolean;
}

export const initCompany = {
  name: '',
  date: '',
  position: '',
  skills: [],
  descriptions: '',
};

const initData = {
  universities: [],
  courses: [],
  languages: [],
  skills: [],
  companies: [initCompany],
};

export default function Register({ user: isUser }: IProps) {
  const { pathname } = useLocation();
  const isLogin = pathname.toLowerCase().includes('auth');
  const navigate = useNavigate();

  const [data, setData] = useState<any>(initData);

  const [countries, setCountries] = useState<string[]>([]);

  const handleOk = async () => {
    try {
      if (!isUser) {
        if (isLogin && data?.email && data?.password) {
          const res = await authLoginCompany(data);
          if (res?.token) {
            const { token, ...newData } = res;

            globalController.setToken(res.token);
            const data = globalController.getData() || {};
            globalController.setData({ ...data, ...newData });
            globalController.setAccount('company');
            navigate(menuItemsCompany[0].url);
          }
        }
        if (!isLogin) {
          const regData = {
            ein: +data.ein,
            companyName: data.companyName,
            url: data.url,
            companyPhone: +data.companyPhone.replace(/\D/g, ''),
            email: data.email,
            about: data.about,
            country: data.country,
            state: data.state || '',
            code: data.code,
            address: data.address,
            address2: data.address2 || '',
          };
          const res = await registerCompany(regData);
          const msg = res?.message;
          if (msg) {
            const isSuccess = msg.includes('success');
            toast(msg, { type: isSuccess ? 'success' : 'warning' });
            if (isSuccess) {
              setTimeout(() => navigate('/auth'), 777);
            }
          } else {
            toast(res?.error.message, {type:'error'});
          }
        }
      }
      if (isUser && isLogin && data?.email && data?.password) {
        console.log('user login');
        const res = await loginUser({ login: data.email, password: data.password });
        if (res && 'error' in res) {
          toast('Access Forbidden', { type: 'error' });
        }
        if (res?.token) {
          const { token, ...newData } = res;
          globalController.setToken(token);
          globalController.setAccount('user');
          const data = globalController.getData() || {};
          globalController.setData({ ...data, ...newData });
          navigate(menuItemsUser[0].url);
        }
        console.log('user login res', res);
      }
      if (isUser && !isLogin) {
        console.log('user registration');
        const newData = {
          ssn: +data.SSN.replace(/\D/g, ''),
          firstName: data.firstname,
          middleName: '',
          lastName: data.lastname,
          email: data.email?.trim(),
          driverLicense: data.license,
          country: data.country,
          state: data.state || '',
          city: '',
          code: data.code?.toString() || '',
          address: data.address || '',
          address2: data.otherCompanyAddress || '',
          phone: +data.phone?.replace(/\D/g, ''),
          invitation: data.password,
        };

        const res = await registerUser(newData);
        console.log('user registration result', res);
        if (res?.message) {
          toast(res.message, { type: res.message.includes('error') ? 'error' : 'info' });
        }

        if (res?.token) {
          async function handleLanguages() {
            const ids = [];
            const languages = data.languages.forEach(async (item: any, i: number) => {});
            return languages;
          }

          globalController.setToken(res.token);
          globalController.setAccount('user');
          // const data = globalController.getData() || {};
          // globalController.setData({ ...data, companyId: res?.companyId });
          navigate(menuItemsUser[0].url);
        }
      }
    } catch (error) {
      globalController.handleError({ error });
    }
  };

  const fetchCountriesStates = useCallback(async () => {
    const res = await getCountries();
    if (res && Array.isArray(res)) {
      setCountries(res.sort((a, b) => (a > b ? 1 : -1)));
      setData((prev: any) => ({ ...prev, country: 'United States' }));
    }
  }, []);

  useEffect(() => {
    if (!isLogin) {
      fetchCountriesStates();
    }
  }, [fetchCountriesStates, isLogin]);

  return (
    // <div className={css.wrapper} style={{ background: !isLogin && isUser ? '#F6F7F9' : '#fff' }}>
    <div className={css.wrapper} style={{ background: '#fff' }}>
      <div
        className={clsx(
          !isLogin && isUser ? 'h-[90%] bottom-0' : 'h-full top-1/2 transform -translate-y-1/2',
          'absolute right-0 bg-no-repeat bg-contain',
        )}
        style={{
          backgroundImage:
            !isLogin && isUser
              ? 'url(/images/register/logoBig1.webp)'
              : 'url(/images/register/bg.webp)',
          aspectRatio: !isLogin && isUser ? 149 / 205 : 159 / 240,
        }}
      />
      <div
        className="absolute bg-no-repeat bg-contain left-[2vmax] top-[2vmax] w-[10%]"
        style={{
          backgroundImage: 'url(/images/register/Logo.webp)',
          aspectRatio: 2 / 1,
        }}
      />
      <div
        className={clsx(
          css.container,
          !isLogin && '!min-w-[400px] -translate-x-1/3',
          !isLogin && !isUser && '!gap-3',
          'bg-[#f6f7f9]',
          // !isLogin && isUser ? 'bg-[#fff]' : 'bg-[#f6f7f9]',
        )}
      >
        {!isLogin && isUser ? null : (
          <div className="text-[20px] font-bold">
            {isLogin ? (isUser ? 'Authorization' : 'Login') : 'Registration'}
          </div>
        )}
        {isLogin ? (
          <>
            <div className="text-base font-thin">
              {/* {isUser ? 'Enter your phone for verification' : 'Enter your email & password'} */}
              Enter your email & password
            </div>
            <input
              // placeholder={isUser ? 'Phone number' : 'Email'}
              placeholder={'Email'}
              className={css.input}
              // value={(isUser ? data.phone : data.email) || ''}
              value={data.email || ''}
              onChange={(e: { target: { value: string | number } }) =>
                // isUser
                // ? setData({ ...data, phone: e.target.value }):
                setData({ ...data, email: e.target.value })
              }
              // autoComplete={isUser ? 'phone' : 'email'}
              autoComplete={'email'}
            />
            {/* {!isUser && ( */}
            <input
              type="password"
              placeholder="Password"
              className={css.input}
              value={data.password || ''}
              onChange={(e) => setData({ ...data, password: e.target.value })}
              autoComplete="current-password"
            />
            {/* )} */}
            <Button
              variant="bigBlue"
              onClick={handleOk}
              className={css.sendBtn}
              // disabled={isUser ? !data.phone : !data.email || !data.password}
              disabled={!data.email || !data.password}
            >
              {/* {isUser ? 'Send SMS' : 'Send'} */}
              Send
            </Button>
          </>
        ) : isUser ? (
          <RegisterUser values={{ data, setData, handleOk, countries }} />
        ) : (
          <RegisterCompany values={{ data, setData, handleOk, countries }} />
        )}
      </div>
    </div>
  );
}

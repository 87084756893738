import React, { useCallback, useState } from 'react';
import PageTitle from '../../../components/PageTitle';
import PageBlock, { PageBlockTitle } from '../../../components/PageBlock';
import Button from '../../../components/Button';
import { InputDiv, SelectDiv } from '../../../components/Select/Select';
import { mockLogs } from './mockData';
import globalController from '../../../mobx/GlobalController';
import { DatePicker } from 'antd';

const actionOptions = ['Added employee', 'Deleted employee', 'Made review'];
const roleOptions = ['Admin', 'Manager', 'Viewer'];

export const Cell = ({ children }) => (
  <div className="text-[15px] py-2 border-b border-gray">{children}</div>
);

const initFilters = {
  role: '',
  action: '',
  login: '',
};

export const NoResults = () => (
  <div className="text-[18px] font-semibold mx-auto my-4">No Results</div>
);

export default function Logs() {
  const companyData = globalController.getData();
  const noData =
    companyData?.companyId &&
    !companyData?.lastReports?.length &&
    !companyData?.lastChangesRecent?.length;

  const [filters, setFilters] = React.useState(initFilters);
  const [data, setData] = useState(mockLogs);

  const handleFilters = useCallback(() => {
    const newData = [...mockLogs]
      .filter((el) =>
        filters.login ? el.login.toLowerCase().includes(filters.login.toLowerCase()) : true,
      )
      .filter((log) => (filters.action ? log.action.type === filters.action : true))
      .filter((log) =>
        filters.role
          ? log.role === filters.role.toLowerCase() ||
            (log.role === 'HR' && filters.role === 'Manager')
          : true,
      )
      .filter((log) => (filters.date ? log.date === filters.dateStr : true));
    setData(newData);
  }, [filters]);

  return (
    <div className="flex flex-col">
      <PageTitle title="Logs" showUser />
      <PageBlock className="!items-start !justify-start">
        <div className="flex w-full justify-between mb-[50px]">
          <PageBlockTitle>Logs actions</PageBlockTitle>
          <div className="w-fit">
            <Button
              variant="bigBlue"
              className="!px-4 !py-[10px] !font-semibold !rounded-[25px]"
              onClick={() => {
                globalController.setModal({ name: 'addEmployee', value: true });
              }}
            >
              Add employee
            </Button>
          </div>
        </div>
        {noData ? (
          <NoResults />
        ) : (
          <>
            <div className="flex gap-6 items-end mb-8">
              <div className="text-sm font-medium gap-[6px] flex flex-col">
                Date
                <DatePicker
                  format="DD.MM.YYYY"
                  value={filters.date}
                  onChange={(date, dateStr) => {
                    setFilters((prev) => ({ ...prev, date, dateStr }));
                  }}
                  style={{ minWidth: '8rem' }}
                />
              </div>
              <SelectDiv
                label="Action"
                options={actionOptions}
                value={filters.action}
                onChange={(action) => setFilters((prev) => ({ ...prev, action }))}
              />
              <InputDiv
                label="Login"
                value={filters.login}
                onChange={(e) => setFilters((prev) => ({ ...prev, login: e.target.value }))}
              />
              <SelectDiv
                label="Role"
                options={roleOptions}
                value={filters.role}
                onChange={(role) => setFilters((prev) => ({ ...prev, role }))}
              />
              <Button
                variant="mediumBlue"
                className="!py-1 !px-2 !h-[30px]"
                type="button"
                onClick={() => handleFilters()}
              >
                Search
              </Button>
            </div>
            <div
              className="grid grid-cols-5 w-full font-medium"
              style={{ gridTemplateColumns: '10% 22% 10% 10% 48%' }}
            >
              <div className="text-[#8E8E8E] mb-2">Date</div>
              <div className="text-[#8E8E8E]">Action</div>
              <div className="text-[#8E8E8E]">Login</div>
              <div className="text-[#8E8E8E]">Role</div>
              <div className="text-[#8E8E8E]"> </div>

              {data.map((log, i, arr) => (
                <React.Fragment key={`${i}-${arr.length}`}>
                  <Cell>{log.date}</Cell>
                  <Cell>{log.action.name}</Cell>
                  <Cell>{log.login}</Cell>
                  <Cell>{log.role === 'HR' ? roleOptions[1] : log.role}</Cell>
                  <Cell> </Cell>
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </PageBlock>
    </div>
  );
}

import React from 'react';

export default function Points({ points, big }) {
  return (
    <div
      style={{
        width: 'fit-content',
        height: 'fit-content',
        padding: big ? '4px 12px' : '2px 4px',
        fontWeight: big ? 600 : 500,
        fontSize: big ? '15px' : '12px',
        borderRadius: '12px',
        background: big
          ? points < 0
            ? '#FFDFDF'
            : '#EDFFE1'
          : points < 50
          ? '#ED2C2C'
          : '#74DC34',
        color: big ? (points < 0 ? '#ED2C2C' : '#60C720') : '#fff',
      }}
    >
      {big && points > 0 && '+'}
      {points} points
    </div>
  );
}

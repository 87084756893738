export const Pencil = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="none" {...props}>
    <rect width="30" height="20" fill="#F5F7FC" rx="10" />
    <path
      stroke="#0446F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16.238 15.629h5.137"
    />
    <path
      stroke="#0446F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M15.553 4.53c.549-.618 1.537-.709 2.207-.202l1.227.898c.737.419.966 1.31.51 1.99-.024.035-6.755 7.96-6.755 7.96a1.233 1.233 0 0 1-.927.422l-2.578.03-.581-2.313c-.082-.325 0-.667.224-.93l6.672-7.855Z"
      clipRule="evenodd"
    />
    <path
      stroke="#0446F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="m14.306 6 3.862 2.792"
    />
  </svg>
);

export const Basket = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="none" {...props}>
    <rect width="30" height="20" fill="#F5F7FC" rx="10" />
    <path
      stroke="#0446F1"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.52 8.387s-.347 4.29-.547 6.096c-.096.864-.629 1.37-1.502 1.385-1.662.03-3.325.032-4.986-.003-.84-.017-1.364-.53-1.458-1.377-.202-1.823-.546-6.1-.546-6.1M20.4 6.331H9.6M18.319 6.331c-.5 0-.93-.353-1.029-.843l-.154-.775a.815.815 0 0 0-.788-.604h-2.696a.815.815 0 0 0-.788.604l-.155.775a1.05 1.05 0 0 1-1.028.843"
    />
  </svg>
);

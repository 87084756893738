import React from 'react';

export const colors = ['#000', '#ED2C2C', '#FFA5A5', '#FFCB14', '#74DC34'];
export const colors3 = ['#ED2C2C', '#FFCB14', '#74DC34'];

export default function ColorLines({ data = [15, 10, 10, 25, 40] }) {
  const totalWidth = 100;
  const widths = [];

  for (let i = 0; i < data.length; i++) {
    const blockWidth = totalWidth - data.slice(0, i).reduce((a, b) => a + b, 0);
    widths.push(blockWidth);
  }

  return (
    <div className="h-4 w-full relative">
      {data.map((value, i, arr) => (
        <div
          key={`${value}-${i}`}
          style={{
            width: `${widths[i]}%`,
            borderRadius: '12px',
            backgroundColor: arr.length === 3 ? colors3[i] : colors[i],
            position: 'absolute',
            right: 0,
            top: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: i === arr.length - 1 ? 'center' : 'flex-start',
            color: '#fff',
            paddingLeft: i !== arr.length - 1 ? `${value / 2}%` : 0,
          }}
        >
          {value}
        </div>
      ))}
    </div>
  );
}

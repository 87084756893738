import globalController from '../../mobx/GlobalController';

import axiosInstance from '.';

// const idCompany = globalController.getData()?.companyId;
const currentDate = new Date();
// const oneYearAgo = new Date(
//   currentDate.getFullYear() - 1,
//   currentDate.getMonth(),
//   currentDate.getDate(),
// );

// Function to load full statistic information
export const loadFullStatistic = async (pageSize, page) => {
  const requestData = {
    // startDate: oneYearAgo.toISOString(),
    // endDate: currentDate.toISOString(),
    date: currentDate.toISOString(),
    idCompany: globalController.getData()?.companyId,
  };

  try {
    const response = await axiosInstance.post(
      `/api/statistic/fullStatistic/${pageSize}/${page}`,
      requestData,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Function to load data from Performance block (employer_hr)
export const loadEmployerHRData = async (companyId, pageSize, page) => {
  try {
    const response = await axiosInstance.post(
      `/api/statistic/employer_hr/${companyId}/${pageSize}/${page}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Function to load data from Performance block (employerPerformance)
export const loadEmployerPerformanceData = async () => {
  const requestData = {
    // startDate: oneYearAgo.toISOString(),
    // endDate: currentDate.toISOString(),
    date: currentDate.toISOString(),
    idCompany: globalController.getData()?.companyId,
  };

  try {
    const response = await axiosInstance.post('/api/statistic/employerPerformance', requestData);
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Function to load last changes recent from statistic rating changes
export const loadLastChangesRecent = async (companyId, pageSize, page) => {
  try {
    const response = await axiosInstance.post(
      `/api/statistic/ratingChanges/${companyId}/${pageSize}/${page}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

// Function to load last reports from statistic
export const loadLastReports = async (companyId, pageSize, page) => {
  try {
    const response = await axiosInstance.post(
      `/api/statistic/lastreports/${companyId}/${pageSize}/${page}`,
    );
    if (response?.error) return { error: response.error };
    return response?.data || null;
  } catch (error) {
    return { error: error.response.data };
  }
};

import React from 'react';

export const SearchIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    {...props}
  >
    <path
      d="M9.83638 15.5386C13.5182 15.556 16.5171 12.5854 16.5345 8.90354C16.552 5.22168 13.5814 2.22282 9.8995 2.20539C6.21764 2.18796 3.21878 5.15856 3.20135 8.84042C3.18392 12.5223 6.15452 15.5211 9.83638 15.5386Z"
      stroke="#8A95B1"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.162 17.2447L14.5542 13.6025"
      stroke="#8A95B1"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
